import { handleResponse } from "utils/utils";
import { authService } from "services";

export const dayTypesService = {
    getAll,
    getSelectable,
    getById,
    add,
    update,
    remove,
};

// gets day types
function getAll(q) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+weekday';

    if (q !== undefined)
        query += "&q=" + q;

    return fetch(`${global.BASE_API}day-types${query}`, requestOptions).then(handleResponse);
}

// gets selectable day types
function getSelectable() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+weekday&variant=select&enabled=true';

    return fetch(`${global.BASE_API}day-types${query}`, requestOptions).then(handleResponse);
}

// get day type by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}day-types/${id}`, requestOptions).then(handleResponse);
}

// add new day type
function add(day_type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(day_type)
    };

    return fetch(`${global.BASE_API}day-types/`, requestOptions).then(handleResponse);
}

// update day type
function update(day_type) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(day_type)
    };

    return fetch(`${global.BASE_API}day-types/${day_type.id}`, requestOptions).then(handleResponse);
}

// remove day type
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}day-types/${id}`, requestOptions).then(handleResponse);
}