import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as employeesActions from "actions/employeesActions";

import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

class EmployeeShiftPatternEdit extends React.Component {
	constructor(props) {
		super(props);

		// console.log(props);

		let employee_shift_pattern = {
			shift_pattern_id: 0,
			start_day: "",
			end_day: '',
			start_index: 0,
		};

		let isEdit = false;

		if (props.employee_shift_pattern) {
			employee_shift_pattern = {
				...props.employee_shift_pattern,
				shift_pattern_id: props.employee_shift_pattern.shift_pattern.id,
			};
			isEdit = true;
		}

		const max_length = isEdit
			? props.employee_shift_pattern.shift_pattern.length
			: 28;
		const length_options = this.setLengthOptions(max_length);

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			employee_shift_pattern,
			length_options,
		};
	}

	setLengthOptions(limit) {
		const length_options = [];

		for (var i = 0; i < limit; i++) {
			length_options.push({
				index: i,
			});
		}

		return length_options;
	}

	getLengthFromShiftPattern(shift_pattern_id) {
		const { shift_patterns } = this.props;

		if (shift_pattern_id < 0) return 28;
		else {
			const shift_pattern = shift_patterns.find(
				(sp) => sp.id === parseInt(shift_pattern_id)
			);
			return shift_pattern.length;
		}
	}

	handleChange = (event) => {
		var { employee_shift_pattern, length_options } = this.state;

		if (event.target.id == "shift_pattern_id") {
			const length = this.getLengthFromShiftPattern(event.target.value);
			length_options = this.setLengthOptions(length);
		}

		this.setState({
			length_options,
			employee_shift_pattern: {
				...employee_shift_pattern,
				[event.target.id]: event.target.value,
			},
		});
	};

	handleDateChange = (id, date) => {
		const { employee_shift_pattern } = this.state;

		let key = id;
		let value = date ? moment(date).format("YYYY-MM-DD") : null;

		this.setState({
			employee_shift_pattern: {
				...employee_shift_pattern,
				[key]: value,
			},
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = "";

		const props = this.props;
		const { employee_shift_pattern } = this.state;

		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await employeesService.updateShiftPattern(
				props.employee.id,
				employee_shift_pattern
			);
		} else {
			response = await employeesService.addShiftPattern(
				props.employee.id,
				employee_shift_pattern
			);
		}

		if (response.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", "Cambios guardados correctamente");
					this.props.getEmployeeShiftPatterns(props.employee.id);
					props.handleClose();
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	render() {
		const { shift_patterns } = this.props;
		const { employee_shift_pattern, length_options } = this.state;

		const optionShiftPatternList =
			shift_patterns.length > 0 &&
			shift_patterns.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const optionIndexList =
			length_options.length > 0 &&
			length_options.map((item) => {
				return (
					<option key={item.index} value={item.index}>
						Día {item.index + 1}
					</option>
				);
			}, this);

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">
					<FormGroup controlId="shift_pattern_id">
						<FormLabel>Rueda de turno</FormLabel>
						<FormControl
							onChange={this.handleChange}
							value={employee_shift_pattern.shift_pattern_id}
							isInvalid={this.checkValidField("shift_pattern_id")}
							as="select"
						>
							<option value="-1">Seleccionar rueda de turno</option>
							{optionShiftPatternList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{this.state.errors.shift_pattern_id}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup
						controlId="start_day"
						className={`form-date ${employee_shift_pattern.start_day === "" && "empty"
							}  ${this.checkValidField('start_day') && "is-invalid"}`}
					>
						<FormLabel>Fecha inicio</FormLabel>

						<DatePicker
							selected={(employee_shift_pattern.start_day === "" || employee_shift_pattern.start_day === null) ? employee_shift_pattern.start_day : moment(employee_shift_pattern.start_day).toDate()}
							onChange={(date) => this.handleDateChange("start_day", date)}
							isInvalid={this.checkValidField("start_day")}
							className="form-control"
							dateFormat="dd/MM/yyyy"
							locale='es'
							placeholderText="dd/mm/aaaa"
						/>

						<FiCalendar />
						<FormControl.Feedback type="invalid">
							{this.state.errors.start_day}
						</FormControl.Feedback>
					</FormGroup>


					<FormGroup controlId="end_day" className={`form-date ${this.checkValidField('end_day') && "is-invalid"}`}>
						<FormLabel>Fecha fin (opcional)</FormLabel>

						<DatePicker
							selected={!employee_shift_pattern.end_day || employee_shift_pattern.end_day === "" ? "" : moment(employee_shift_pattern.end_day).toDate()}
							onChange={(date) => this.handleDateChange("end_day", date)}
							isInvalid={this.checkValidField("end_day")}
							className="form-control"
							dateFormat="dd/MM/yyyy"
							locale='es'
							placeholderText="dd/mm/aaaa"
						/>

						<FiCalendar />
						<FormControl.Feedback type="invalid">
							{this.state.errors.end_day}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="start_index">
						<FormLabel>Empezar por</FormLabel>
						<FormControl
							onChange={this.handleChange}
							value={employee_shift_pattern.start_index}
							isInvalid={this.checkValidField("start_index")}
							as="select"
						>
							<option value="-1">Seleccionar índice</option>
							{optionIndexList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{this.state.errors.start_index}
						</FormControl.Feedback>
					</FormGroup>
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state, myProps) => {
	return {
		...state.employeesReducer,
		shift_patterns: state.shiftsReducer.select_shift_patterns,
		employee: myProps.employee,
	};
};

export default connect(
	mapStateToProps,
	employeesActions
)(EmployeeShiftPatternEdit);
