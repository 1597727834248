import { functionsService } from "services";

export const getAllFunctions = (query, enabled) => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try{
		const list = await functionsService.getAll(query, enabled);

		dispatch({
			type: 'GET_FUNCTIONS',
			payload: list.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}

export const getSelectableFunctions = () => async (dispatch) => {

	try{
		const list = await functionsService.getSelectable();

		dispatch({
			type: 'GET_SELECT_FUNCTIONS',
			payload: list.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}

export const getEnabledFunctions = () => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try{
		const list = await functionsService.getAll('', true);

		dispatch({
			type: 'GET_FUNCTIONS',
			payload: list.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}