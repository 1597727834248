import React from 'react'

const EditModal = ({ closeModal, confirmAction, title, content, style }) => {
  return (
    <div className="modal-content" style={style}>
      <div className="modal-header">
        <h5
          className="modal-title"
        >{title}</h5>
        <button type="button" className="close" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <>
        {content}
      </>
    </div>
  )
}

export default EditModal






      // <div className="modal-body">
      //   {content}
      // </div>
      // <div className="modal-footer">
      //   <button type="button" onClick={closeModal} className="btn btn-outline-primary">Cancelar</button>
      //   <button type="button" className="btn btn-primary" onClick={confirmAction}>Guardar</button>
      // </div>