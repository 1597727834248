import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiThumbsUp } from "react-icons/fi"
import moment from 'moment';
import Avatar from "components/Avatar";
import { Link } from 'react-router-dom'
import { plansService } from "services";

class Dashboard_WithoutTime extends Component {

	constructor(props) {
		super(props);
		this.state = {
			plan_assignments: [],
			hasResults: false,
		};
	}

	componentDidMount() {
		this.getTimes();
	}

	async getTimes() {
		const today = new Date();

		const start = moment(today).format('YYYY-MM-DD');
		const end = moment(today).format('YYYY-MM-DD');

		const response = await plansService.getPlanAssignments({ start, end, without_time_registration: true, started_within_a_margin: true });
		// console.log(response);

		if (response.ok) {
			const plan_assignments = response.data;

			var hasResults = false;
			plan_assignments.map((pa) => {
				pa.plans.map((item) => {
					if (item.assignments.length > 0)
						hasResults = true;
				})
			});

			this.setState({
				plan_assignments,
				hasResults,
				loading: false,
			});
		}
	}

	getEmployeeRows = () => this.state.plan_assignments.map((pa, i) => {
		const employee = pa.employee;
		const assignments = pa.plans[0].assignments;

		return this.getAssignments(employee, assignments)
	});

	getAssignments = (employee, assignments) => assignments.map((a, i) => {
		return (
			<tr key={i}>
				<td>
					<span className='employee-name'>
						<Link to={`/times/${employee.id}/employee`}>
							<Avatar
								userId={employee.id}
								className="avatar-xs"
								alt={employee.name}
							/>
							{employee.name} {employee.surname}
						</Link>
					</span>
				</td>
				<td>
					{a.shift.name}
				</td>
				<td>
					{a.ubication ? a.ubication.name : "-"}
				</td>
			</tr>
		)
	});

	render() {
		const { hasResults } = this.state;
		return (
			hasResults ?
				(
					<table className="table table-times table-zebra">
						<thead>
							<tr>
								<th>Empleado</th>
								<th>Turno</th>
								<th>Ubicación</th>
							</tr>
						</thead>
						<tbody>
							{this.getEmployeeRows()}
						</tbody>
					</table>
				)
				:
				<div className="empty">
					<FiThumbsUp />
					<p>No hay fichajes no realizados hoy</p>
				</div>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.timesReducer,
		user: reducers.authReducer.user,
	}
};

export default connect(mapStateToProps, null)(Dashboard_WithoutTime);