import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { formatMinutesToTime } from "utils/datetime";
import { FiActivity } from "react-icons/fi";
import { dashboardsService } from "services";

class Dashboard_TotalWorkingHours extends Component {
	constructor(props) {
		super(props);
		//  console.log(props);

		this.state = {
			expected_week: 0,
			expected_month: 0,
			expected_year: 0,
			total_week: 0,
			total_month: 0,
			total_year: 0,
		};
	}

	componentDidMount() {
		this.getTotals();
	}

	async getTotals() {
		const employee_id = this.props.user.id;
		const resp = await dashboardsService.getEmployeeHourSummary(employee_id);
		// console.log(resp);
		const { current_week, current_month, current_year } = resp;

		this.setState({
			total_week: resp.ok ? current_week.time_registration_hours : 0,
			expected_week: resp.ok ? current_week.target_hours : 0,
			total_month: resp.ok ? current_month.time_registration_hours : 0,
			expected_month: resp.ok ? current_month.target_hours : 0,
			total_year: resp.ok ? current_year.time_registration_hours : 0,
			expected_year: resp.ok ? current_year.target_hours : 0,
		});
	}

	render() {
		const {
			total_week,
			total_month,
			total_year,
			expected_week,
			expected_month,
			expected_year,
		} = this.state;

		const today = new Date();
		const month = moment(today).format("MMMM");
		const year = today.getFullYear();

		if (
			total_week.length > 0 ||
			total_month > 0 ||
			total_year > 0 ||
			expected_year > 0
		) {
			return (
				<>
					<div className="bloque-horas">
						<h4 className="title">Esta semana</h4>
						<span className="horas-trabajadas">
							{formatMinutesToTime(total_week * 60)}
						</span>
						{expected_week > 0 && (
							<span className="horas-teoricas">
								de{" "}
								<span className="horas-teoricas-total">
									{formatMinutesToTime(expected_week * 60)}
								</span>
							</span>
						)}
					</div>

					<div className="bloque-horas">
						<h4 className="title capitalize">{month}</h4>
						<span className="horas-trabajadas">
							{formatMinutesToTime(total_month * 60)}
						</span>
						{expected_month > 0 && (
							<span className="horas-teoricas">
								de{" "}
								<span className="horas-teoricas-total">
									{formatMinutesToTime(expected_month * 60)}
								</span>
							</span>
						)}
					</div>

					<div className="bloque-horas">
						<h4 className="title">{year}</h4>
						<span className="horas-trabajadas">
							{formatMinutesToTime(total_year * 60)}
						</span>
						{expected_year > 0 && (
							<span className="horas-teoricas">
								de{" "}
								<span className="horas-teoricas-total">
									{formatMinutesToTime(expected_year * 60)}
								</span>
							</span>
						)}
					</div>
				</>
			);
		} else {
			return (
				<div className="empty">
					<FiActivity />
					<p>No hay ningún dato</p>
				</div>
			);
		}
	}
}

const mapStateToProps = (reducers) => {
	return {
		user: reducers.authReducer.user,
	};
};

export default connect(mapStateToProps, null)(Dashboard_TotalWorkingHours);
