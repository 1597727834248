// Time types utils
import moment from 'moment';

export const getEmployeeTimeTypes = (list, date) => {
	var employee_time_types = [];
	// console.log(list);

	if (!date)
		date = new Date();

	list.map((item) => {
		var period_end = item.end || new Date();

		if (moment(date).isBetween(moment(item.start), moment(period_end), 'days', '[]')) {
			employee_time_types = item.time_types;
		}
	});

	return employee_time_types;
}