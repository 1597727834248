const initialState = {
	ubications: [],
	select_ubications: [],
	loading: false,
	error: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_UBICATIONS':
			return {
				...state,
				ubications: action.payload,
				loading: false,
				error: ''
			};
		case 'GET_SELECT_UBICATIONS':
			return {
				...state,
				select_ubications: action.payload,
				loading: false,
				error: ''
			};
		case 'LOADING':
			return { ...state, loading: true };
		case 'ERROR':
			return {
				...state,
				error: action.payload,
				loading: false
			};

		default:
			return state;
	};
};