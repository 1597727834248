import React from "react";
import { default as TotalWorkingHours } from "components/Dashboard/Dashboard_TotalWorkingHours"
import { WIDGET_NAMES } from "components/WidgetTemplates/utils";

const Card_TotalWorkingHours = (props) => {
	// console.log(props);

	return (
		<article className="card card-resumen">
			<div className="card-top">
				<h2>{WIDGET_NAMES[props.name]}</h2>
			</div>
			<div className="card-content">
				<TotalWorkingHours />
			</div>
		</article>
	);
};

export default Card_TotalWorkingHours;