import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus } from "react-icons/fi";
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import PlanViewList  from 'components/PlanViews/PlanViewsList';
import PlanViewEditModal  from 'components/PlanViews/PlanViewEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as plansActions from 'actions/plansActions';
import * as rulesActions from 'actions/rulesActions';
import Modal  from 'components/Modal';

class PlanViews extends Component {
	constructor(props) {
		super(props);

		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewPlanviewModal = this.openNewPlanviewModal.bind(this);
	}

	componentDidMount() {
		this.getPlanviews();
		this.props.getAccounts();
		this.props.getRules();
	}

	async getPlanviews () {
		await this.props.getPlanViews();
	}

	closeModal() {
		this.props.hideModal()
	}

	openNewPlanviewModal() {
		this.props.showModal({
			open: true,
			title: 'Nueva vista',
			style: {width: '600px'},
			content: <PlanViewEditModal handleClose={this.closeModal}  />,
			closeModal: this.closeModal
		}, 'edit')
	}

	getContent = () => {
		if (this.props.loading){
			return <Loading />;
		}

		if (!this.props.plan_views){
			return "Error cargando las vistas";
		}

		return (<PlanViewList plan_views={this.props.plan_views} />);
	}   


  render() {
    return(
    	<>
	      <Layout className="page-plan-views" context="plan-views">

	        <div className="heading">
	            <h1 className="title">Vistas de planificación</h1>

	            <div className="heading-actions">  
	                <button type="button" title="Crear vista" onClick={this.openNewPlanviewModal} className="btn btn-new"><FiPlus /></button>
	            </div>  
	        </div>

			{ this.getContent() }

	      </Layout>

	      <Modal hideModal={this.props.hideModal} />
      </>      
    )
  }
}



const mapStateToProps = (reducers) => {
  return {
    ...reducers.plansReducer,
    ...reducers.rulesReducer,
    loading: reducers.plansReducer.loading
  }
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getPlanViews: () => dispatch(plansActions.getPlanViews()),
  getAccounts: () => dispatch(rulesActions.getAccounts()),
  getRules: () => dispatch(rulesActions.getRulesByType("Calculada")),
})


export default connect(mapStateToProps, mapDispatchToProps)(PlanViews)