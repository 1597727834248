import { authService } from "services";


export const login = (username, password) => async (dispatch) => {
	dispatch({
		type: 'LOADING'
	});

	let response = await authService.login(username, password);
	// console.log(response);

	if (response.ok) {
		dispatch({
			type: 'LOGIN_SUCCESS',
			token: response.token,
			user: response.user
		});
	}
	else {
		dispatch({
			type: 'AUTH_ERROR',
			errors: response.errors,
			error_message: response.error_message
		});
	}
}

export const register = (user) => async dispatch => {
	dispatch({
		type: 'LOADING'
	});

	let response = await authService.register(user);
	// console.log(response);

	if (response.ok) {
		dispatch({
			type: 'REGISTER',
			token: response.token,
			user: response.user
		});
	}
	else {
		dispatch({
			type: 'AUTH_ERROR',
			errors: response.errors,
			error_message: response.error_message
		});
	}
}

export const update = () => async (dispatch) => {
	try {
		let user = await authService.getCurrent();
		// console.log(user);
		authService.setUser(user);

		dispatch({
			type: 'UPDATE_USER',
			user
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
	}
}

export const sso = () => async (dispatch) => {
	try {
		let user = await authService.initializeAuthService();
		console.log(user);
		if (user) {
			dispatch({
				type: 'SSO',
				user
			});
			return Promise.resolve(user);
		}
		return Promise.reject("Error en SSO");
	}
	catch (error) {
		console.log('Error: ' + error.message);
		return Promise.reject(error);
	}
}