import React from 'react';
import Responsive from 'react-responsive';


// responsive components
export const Desktop = props => <Responsive {...props} minWidth={992} />;
export const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const MobileOrTablet = props => <Responsive {...props} maxWidth={991} />;
export const TabletOrDesktop = props => <Responsive {...props} minWidth={768} />;


// responsive hooks
/*
export const isDesktop = useMediaQuery({query: '(min-width: 992px)'});
export const isTablet = useMediaQuery({ query: '(min-width: 768px and max-width:991px)' });
export const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
export const isTabletOrDesktop = useMediaQuery({query: '(min-width: 768px)'});
*/