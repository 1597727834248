import { handleResponse } from "utils/utils";
import { authService } from "services";

export const companyService = {
	getById,
	update,
	getSubscriptionTypes,
	getSubscriptions,
	addSubscriptionRequest,
	getSubscriptionRequests,
	getPaymentModels,
};

// get company by id
function getById(companyId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"X-Ninja-In-Subscription-Process": "true",
		},
	};

	return fetch(`${global.BASE_API}companies/${companyId}`, requestOptions).then(
		handleResponse
	);
}

// update company
function update(company) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(company),
	};

	return fetch(
		`${global.BASE_API}companies/${company.id}`,
		requestOptions
	).then(handleResponse);
}

// get subscription types
function getSubscriptionTypes() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}apps/subscription-types`,
		requestOptions
	).then(handleResponse);
}

// get company's subscriptions
function getSubscriptions(companyId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
			"X-Ninja-In-Subscription-Process": "true",
		},
	};

	return fetch(
		`${global.BASE_API}companies/${companyId}/subscriptions`,
		requestOptions
	).then(handleResponse);
}

// add new subscription request
// "X-Ninja-In-Subscription-Process" : "true"
function addSubscriptionRequest(request) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
			"X-Ninja-In-Subscription-Process": "true",
		},
		body: JSON.stringify(request),
	};

	return fetch(
		`${global.BASE_API}companies/${request.company.id}/subscriptions-requests`,
		requestOptions
	).then(handleResponse);
}

// get subscription requests
function getSubscriptionRequests(companyId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}companies/${companyId}/subscriptions-requests`,
		requestOptions
	).then(handleResponse);
}

// gets payment models
function getPaymentModels() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${global.BASE_API}apps/payment-models`, requestOptions).then(
		handleResponse
	);
}
