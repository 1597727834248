import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal} from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import Moment from 'react-moment';
import {TabletOrDesktop, Mobile} from 'utils/responsive';
import TransactionEditModal  from 'components/Transactions/TransactionEditModal';
import { showModal, hideModal } from "actions/modalActions";
import { transactionsService } from "services";
import { errorFormating } from "utils/utils";


class TransactionList extends Component { 
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openEditTransactionModal = this.openEditTransactionModal.bind(this);
		this.openDeleteTransactionModal = this.openDeleteTransactionModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	closeModal() {
		this.props.hideModal()
	}

	openEditTransactionModal(transaction) {
		this.props.showModal({
		  open: true,
		  title: (transaction.type !== 'Manual') ? 'Ver transacción' : 'Editar transacción manual',
		  style: {width: '420px'},
		  content: <TransactionEditModal transaction={transaction} getTransactions={this.props.getTransactions} handleClose={this.closeModal}  />,
		  closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteTransactionModal(idTransaction) {
		this.props.showModal({
		  open: true,
		  title: 'Eliminar transacción manual',
		  style: {width: '400px'},
		  message:'¿Desea eliminar la transacción? Esta acción no se puede deshacer',
		  deleteAction: () => this.confirmDelete(idTransaction),
		  closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
		 open: true,
		 title: "Error al eliminar",
		 style: {width: '400px'},
		 message: message,
		 closeModal: this.closeModal
		}, 'alert')
	} 	

	confirmDelete= async(idTransaction) =>  {
		const response = await transactionsService.remove(idTransaction);

		if (!response.ok){
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else{
			this.props.getTransactions();
			this.closeModal();
		}
	}


	getEmptyRow = () => {
	    return(
	      <tr>
	        <td colSpan="7" className="no-result">
	          Sin resultados
	        </td>
	      </tr>
	    );
	} 	

	getDropdownActions = (transaction) => {
	  	const labelAction = (transaction.type !== 'Manual') ? "Ver" : "Editar";

	    return(
	        <Dropdown>
	            <Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
	            <Dropdown.Menu alignRight>
	              <Dropdown.Item as="button" onClick={()=> this.openEditTransactionModal(transaction)}>{labelAction}</Dropdown.Item>
	              { transaction.type === 'Manual' && 
	                (<Dropdown.Item as="button" onClick={()=> this.openDeleteTransactionModal(transaction.id)}>Eliminar</Dropdown.Item>)
	              }
	            </Dropdown.Menu>
	        </Dropdown>
	    );
	} 	

	getRowsMobile = () =>  this.props.transactions.map((transaction, index)  => {
	  return (
	  	<Fragment key={index}>
		    <tr className="tr-head">
		      <td colSpan="4"> 
		        <button className="btn-transparent btn-editable" onClick={()=> this.openEditTransactionModal(transaction)}>
		        	<span className="label-top"><Moment format="DD/MM/YY">{ transaction.day }</Moment> - { transaction.type }</span>
		        </button> 
		      </td>                  
		    </tr>	  
		    <tr className="tr-body">
		      <td> 
		      	<span className="label-mini">Empleado</span>{ transaction.employee.name }  {transaction.employee.surname}
		      </td>        
		      <td>
		      	<span className="label-mini">Cuenta</span>{ transaction.account.name }
		      </td>    
		      <td className="hidden-xs">
		      	<span className="label-mini">Fuente</span>
		      	{ transaction.contract &&  transaction.contract.name}
	      		{ transaction.transaction_rule &&  transaction.transaction_rule.name}
	      		{ transaction.type === "Manual" && transaction.type}
		      </td>  	           
		      <td className="td-actions">
		      		{this.getDropdownActions(transaction)}
		      </td>
		    </tr>
	    </Fragment>
	  )
	});  

	getRows = () =>  this.props.transactions.map((transaction, index)  => {

	  const value = parseFloat(transaction.value).toFixed(2).toString().replace(".",",")

	  return (
	    <tr key={ index }>
	      <td> 
	          <button className="btn-transparent btn-editable" onClick={()=> this.openEditTransactionModal(transaction)}><Moment format="DD/MM/YY">{ transaction.day }</Moment></button>      
	      </td>
	      <td>{ transaction.employee.name} {transaction.employee.surname}</td>        
	      <td>{ transaction.account.name }</td>        
	      <td className="td-valor">{ value } { transaction.account.type }</td>        
	      <td>{ transaction.type }</td>        
	      <td>
	      		{ transaction.contract &&  transaction.contract.name}
	      		{ transaction.transaction_rule &&  transaction.transaction_rule.name}
	      		{ transaction.type === "Manual" && transaction.type}
	      </td>        
	      <td className="td-actions">
			{this.getDropdownActions(transaction)}
	      </td>
	    </tr>
	  )
	});  

	render() {
    return(
	      <>
	        <Mobile>
	            <table className="table table-transactions table-mobile">
	              <tbody>
	                {this.props.transactions.length === 0 ?
	                  this.getEmptyRow() 
	                  :
	                  this.getRowsMobile() 
	                 }
	              </tbody>
	            </table>
	        </Mobile>

	        <TabletOrDesktop>
	            <table className="table table-transactions table-zebra">
					<thead>
						<tr>
							<th>Fecha</th>
							<th>Empleado</th>
							<th>Cuenta</th>
							<th>Valor</th>
							<th>Tipo</th>
							<th>Fuente</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{this.props.transactions.length === 0 ?
							this.getEmptyRow() 
							:
							this.getRows() 
						}
      				</tbody>
	            </table>
	        </TabletOrDesktop>
	    </>
    )
	}
}


const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
})


export default connect(null, mapDispatchToProps)(TransactionList)