const initialState = {
	//group: {},
	groups: [],
	detailed_groups: [],
	select_groups: [],
	staffing_levels: [],
	staffing_level_assignments: [],
	staffing_level_current_assignments: [],
	staffing_level_hourly_assignments: [],
	select_staffing_levels: [],
	group_staffing_levels: [],
	group_supervisors: [],
	loading: false,
	error: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		// case 'GET_GROUP':
		// 	return {
		// 		...state,
		// 		group: action.payload,
		// 		loading: false,
		// 		error: ''
		// 	};
		case "GET_GROUPS":
			return {
				...state,
				groups: action.payload,
				loading: false,
				error: "",
			};
		case "GET_DETAILED_GROUPS":
			return {
				...state,
				detailed_groups: action.payload,
				loading: false,
				error: "",
			};
		case "GET_CHILD_GROUPS":
			return {
				...state,
				select_groups: action.payload,
				loading: false,
				error: "",
			};
		case "GET_STAFFING_LEVELS":
			return {
				...state,
				staffing_levels: action.payload,
				loading: false,
				error: "",
			};
		case "GET_SELECT_STAFFING_LEVELS":
			return {
				...state,
				select_staffing_levels: action.payload,
				loading: false,
				error: "",
			};
		case "GET_GROUP_STAFFING_LEVELS":
			return {
				...state,
				group_staffing_levels: action.payload,
				loading: false,
				error: "",
			};
		case "GET_STAFFING_LEVEL_ASSIGNMENTS":
			return {
				...state,
				staffing_level_assignments: action.payload,
				loading: false,
				error: "",
			};
		case "GET_STAFFING_LEVEL_CURRENT_ASSIGNMENTS":
			return {
				...state,
				staffing_level_current_assignments: action.payload,
				loading: false,
				error: "",
			};
		case "GET_STAFFING_LEVEL_HOURLY_ASSIGNMENTS":
			return {
				...state,
				staffing_level_hourly_assignments: action.payload,
				loading: false,
				error: "",
			};
		case "GET_GROUP_SUPERVISORS":
			return {
				...state,
				group_supervisors: action.payload,
				loading: false,
				error: "",
			};

		case "LOADING":
			return { ...state, loading: true };
		case "ERROR":
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			return state;
	}
};
