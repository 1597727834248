import React from "react";
import { ContextMenu, MenuItem, connectMenu } from "react-contextmenu";
const MENU_SHIFT_ID = "menu_shift";
const MENU_DAY_ID = "menu_day";
const MENU_STAFFING_ID = "menu_staffing";
const MENU_STAFFING_HEADER_ID = "menu_staffing_header";

// Context menu 4 shifts:
const ShiftPlanContextMenu = (props) => {
	// console.log(props);
	const { trigger, isUbicationListSelected } = props;
	const handleItemClick = trigger ? trigger.onItemClick : null;
	const shift_plan = trigger ? trigger.plan.shift_plan : null;
	const isLocked =
		trigger && shift_plan && shift_plan.locked ? shift_plan.locked : false;

	const isEdit =
		trigger &&
			shift_plan &&
			shift_plan.shift &&
			(shift_plan.shift.type === "Trabajo" ||
				shift_plan.shift.type === "Attendance" || shift_plan.shift.allow_hourly_absence_request)
			? true
			: false;

	const isEditOnly = trigger && trigger.isEditOnly ? true : false;

	if (isUbicationListSelected) {
		return (
			<ContextMenu id={MENU_SHIFT_ID}>
				<MenuItem onClick={handleItemClick} data={{ action: "paste-ubication" }}>
					Pegar Ubicaciones
				</MenuItem>
			</ContextMenu>
		);
	}

	if (isEditOnly) {
		return (
			<ContextMenu id={MENU_SHIFT_ID}>
				{trigger && (
					<MenuItem onClick={handleItemClick} data={{ action: "edit" }}>
						Editar Turno
					</MenuItem>
				)}
				{trigger && (
					<MenuItem onClick={handleItemClick} data={{ action: "exchange" }}>
						Intercambiar Turno
					</MenuItem>
				)}
				{trigger && (
					<MenuItem onClick={handleItemClick} data={{ action: "delete" }}>
						Quitar Turno
					</MenuItem>
				)}
			</ContextMenu>
		);
	}


	return (
		<ContextMenu id={MENU_SHIFT_ID}>
			{trigger && !isLocked && (
				<MenuItem onClick={handleItemClick} data={{ action: "lock" }}>
					Bloquear turno
				</MenuItem>
			)}
			{trigger && isLocked && (
				<MenuItem onClick={handleItemClick} data={{ action: "unlock" }}>
					Desbloquear Turno
				</MenuItem>
			)}
			{trigger && (
				<MenuItem onClick={handleItemClick} data={{ action: "copy" }}>
					Copiar turno planificado
				</MenuItem>
			)}
			{trigger && isEdit && (
				<MenuItem onClick={handleItemClick} data={{ action: "edit" }}>
					Editar Turno
				</MenuItem>
			)}
			{trigger && (
				<MenuItem onClick={handleItemClick} data={{ action: "exchange" }}>
					Intercambiar Turno
				</MenuItem>
			)}
			{trigger && (
				<MenuItem onClick={handleItemClick} data={{ action: "delete" }}>
					Quitar Turno
				</MenuItem>
			)}
		</ContextMenu>
	);
};

export const ShiftContextMenu = connectMenu(MENU_SHIFT_ID)(ShiftPlanContextMenu);

// Context menu 4 days:
const DayPlanContextMenu = (props) => {
	// console.log(props);
	const { trigger, isShiftListSelected, isUbicationListSelected, isCopiedPlanSelected, isCutSelected } = props;
	const handleItemClick = trigger ? trigger.onItemClick : null;

	return (
		<ContextMenu id={MENU_DAY_ID}>
			{trigger && isShiftListSelected && (
				<MenuItem onClick={handleItemClick} data={{ action: "paste-shift" }}>
					Pegar Turnos
				</MenuItem>
			)}

			{trigger && isUbicationListSelected && (
				<MenuItem onClick={handleItemClick} data={{ action: "paste-ubication" }}>
					Pegar Ubicaciones
				</MenuItem>
			)}

			{trigger && isCopiedPlanSelected && (
				<MenuItem onClick={handleItemClick} data={{ action: "paste-plan" }}>
					Pegar turno planificado
				</MenuItem>
			)}

			{trigger && isCutSelected && (
				<MenuItem onClick={handleItemClick} data={{ action: "cut-paste" }}>
					Pegar turnos planificados
				</MenuItem>
			)}

			{trigger && (
				<MenuItem onClick={handleItemClick} data={{ action: "cut" }}>
					Cortar Turnos
				</MenuItem>
			)}

			{trigger && (
				<MenuItem onClick={handleItemClick} data={{ action: "remove" }}>
					Quitar Turnos
				</MenuItem>
			)}
		</ContextMenu>
	);
};

export const DayContextMenu = connectMenu(MENU_DAY_ID)(DayPlanContextMenu);


// Staffing levels context menu:
const StaffingLevelContextMenu = (props) => {
	// console.log(props);
	const { trigger, isSelected } = props;
	const handleItemClick = trigger ? trigger.onItemClick : null;
	// console.log(trigger);
	if (trigger && isSelected) {
		return (
			<ContextMenu id={MENU_STAFFING_ID}>
				<MenuItem onClick={handleItemClick} data={{ action: "edit" }}>
					Editar
				</MenuItem>
			</ContextMenu>
		);
	}
	return ''
};

export const StaffingContextMenu = connectMenu(MENU_STAFFING_ID)(StaffingLevelContextMenu);


// Staffing levels header context menu:
const StaffingLevelHeaderContextMenu = (props) => {
	// console.log(props);
	const { trigger } = props;
	const handleItemClick = trigger ? trigger.onItemClick : null;

	// if (trigger && trigger.onItemClick) {
	return (
		<ContextMenu id={MENU_STAFFING_HEADER_ID}>
			<MenuItem onClick={handleItemClick} data={{ action: "add" }}>
				Asignar necesidades de personal
			</MenuItem>
		</ContextMenu>
	);
	// }
	// return ''
};

export const StaffingHeaderContextMenu = connectMenu(MENU_STAFFING_HEADER_ID)(StaffingLevelHeaderContextMenu);