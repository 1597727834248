import React, { Fragment } from 'react';
import moment from 'moment';
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function collect(props) {
	return props;
}

const getStaffingHourlyHeader = (props) => props.plan_days.map((d, i) => {
	const day = moment(d.day).format("dd");
	const dayNum = moment(d.day).format("DD");
	const details = d.details;

	// console.log(d);
	let day_type;

	// TODO adaptar multigrup
	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}

	const color = day_type.color;

	return (
		<Fragment key={i}>
			<th
				className="th-day head-hours-main"
				style={{ color: color }}
			>
				<div className="th-wrap">
					<span className="span-day">{day}</span>
					<span className="span-num">{dayNum}</span>
				</div>
			</th>
			{getPlanHourlyRows(props)}
		</Fragment>
	)
});


const getPlanHourlyRows = (props) => props.working_hours_list.map((h, i) => {
	return (
		<th className="head-hours" key={i}>
			{h}-{h + 1}
		</th>
	)
});

const getStaffingFunctionRows = (props) => props.staffing_level_hourly_assignments.map((assignment, i) => {
	const function_name = assignment.function;

	return (
		<tr key={i}>
			<th>
				<div className="th-wrap">
					{function_name}
				</div>
			</th>
			{getHourlyCounterRow(props, assignment)}
		</tr>
	)
});

// contenido necesidades por cada dia y sus horas
const getHourlyCounterRow = (props, assignment) => assignment.counters.map((day, i) => {
	var day_totals = [];
	props.plan_assignments.map((pa, i) => {
		if (pa.employee.employee_with_function && pa.employee.employee_with_function.function.name === assignment.function) {
			const day_filter = pa.plan_assignments_hourly_totals.filter(t => t.day === day.day);

			if (day_filter.length > 0) day_totals.push(...day_filter[0].hours);
		}

		return true;
	});

	// console.log(day_totals);

	return (
		<Fragment key={i}>
			<td
				className="td-day"
			>
			</td>
			{getHourlyCounterRowContent(props, day, day_totals)}
		</Fragment>
	)
});


const getHourlyCounterRowContent = (props, day, day_totals) => day.hours.map((h, i) => {

	const formated_hour = h.hour.substring(0, 2);
	const hour_filter = day_totals.filter(d => d.hour === parseInt(formated_hour));
	let current = hour_filter.reduce((acc, numero) => acc + numero.count, 0);
	current = parseFloat(current).toFixed(2).toString().replace(".", ",").replace(",00", "");

	const needed = h.needed;
	const tooltip = <Tooltip className="tooltip-staffing">{current}/{needed}</Tooltip>;

	var classBackground = "";
	if (needed < current) {
		classBackground = "bg-green";
	}

	if (needed > current) {
		classBackground = "bg-red";
	}


	return (
		<OverlayTrigger key={i} placement="top" overlay={tooltip}>
			<td key={i} className={`td-hour shift-cell ${classBackground}`}>
				{needed === 0 && current === 0 ? "-" : needed}
				{needed < current && (<FaCaretUp className="color-green-dark" />)}
				{needed > current && (<FaCaretDown className="color-red" />)}
			</td>
		</OverlayTrigger >
	)
});

const PlanStaffingHourly = (props) => {
	// console.log(props);

	if (props.staffing_level_hourly_assignments.length === 0) return "";

	return (
		<table id="tableNeeds" className={`table table-bordered table-plan table-needs table-hourly-counter`}>
			<thead>
				<tr>
					<th className="main-col"><div className="th-wrap">Necesidades</div></th>
					{getStaffingHourlyHeader(props)}
				</tr>
			</thead>
			<tbody>
				{getStaffingFunctionRows(props)}
			</tbody>
		</table>
	)
}

export default PlanStaffingHourly