import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FiX } from "react-icons/fi";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as employeesActions from 'actions/employeesActions';

class EmployeeFunctionEdit extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);

    let employee_function = {
      function_id: 0,
      priority: -1,
      is_default: true,

    }

    let isEdit = false;
    var selected_ubications = [];

    if (props.employee_function) {
      employee_function = {
        ...props.employee_function,
        function_id: props.employee_function.function.id
      };
      isEdit = true;

      if (employee_function.ubications)
        selected_ubications = employee_function.ubications.map(ubication => {
          return { ubication: ubication };
        });
    }
    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      employee_function,
      selected_ubications,
      temp_ubication: {},
      new_item_error: false,
    };
  }

  handleSwitch = event => {
    const { employee_function } = this.state;

    this.setState({
      employee_function: {
        ...employee_function,
        [event.target.id]: event.target.checked
      },
    });
  }

  handleChange = event => {
    const { employee_function } = this.state;

    this.setState({
      employee_function: {
        ...employee_function,
        [event.target.id]: event.target.value
      },
    });
  }

  handleAddChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';

    const props = this.props;
    const { selected_ubications } = this.state;

    const temp_employee_function = this.state.employee_function;

    if (temp_employee_function.priority === "-1" || temp_employee_function.priority === -1) {
      temp_employee_function.priority = null;
    }

    if (selected_ubications.length > 0)
      temp_employee_function.ubication_ids = selected_ubications.map(o => o.ubication.id);
    else
      temp_employee_function.ubication_ids = [];

    // if edit => update; else => new    
    if (this.state.isEdit) {
      response = await employeesService.updateFunction(props.employee.id, temp_employee_function);
    }
    else {
      response = await employeesService.addFunction(props.employee.id, temp_employee_function);
    }

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getEmployeeFunctions(props.employee.id);
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  getSelectedUbications = () => this.state.selected_ubications.map((item) => {
    return (
      <li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.ubication.background_color && item.ubication.background_color }}>
        {item.ubication.name}
        <button type="button" onClick={() => this.removeUbication(item.ubication.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
      </li>
    )
  });

  addUbication = () => {
    let { selected_ubications, temp_ubication, new_item_error } = this.state;
    let isValid = true;
    let ubication;

    if (temp_ubication === undefined) {
      isValid = false;
    }
    else {
      ubication = this.props.employee_ubications.find(item => item.ubication.id === parseInt(temp_ubication));
      if (ubication === undefined) {
        isValid = false;
        new_item_error = true;
      }
    }

    if (isValid) {
      // check if ubication already exists
      const exists = selected_ubications.find(item => item.ubication.id === parseInt(temp_ubication));

      if (!exists) {
        selected_ubications = selected_ubications.concat(ubication);
      }

      this.setState({
        selected_ubications,
        new_item_error: false,
        temp_ubication: {},
      });
    }
    else {
      this.setState({
        new_item_error,
      });
    }
  }
  removeUbication = (idUbication) => {
    this.setState((prevState, props) => {
      return {
        selected_ubications: prevState.selected_ubications.filter(item => item.ubication.id !== parseInt(idUbication))
      };
    });
  }

  render() {
    const { functions, employee_ubications } = this.props;
    const { employee_function, temp_ubication, new_item_error } = this.state;

    var isDefault = employee_function.is_default;

    const optionFunctionList = functions.length > 0 && functions.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name}</option>
      )
    }, this);

    const optionPriorityList = Array.from({ length: 10 }, (v, i) => (
      <option key={i} value={i + 1}>{`${i + 1}`}</option>
    ));

    const optionUbicationList = employee_ubications && employee_ubications.map((item, i) => {
      return (
        <option key={i} value={item.ubication.id}>{item.ubication.name}</option>
      )
    }, this);

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <FormGroup controlId="function_id">
            <FormLabel>Función</FormLabel>
            <FormControl
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={employee_function.function_id}
              isInvalid={this.checkValidField('function_id')}
              as="select">
              <option value="-1">Seleccionar función</option>
              {optionFunctionList}
            </FormControl>

            <FormControl.Feedback type="invalid">
              {this.state.errors.function_id}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup controlId="priority">
            <FormLabel>Prioridad</FormLabel>
            <FormControl
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={employee_function.priority}
              isInvalid={this.checkValidField('priority')}
              as="select">
              <option value="-1">Seleccionar prioridad</option>
              {optionPriorityList}
              <option key="100" value="100">100</option>
            </FormControl>

            <FormControl.Feedback type="invalid">
              {this.state.errors.priority}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup className="form-group-flex" controlId="is_default">
            <FormLabel>Defecto</FormLabel>
            <Switch
              controlId="is_default"
              isOn={isDefault}
              handleToggle={this.handleSwitch}
            />

            <FormControl.Feedback type="invalid">
              {this.state.errors.is_default}
            </FormControl.Feedback>
          </FormGroup>

          {(employee_ubications && employee_ubications.length > 0) && (
            <div
              className="form-group-flex form-group-main mb20"
            >
              <p className="mb5">Esta función estará disponible únicamente en las siguientes ubicaciones:</p>

              <div className="flex-group mb10">
                <FormGroup controlId="temp_ubication">
                  <FormControl
                    type="text"
                    value={temp_ubication}
                    placeholder="Selecciona la ubicación"
                    onChange={this.handleAddChange}
                    isInvalid={new_item_error}
                    as="select"
                  >
                    <option value="">Selecciona la ubicación</option>
                    {optionUbicationList}
                  </FormControl>
                </FormGroup>
                <button type="button" onClick={this.addUbication} className="btn btn-primary">Añadir</button>
              </div>
              <ul className="list-tags">
                {this.getSelectedUbications()}
              </ul>

              <p className='text-info f12'>Si no se selecciona ninguna ubicación la función estará disponible en todas las ubicaciones</p>
            </div>
          )}

        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, myProps) => {
  return {
    ...state.employeesReducer,
    functions: state.functionsReducer.functions,
    employee: myProps.employee,
  }
};

export default connect(mapStateToProps, employeesActions)(EmployeeFunctionEdit);