let token = JSON.parse(localStorage.getItem('token'));
let user = JSON.parse(localStorage.getItem('user'));
const initialState = token && user ? { loggedIn: true, token, user, errors: '', error_message: '' } : {};

export default (state = initialState, action) => {

  switch (action.type) {
    case 'LOGIN_SUCCESS':
    case 'SSO':
      return {
        loggedIn: true,
        token: action.token,
        user: action.user,
      };
    case 'UPDATE_USER':
      return {
        loggedIn: true,
        token: action.token,
        user: action.user,
      };
    case 'REGISTER':
      return {
        loggedIn: true,
        token: action.token,
        user: action.user,
      };
    case 'AUTH_ERROR':
      return {
        loggedIn: false,
        errors: action.errors,
        error_message: action.error_message,
        token: {},
        user: {}
      };
    default:
      return state;
  };
};