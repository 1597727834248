const helpUrlsByBrand = {
	plain: {
		absence: "https://help.plain.ninja/es/articles/3693962-que-es-una-ausencia",
		function: "https://help.plain.ninja/es/articles/3693857-que-es-una-funcion-y-para-que-sirve",
		shift: "https://help.plain.ninja/es/articles/3693943-que-es-un-turno-y-para-que-sirve",
		needs: "https://help.plain.ninja/es/articles/3723502-que-son-las-necesidades-de-personal",
		group: "https://help.plain.ninja/es/articles/3693846-que-es-un-grupo-y-para-que-sirve",
		shift_pattern: "https://help.plain.ninja/es/articles/3832982-que-son-las-ruedas-de-turnos-y-para-que-sirven",
	},
	haddock: {
		absence: "https://help.haddock.app/es/articles/9902382-ausencias",
		function: "https://help.haddock.app/es/articles/9902357-empleados#h_efec80aa14",
		shift: "https://help.haddock.app/es/articles/9902362-turnos",
		needs: "https://help.haddock.app/es/articles/9902359-grupos#h_e9c19d99ac",
		group: "https://help.haddock.app/es/articles/9902359-grupos",
		shift_pattern: "https://help.haddock.app/es/articles/9902362-turnos#h_a126b09e5a",
	},
};

// Función para obtener el nombre de la marca según el dominio
const getBrandFromDomain = () => {
	const domain = window.location.hostname;
	return domain === "haddock.local" || domain === "hr.haddock.app" ? "haddock" : "plain";
};

// Exportar las URLs de ayuda según el dominio
export const helpUrls = helpUrlsByBrand[getBrandFromDomain()];
