import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiThumbsDown } from "react-icons/fi"
import moment from 'moment';
import Avatar from "components/Avatar";
import { timesService } from "services";

class Dashboard_WorkingToday extends Component {

	constructor(props) {
		super(props);
		this.state = {
			times: [],
		};
	}

	componentDidMount() {
		this.getTimes();
	}

	async getTimes() {
		const today = new Date();

		const start_from = moment(today).format('YYYY-MM-DD');
		const start_to = moment(today).format('YYYY-MM-DD');

		const response = await timesService.getAll({ start_from, start_to, not_ended: true, include_all: true });

		if (response.ok) {
			this.setState({
				times: response.data,
				loading: false,
			});
		}
	}


	getTimeRows = () => this.state.times.map((time) => {
		const employee = time.employee;
		return (
			<tr key={time.id}>
				<td>
					<span className='employee-name'>
						<Avatar
							userId={employee.id}
							className="avatar-xs"
							alt={employee.name}
						/>
						{employee.name} {employee.surname}
					</span>

				</td>
				<td>
					{time.type.name}
				</td>
				<td>{moment.utc(time.start).local().format('HH:mm')}</td>
			</tr>
		)
	});

	render() {
		const { times } = this.state;
		return (
			times.length > 0 ?
				(
					<table className="table table-times table-zebra">
						<thead>
							<tr>
								<th>Empleado</th>
								<th>Tipo de tiempo</th>
								<th>Inicio</th>
							</tr>
						</thead>
						<tbody>
							{this.getTimeRows()}
						</tbody>
					</table>
				)
				:
				<div className="empty">
					<FiThumbsDown />
					<p>No hay nadie trabajando hoy</p>
				</div>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.timesReducer,
		user: reducers.authReducer.user,
	}
};

export default connect(mapStateToProps, null)(Dashboard_WorkingToday);