import React from 'react';

const Switch = ({ controlId, isOn, handleToggle, disabled }) => {
  return (
    <div className={`switch ${disabled ? 'disabled' : ''}`}>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={controlId}
        type="checkbox"
        disabled={disabled}
      />
      <label
        style={{ background: isOn && '#06D6A0' }}
        className="react-switch-label"
        htmlFor={controlId}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};

export default Switch;