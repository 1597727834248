import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import ReduxToastr from "react-redux-toastr";
import moment from "moment";
import "moment/locale/es";
import Moment from "react-moment";
import "moment-timezone";

import { getGeoIP } from "./utils/utils";

// global.BASE_API = "http://localhost:8080/api/"; // local
//global.BASE_API = "https://app.plain.ninja/saas/api/"; // prod
// global.BASE_API = 'http://192.168.1.53:8080/api/'; // local

global.BASE_API = process.env.REACT_APP_BASE_API;
global.BASE_WS = process.env.REACT_APP_BASE_WS;
global.REACT_APP_MAGIC_URL = process.env.REACT_APP_MAGIC_URL;
// console.log(process.env);
// console.log(global.BASE_API);
global.API_KEY = "9cb029da-b5a6-429e-8184-4ebfb89a732c"; // temp

Moment.globalMoment = moment; // Sets the moment instance to use.
Moment.globalLocale = "es"; // Set the locale to spanish
Moment.globalFormat = "D MMM YYYY"; // Set the output format
Moment.globalLocal = true; // Set the output timezone for local for every instance

// geolocation + IP
getGeoIP();

const store = createStore(
	reducers, // all reducers
	{}, // initial state
	applyMiddleware(reduxThunk)
);

ReactDOM.render(
	<Provider store={store}>
		<App />
		<ReduxToastr
			timeOut={7000}
			newestOnTop={true}
			preventDuplicates
			position="top-right"
			getState={(state) => state.toastr} // This is the default
			transitionIn="fadeIn"
			transitionOut="fadeOut"
			closeOnToastrClick
		/>
	</Provider>,
	document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
