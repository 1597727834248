import React from "react";
import { Link } from "react-router-dom";
import { authService } from "services";
import Logo from "components/Logo";
import { getAppName } from 'utils/utils';

class EndTrial extends React.Component {
	componentDidMount() {
		authService.cleanStorage();
	}
	render() {
		const appName = getAppName();

		return (
			<React.Fragment>
				<div className="page-end-trial container-fluid">
					<Link to="/">
						<Logo className="main-logo" type="logo" />
					</Link>
					<h1>Periodo de prueba finalizado</h1>
					<p>
						El periodo de prueba ha caducado, por favor, para activar la
						suscripción, contacta con un administrador de {appName} dentro de tu
						empresa.
					</p>
				</div>
			</React.Fragment>
		);
	}
}

export default EndTrial;
