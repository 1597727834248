import React from 'react';
import Moment from 'react-moment';
import Avatar from 'components/Avatar';
import { Link } from "react-router-dom";
import { Tablet, Desktop, Mobile } from 'utils/responsive';
import { Dropdown } from 'react-bootstrap';
import { FiMoreHorizontal } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";
import EmployeeListItemHeader from 'components/Employees/EmployeeListItemHeader';
import AbsenceListItemPeriod from 'components/Absences/AbsenceListItemPeriod';

const ManagedAbsencesList = (props) => {
	// console.log(props);
	// console.log(props.managed_absences);

	if (props.managed_absences == undefined || props.managed_absences.length === 0)
		return getEmptyRow();


	if (props.is_list_view) {
		return (
			<>
				<Mobile>
					<table className="table table-absences table-mobile">
						<tbody>
							{getTableRowsMobile(props)}
						</tbody>
					</table>
				</Mobile>
				<Tablet>
					<table className="table table-absences table-tablet">
						<tbody>
							{getTableRowsTablet(props)}
						</tbody>
					</table>
				</Tablet>
				<Desktop>
					<table className="table table-absences table-desktop table-zebra">
						<thead>
							<tr>
								<th>Empleado</th>
								<th>Turno</th>
								<th>Periodo</th>
								<th>Estado</th>
								<th>Solicitado</th>
								<th>Resuelto</th>
								<th>Gestionado</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{getTableRows(props)}
						</tbody>
					</table>
				</Desktop>
			</>
		)
	}

	return <div className="row grid-absences">{getGridRows(props)}</div>

}

const getEmptyRow = () => {
	return (
		<p className="no-results">Sin resultados.</p>
	);
}

const getTableRows = (props) => props.managed_absences.map((absence) => (
	<tr key={absence.id} className="row-ausencia">
		<td className="td-name">
			<div className="td-name-content">
				<Link to={`/employees/${absence.requester.id}`}><Avatar userId={absence.requester.id} className="avatar-sm" alt={absence.requester.name} /></Link>
				<div className="employee-info">
					<Link className="employee-name" to={`/employees/${absence.requester.id}`}>{absence.requester.name} {absence.requester.surname}</Link>
				</div>
			</div>
		</td>
		<td>
			<span
				className="card-shift"
				style={{ backgroundColor: absence.shift.background_color && absence.shift.background_color, borderColor: absence.shift.border_color && absence.shift.border_color }}>
				{absence.shift.name}
			</span>
		</td>
		<td>
			<AbsenceListItemPeriod absence={absence} />
		</td>
		<td>
			<span className={`estado ${absence.status}`}>{absence.status}</span>
		</td>
		<td>
			<Moment className="tag-dia" format="D/MM/YYYY">{absence.requested}</Moment>
		</td>
		<td>
			<Moment className="tag-dia" format="D/MM/YYYY">{absence.answered}</Moment>
		</td>
		<td className="td-name">
			<div className="td-name-content">
				<Link to={`/employees/${absence.answerer.id}`}><Avatar userId={absence.answerer.id} className="avatar-xxs" alt={absence.answerer.name} /></Link>
				<div className="employee-info">
					<Link className="employee-name" to={`/employees/${absence.answerer.id}`}>{absence.answerer.name} {absence.answerer.surname}</Link>
				</div>
			</div>
		</td>
		<td>
			{(absence.attachments && absence.attachments.length > 0) && <MdAttachFile title="Tiene adjuntos" className="absence-attachment" />}
		</td>
		<td className="td-actions">
			{getActions(props, absence)}
		</td>
	</tr>
));

const getTableRowsTablet = (props) => props.managed_absences.map((absence) => (
	<tr key={absence.id} className="row-ausencia">
		<td className="td-name">
			<div className="td-name-content">
				<Link to={`/employees/${absence.requester.id}`}><Avatar userId={absence.requester.id} className="avatar-sm" alt={absence.requester.name} /></Link>
				<div className="employee-info">
					<Link className="employee-name" to={`/employees/${absence.requester.id}`}>{absence.requester.name} {absence.requester.surname}</Link>
				</div>
			</div>
		</td>
		<td>
			<span
				className="card-shift"
				style={{ backgroundColor: absence.shift.background_color && absence.shift.background_color, borderColor: absence.shift.border_color && absence.shift.border_color }}>
				{absence.shift.name}
			</span>
		</td>
		<td>
			<span className={`estado ${absence.status}`}>{absence.status}</span>
		</td>
		<td>
			<AbsenceListItemPeriod absence={absence} />
		</td>
		<td>
			{(absence.attachments && absence.attachments.length > 0) && <MdAttachFile title="Tiene adjuntos" className="absence-attachment" />}
		</td>
		<td className="td-actions">
			{getActions(props, absence)}
		</td>
	</tr>
));

const getTableRowsMobile = (props) => props.managed_absences.map((absence) => (
	<tr key={absence.id} className="row-ausencia">
		<td className="td-name">
			<div className="td-name-content">
				<Link to={`/employees/${absence.requester.id}`}><Avatar userId={absence.requester.id} className="avatar-sm" alt={absence.requester.name} /></Link>
				<div className="employee-info">
					<Link className="employee-name" to={`/employees/${absence.requester.id}`}>{absence.requester.name} {absence.requester.surname}</Link>
				</div>
			</div>

			<AbsenceListItemPeriod absence={absence} />
		</td>
		<td>
			<span
				className="card-shift"
				style={{ backgroundColor: absence.shift.background_color && absence.shift.background_color, borderColor: absence.shift.border_color && absence.shift.border_color }}>
				{absence.shift.name}
			</span>

			<span className={`estado ${absence.status}`}>{absence.status}</span>
		</td>

		<td className="td-actions">
			{getActions(props, absence)}
		</td>
	</tr>
));

const getGridRows = (props) => props.managed_absences.map((absence) => (
	<div className="col-sm-6 col-lg-4 col-xl-3" key={absence.id}>

		<article className="card card-ausencia" key={absence.id}>
			<EmployeeListItemHeader employee={absence.requester} />

			<div className="card-content">
				{/* <span className="absence-comments">{absence.comments}</span> */}

				<span
					className="card-shift"
					style={{ backgroundColor: absence.shift.background_color && absence.shift.background_color, borderColor: absence.shift.border_color && absence.shift.border_color }}>
					{absence.shift.name}
				</span>

				<AbsenceListItemPeriod absence={absence} />

				<p className="card-label card-label-solicitado">Solicitado el <Moment className="tag-dia" format="D/MM/YYYY">{absence.requested}</Moment></p>

				<div className="form-group no-form">
					<span className={`estado ${absence.status}`}>{absence.status}</span>
				</div>

				<div className="form-group no-form card-label-gestionado">
					<Avatar userId={absence.answerer.id} className="avatar-xxs" alt={absence.answerer.name} /> {absence.answerer.name} {absence.answerer.surname}
				</div>


				{absence.answer_comments && (<span className="absence-answer-comments">{absence.answer_comments}</span>)}
			</div>

			<div className="card-actions bottom-right">
				{getActions(props, absence)}
			</div>
		</article>

	</div>
));

const getActions = (props, absence) => {
	return (
		<Dropdown>
			<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
			<Dropdown.Menu alignRight>
				<Dropdown.Item as="button" onClick={() => props.openEditAbsenceModal(absence, true)}>Ver</Dropdown.Item>
				{(absence.status !== "Denegado" && absence.status !== "Denied") && (
					<Dropdown.Item
						as="button"
						onClick={() => props.handleCancelRequest(absence)}
					>
						Cancelar ausencia
					</Dropdown.Item>
				)}
			</Dropdown.Menu>
		</Dropdown>
	)
};

export default ManagedAbsencesList