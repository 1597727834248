const initialState = {
	plans: [],
	plan_days: [],
	plan_assignments: [],
	plan_visits: [],
	plan_views: [],
	plan_view_default: {},
	loading: false,
	error: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "LOADING":
			return { ...state, loading: true };
		case "UNLOADING":
			return { ...state, loading: false };
		case "GET_PLANS":
			return {
				...state,
				plans: action.payload,
				loading: false,
				error: "",
			};
		case "GET_PLAN_DAYS":
			return {
				...state,
				plan_days: action.payload,
				loading: false,
				error: "",
			};
		case "GET_PLAN_ASSIGNMENTS":
			return {
				...state,
				plan_assignments: action.payload,
				loading: false,
				error: "",
			};
		// case "GET_PLAN_CALCULATIONS":
		// 	return {
		// 		...state,
		// 		//plan_assignments: action.payload, // CANVIAR
		// 		loading: false,
		// 		error: "",
		// 	};
		case "SET_PLAN_ASSIGNMENTS":
			return {
				...state,
				loading: false,
				error: "",
			};
		case "GET_PLAN_VISITS":
			return {
				...state,
				plan_visits: action.payload,
				loading: false,
				error: "",
			};
		case "GET_PLAN_VIEWS":
			return {
				...state,
				plan_views: action.payload,
				loading: false,
				error: "",
			};
		case "GET_DEFAULT_PLAN_VIEW":
			return {
				...state,
				plan_view_default: action.payload,
				loading: false,
				error: "",
			};

		case "ERROR":
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			return {
				...state,
				loading: false,
			};
	}
};
