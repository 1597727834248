import React from "react";
import { default as NextHolidays } from "components/Dashboard/Dashboard_NextHolidays"
import { WIDGET_NAMES } from "components/WidgetTemplates/utils";

const Card_NextHolidays = (props) => {
	// console.log(props);

	return (
		<article className="card card-upcoming-holidays">
			<div className="card-top">
				<h2>{WIDGET_NAMES[props.name]}</h2>
			</div>
			<div className="card-content">
				<NextHolidays />
			</div>
		</article>
	);
};

export default Card_NextHolidays;