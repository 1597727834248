import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal, FiLock } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import TimeTypeEditModal  from 'components/TimeTypes/TimeTypeEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as timesActions from 'actions/timesActions';
import { timeTypesService } from "services";
import { errorFormating } from "utils/utils";

class TimeTypesList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openEditTimeTypeModal = this.openEditTimeTypeModal.bind(this);
		this.openDeleteTimeTypeModal = this.openDeleteTimeTypeModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	componentDidMount() {

	}

	closeModal() {
		this.props.hideModal()
	}

	openEditTimeTypeModal(time_type) {
		this.props.showModal({
		  open: true,
		  title: 'Editar tipo de tiempo',
		  style: {width: '400px'},
		  content: <TimeTypeEditModal time_type={time_type}  handleClose={this.closeModal}  />,
		  closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteTimeTypeModal(idTimeType) {
		this.props.showModal({
		  open: true,
		  title: 'Eliminar tipo de tiempo',
		  style: {width: '400px'},
		  message:'¿Desea eliminar el tipo de tiempo? Esta acción no se puede deshacer',
		  deleteAction: () => this.confirmDelete(idTimeType),
		  closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
		 open: true,
		 title: "Error al eliminar",
		 style: {width: '400px'},
		 message: message,
		 closeModal: this.closeModal
		}, 'alert')
	} 	

	confirmDelete= async(idTimeType) =>  {
		const response = await timeTypesService.remove(idTimeType);

		if (!response.ok){
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else{
			this.props.getTimeTypes();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return(
			<tr>
				<td colSpan="4" className="no-result">
					Sin resultados
				</td>
			</tr>
		);
	} 	

	getRows = () =>  this.props.time_types.map((time_type)  => {

		return (
			<tr key={ time_type.id }>
				<td> 
					{time_type.system
						?  time_type.name  
						: <button className="btn-transparent btn-editable" onClick={()=> this.openEditTimeTypeModal(time_type)}>{ time_type.name }</button>
					}				
				</td>
				<td>
					{time_type.enabled
						? <span title='Activo' className='estado estado-si'>Activo</span> 
						: <span title='Inactivo' className='estado estado-no'>Inactivo</span>
					}
				</td>
				<td className="hidden-xs">
					{time_type.work_time
						? "Sí"
						: "No"
					}
				</td>
				<td className="td-actions">
					{time_type.system 
						?
						  <FiLock className="icon-locked" title="Tipo de tiempo de sistema" />
						: (<Dropdown>
								<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item as="button" onClick={()=> this.openEditTimeTypeModal(time_type)}>Editar</Dropdown.Item>
									<Dropdown.Item as="button" onClick={()=> this.openDeleteTimeTypeModal(time_type.id)}>Eliminar</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>)
					}					
				</td>
			</tr>
		)
	});  	

	render() {
		return(
			<table className="table table-timeTypes table-zebra">
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Estado</th>
						<th className="hidden-xs">Tipo trabajo</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{this.props.time_types.length === 0 ?
						this.getEmptyRow() 
					:
						this.getRows() 
					}
				</tbody>
			</table>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.calendarsReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getTimeTypes: (q) => dispatch(timesActions.getTimeTypes(q)),
})


export default connect(mapStateToProps, mapDispatchToProps)(TimeTypesList)