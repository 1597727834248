import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";
import {
	FormGroup,
	FormControl,
	FormLabel,
} from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import SubmitButton from "components/SubmitButton";
import * as groupsActions from "actions/groupsActions";

class PlanOpener extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.state = {
			start: this.props.start || "",
			end: this.props.end || "",
			group_id: this.props.group_id || "",
			isLoading: false,
			submitted: false,
			error: "",
		};
	}

	componentDidMount() {
		this.props.getAllGroups();
	}

	onDatesChange = (dates) => {
		var { startDate, endDate } = dates;

		if (startDate)
			startDate = startDate.format("YYYY-MM-DD");

		if (endDate)
			endDate = endDate.format("YYYY-MM-DD");

		this.setState({
			start: startDate,
			end: endDate,
		});
	};


	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	checkInvalidRange = () => {
		return (
			this.state.submitted &&
			(this.state.start === "" ||
				this.state.end === "" ||
				this.state.start === null ||
				this.state.end === null ||
				this.state.error !== "")
		);
	};

	// open plan
	handleSubmit = async (event) => {
		event.preventDefault();
		const { group_id, start, end, error } = this.state;
		const props = this.props;

		if (group_id && start && end && error === "") {
			const newURL = `/plans/${group_id}/${start}@${end}`;
			window.history.pushState(null, "", newURL);
			props.updateStateAndReload({ group_id, start, end });
			props.handleClose();
		}

		this.setState({
			submitted: true,
			error,
		});
	};

	render() {
		const { error, start, end, group_id, submitted } = this.state;
		const { detailed_groups } = this.props;

		const optionGroupList =
			detailed_groups.length > 0 &&
			detailed_groups.map((item, i) => {
				let multigroup = item.is_multigroup ? " (multigrupo)" : "";
				return (
					<option key={i} value={item.id}>
						{item.name}
						{multigroup}
					</option>
				);
			}, this);
		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body plan-opener-modal">

					<FormGroup controlId="group_id">
						<FormLabel>Grupo</FormLabel>
						<FormControl
							value={group_id}
							onChange={this.handleChange}
							isInvalid={submitted && group_id === ""}
							as="select"
						>
							<option value="">Seleccionar grupo</option>
							{optionGroupList}
						</FormControl>
						<FormControl.Feedback type="invalid">
							Selecciona un grupo para la planificación
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup
						className={`form-group-periodo ${this.checkInvalidRange() ? "is-invalid" : ""
							}`}
					>
						<FormLabel>Periodo</FormLabel>
						<DateRangePicker
							startDateId="startDateId"
							endDateId="endDateId"
							startDate={
								start ? moment(start) : null
							}
							endDate={end ? moment(end) : null}
							startDatePlaceholderText="Inicio"
							endDatePlaceholderText="Fin"
							onDatesChange={this.onDatesChange}
							focusedInput={this.state.focusedInput}
							hideKeyboardShortcutsPanel={true}
							isOutsideRange={() => false}
							minimumNights={0}
							onFocusChange={(focusedInput) =>
								this.setState({ focusedInput })
							}
						/>
						<FormControl
							className="hidden"
							isInvalid={this.checkInvalidRange()}
						/>

						<FormControl.Feedback type="invalid">
							{error !== "" ? error : "Indica inicio y fin de periodo"}
						</FormControl.Feedback>
					</FormGroup>

					{this.state.error_footer && (
						<div
							className="alert alert-danger alert-dismissible fade show"
							role="alert"
						>
							{this.state.error_footer}
						</div>
					)}

					{/* {this.state.errors && this.state.errors.plan_id && (
						<div
							className="alert alert-danger alert-general-error fade show"
							role="alert"
						>
							{this.state.errors.plan_id}
						</div>
					)} */}
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Abrir planificación"
						loadingText="Abriendo..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
		loading: reducers.groupsReducer.loading,
		user: reducers.authReducer.user,
	};
};


const mapDispatchToProps = (dispatch) => ({
	getAllGroups: () =>
		dispatch(
			groupsActions.getAllGroups({ sort: "+name", variant: "detailed" })
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlanOpener));

