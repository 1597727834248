import { companyService, appService, employeesService } from "services";

export const getCompany = (companyId) => async (dispatch) => {
	try {
		dispatch({ type: "LOADING" });

		var company = await companyService.getById(companyId);
		// console.log(company);
		dispatch({
			type: "GET_COMPANY",
			payload: company,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getSubscriptionTypes = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await companyService.getSubscriptionTypes();

		dispatch({
			type: "GET_SUBSCRIPTION_TYPES",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getPaymentModels = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await companyService.getPaymentModels();

		dispatch({
			type: "GET_PAYMENT_MODELS",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getCountries = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await appService.getCountries();

		dispatch({
			type: "GET_COUNTRIES",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployeesCount = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const employees = await employeesService.getActiveEmployees();
		const count = employees.ok ? employees.data.length : 0;

		dispatch({
			type: "GET_EMPLOYEES_COUNT",
			payload: count,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};
