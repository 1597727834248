const initialState = {
	custom_fields: [],
	loading: false,
	error: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_CUSTOM_FIELDS':
			return {
				...state,
				custom_fields: action.payload,
				loading: false,
				error: ''
			};
		case 'LOADING':
			return { ...state, loading: true };
		case 'ERROR':
			return {
				...state,
				error: action.payload,
				loading: false
			};

		default:
			return state;
	};
};