import { handleResponse, handleBlobResponse, } from "utils/utils";
import { authService } from "services";

export const plansService = {
	getPlanDays,
	getPlanAssignments,
	getVisits,
	setAssignments,
	getAll,
	getById,
	add,
	update, // patch plan (locked)
	cutAndPaste, // cut and paste plans
	updateAssignment, // put plan (assignment)
	remove,
	updateMultiple,
	removeMultiple,
	updateIntelligent,
	getPlanCalculations,
	getPlannedHours, // horas planificadas balance
	exportPlan,
	exportShifts,
	downloadImportTemplate,
	prepareImport,
	runImport,
	unlock,
	setFunctions,
	assignmentExchange,
	copy,
};


// gets days
function getPlanDays(group_id, start, end) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};
	const query = "?group_id=" + group_id + "&start=" + start + "&end=" + end;

	return fetch(`${global.BASE_API}plans/days${query}`, requestOptions).then(
		handleResponse
	);
}

// gets employees (assignments)
function getPlanAssignments(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	const { sort, group_id, start, end, function_id, q, employee_id, without_time_registration, started_within_a_margin, ubication_id, update_employee_ids } = options;
	let { page } = options;


	if (page === undefined)
		page = 0;

	// let query = "?page=" + page + "&start=" + start + "&end=" + end;
	let query = "?page_size=1000&start=" + start + "&end=" + end;

	if (sort !== undefined)
		query += "&sort=" + sort;

	if (group_id !== undefined)
		query += "&group_id=" + group_id;

	if (employee_id !== undefined)
		query += "&employee_id=" + employee_id;

	if (ubication_id !== undefined && ubication_id > 0)
		query += "&ubication_id=" + ubication_id;

	if (function_id !== undefined && function_id > 0)
		query += "&function_id=" + function_id;

	if (without_time_registration)
		query += "&without_time_registration=true";

	if (started_within_a_margin)
		query += "&started_within_a_margin=true";

	if (update_employee_ids && update_employee_ids.length > 0) {
		update_employee_ids.forEach(id => {
			query += "&employee_ids=" + id;
		});
	}

	if (q !== undefined && q !== "") query += "&q=" + q.replace(" ", "%20");

	console.log(query);

	return fetch(
		`${global.BASE_API}plans/assignments${query}`,
		requestOptions
	).then(handleResponse);
}

// gets last open plans
function getVisits() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}plans/visits`, requestOptions).then(
		handleResponse
	);
}

// post assignments
function setAssignments(options) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	const { group_id, start, end } = options;

	const query = "?group_id=" + group_id + "&start=" + start + "&end=" + end;

	return fetch(
		`${global.BASE_API}plans/assignments${query}`,
		requestOptions
	).then(handleResponse);
}

// gets all plans
function getAll(query) {
	if (query === undefined) query = "";

	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}plans${query}`, requestOptions).then(
		handleResponse
	);
}

// get one plan by id
function getById(id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}plans/${id}`, requestOptions).then(
		handleResponse
	);
}

// add new plan
function add(plan) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(plan),
	};

	return fetch(`${global.BASE_API}plans/`, requestOptions).then(handleResponse);
}

// PATCH update plan (locked)
function update(plan) {
	const requestOptions = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(plan),
	};

	return fetch(`${global.BASE_API}plans/${plan.id}`, requestOptions).then(
		handleResponse
	);
}

// PATCH cut and paste plans
function cutAndPaste(options) {
	const requestOptions = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
			"X-Ninja-Multiple": true,
		},
		body: JSON.stringify(options),
	};

	return fetch(`${global.BASE_API}plans/`, requestOptions).then(
		handleResponse
	);
}

// PUT update plan (shift info)
function updateAssignment(plan) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(plan),
	};

	return fetch(`${global.BASE_API}plans/${plan.id}`, requestOptions).then(
		handleResponse
	);
}

// remove plan
function remove(id) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}plans/${id}`, requestOptions).then(
		handleResponse
	);
}

// add/update multiple plans
function updateMultiple(plans) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
			"X-Ninja-Multiple": true,
		},
		body: JSON.stringify(plans),
	};

	return fetch(`${global.BASE_API}plans/`, requestOptions).then(handleResponse);
}

// remove multiple plans
function removeMultiple(plans) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
			"X-Ninja-Multiple": true,
		},
		body: JSON.stringify(plans),
	};

	return fetch(`${global.BASE_API}plans/`, requestOptions).then(handleResponse);
}

// update plan from magic plans
function updateIntelligent(iplans) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
			// "X-Ninja-Multiple": true,
		},
		body: JSON.stringify(iplans),
	};

	return fetch(`${global.BASE_API}plans/intelligent`, requestOptions).then(handleResponse);
}

function getPlanCalculations(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	const {
		group_id,
		employee_id,
		start,
		end,
		plan_view_id,
		function_id,
		q,
	} = options;

	let query = "?start=" + start + "&end=" + end;

	if (group_id !== undefined && group_id > 0) query += "&group_id=" + group_id;

	if (employee_id !== undefined && employee_id > 0)
		query += "&employee_id=" + employee_id;

	if (plan_view_id !== undefined && plan_view_id > 0)
		query += "&plan_view_id=" + plan_view_id;

	if (function_id !== undefined && function_id > 0)
		query += "&function_id=" + function_id;

	if (q !== undefined && q !== "") query += "&q=" + q.replace(" ", "%20");

	return fetch(
		`${global.BASE_API}plan-calculations${query}`,
		requestOptions
	).then(handleResponse);
}

// horas planificadas (para balance horas)

function getPlannedHours(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	const { start, end, employee_id, sort } = options;

	// let query = "?page=" + page + "&start=" + start + "&end=" + end;
	let query = "?page_size=1000&start=" + start + "&end=" + end;

	if (employee_id !== undefined)
		query += "&employee_id=" + employee_id;

	if (sort !== sort)
		query += "&sort=" + sort;

	return fetch(
		`${global.BASE_API}plans/assignments/planned-hours${query}`,
		requestOptions
	).then(handleResponse);
}

// exportar horarios y turnos planificados
function exportPlan(options) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`,
		},
	};

	const { start, end, group_id, group_ids, special } = options;

	let query = "?page_size=1000&start=" + start + "&end=" + end;

	if (group_id !== undefined && group_id > 0)
		query += "&group_id=" + group_id;

	if (group_ids !== undefined && group_ids.length > 0)
		query += "&group_ids=" + group_ids;

	if (special)
		return fetch(`${global.BASE_API}plans/assignments/export/${special}${query}`, requestOptions).then(handleResponse);

	return fetch(`${global.BASE_API}plans/assignments/export${query}`, requestOptions).then(handleResponse);
}

// exportar turnos sin horarios
function exportShifts(options) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`,
		},
	};

	const { start, end, group_id, group_ids } = options;

	let query = "?page_size=1000&start=" + start + "&end=" + end;

	if (group_id !== undefined && group_id > 0)
		query += "&group_id=" + group_id;

	if (group_ids !== undefined && group_ids.length > 0)
		query += "&group_ids=" + group_ids;

	return fetch(`${global.BASE_API}plans/assignments/export/notimes${query}`, requestOptions).then(handleResponse);
}

/**********************************************************************
 ****** IMPORT PLANS
 ***********************************************************************/

function downloadImportTemplate() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}plans/imports/template`,
		requestOptions
	).then(handleBlobResponse);
}

function prepareImport(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(`${global.BASE_API}plans/imports`, requestOptions).then(
		handleResponse
	);
}

function runImport(options) {
	const { token, absence_shift_overlap_overwrite } = options;

	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ absence_shift_overlap_overwrite }),
	};

	return fetch(
		`${global.BASE_API}plans/imports/${token}`,
		requestOptions
	).then(handleResponse);
}

// unlock plan assignments
function unlock(options) {
	const requestOptions = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	const { start, end, group_id, presence_shifts_only } = options;

	let query = "?start=" + start + "&end=" + end;

	if (group_id !== undefined)
		query += "&group_id=" + group_id;

	if (presence_shifts_only)
		query += "&presence_shifts_only=true";


	return fetch(
		`${global.BASE_API}plans/assignments/unlock${query}`,
		requestOptions
	).then(handleResponse);
}


// asignar funciones		
function setFunctions(options) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	const { group_id, start, end } = options;

	const query = "?group_id=" + group_id + "&start=" + start + "&end=" + end;

	return fetch(
		`${global.BASE_API}plans/functions${query}`,
		requestOptions
	).then(handleResponse);
}

// intercambio de tunos

function assignmentExchange(exchange_request) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(exchange_request),
	};

	return fetch(`${global.BASE_API}plan-assignment-exchanges/direct`, requestOptions).then(handleResponse);
}


// copiar plan
function copy(obj) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(obj),
	};

	return fetch(`${global.BASE_API}plans/assignments/copy`, requestOptions).then(handleResponse);
}