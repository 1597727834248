export const TIME_ALERTS = {
	"TIME_REGISTRATION_START_TOO_EARLY": "Fichaje de entrada demasiado pronto",
	"TIME_REGISTRATION_START_TOO_LATE": "Fichaje de entrada demasiado tarde",
	"TIME_REGISTRATION_END_TOO_EARLY": "Fichaje de salida demasiado pronto",
	"TIME_REGISTRATION_END_TOO_LATE": "Fichaje de salida demasiado tarde",
	"TIME_REGISTRATION_NOT_DONE": "Fichaje de entrada no realizado",
	"TIME_REGISTRATION_BREAK_TOO_SHORT": "Pausa demasiado corta",
	"TIME_REGISTRATION_BREAK_TOO_LONG": "Pausa demasiado larga",
	"TIME_REGISTRATION_BREAK_NOT_PLANNED": "Pausa no planificada",
};

export const TIME_LIST_VIEWS = {
	list: 1,
	employee_day: 2,
	employee_week: 3,
};