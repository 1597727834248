const initialState = {
	times: [],
	time_types: [],
	select_time_types: [],
	default_time_type: {},
	loading: false,
	error: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_TIME_TYPES':
			return { 
				...state, 
				time_types: action.payload,
				loading: false,
				error: ''
			};	
		case 'GET_SELECT_TIME_TYPES':
			return { 
				...state, 
				select_time_types: action.payload,
				loading: false,
				error: ''
			};				
		case 'GET_DEFAULT_TIME_TYPE':
			return { 
				...state, 
				default_time_type: action.payload,
				loading: false,
				error: ''
			};					
		case 'LOADING':
			return { ...state, loading: true };

		case 'ERROR':
			return { 
				...state, 
				error: action.payload,
				loading: false
			};			

		default: 
			return state;
	};
};


