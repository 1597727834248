import { accountsService, contractsService, rulesService, transactionsService } from "services";

export const getAccounts = (q) => async (dispatch) => {
	dispatch({
		type: 'LOADING'
	});

	try {

		var cuentas = await accountsService.getAll(q);
		//console.log(cuentas);
		dispatch({
			type: 'GET_ACCOUNTS',
			payload: cuentas.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getAccountTypes = () => async (dispatch) => {
	dispatch({
		type: 'LOADING'
	});

	try {

		var tipos = await accountsService.getAccountTypes();
		// console.log(tipos);
		dispatch({
			type: 'GET_ACCOUNT_TYPES',
			payload: tipos
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getAccountAssignments = (options) => async (dispatch) => {

	// dispatch({
	// 	type: 'LOADING'
	// });

	try {
		const response = await accountsService.getAccountAssignments(options);
		//console.log(response.data);
		dispatch({
			type: 'GET_ACCOUNT_ASSIGNMENTS',
			payload: response.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getSelectableAccounts = () => async (dispatch) => {
	try {
		var cuentas = await accountsService.getSelectable();

		dispatch({
			type: 'GET_SELECT_ACCOUNTS',
			payload: cuentas.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getAllRules = (q) => async (dispatch) => {
	try {
		var reglas = await rulesService.getAll(q);
		// console.log(reglas);
		dispatch({
			type: 'GET_RULES',
			payload: reglas.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getRulesByType = (ruleType) => async (dispatch) => {
	try {
		var reglas = await rulesService.getByType(ruleType);
		// console.log(reglas);
		dispatch({
			type: 'GET_RULES',
			payload: reglas.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getRuleTypes = () => async (dispatch) => {
	try {
		var tipos = await rulesService.getRuleTypes();
		// console.log(tipos);
		dispatch({
			type: 'GET_RULE_TYPES',
			payload: tipos
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}



export const getContracts = (options) => async (dispatch) => {
	dispatch({
		type: 'LOADING'
	});

	try {
		var contratos = await contractsService.getAll(options);
		// console.log(contratos);

		dispatch({
			type: 'GET_CONTRACTS',
			payload: contratos.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}


export const getAllTransactions = (q) => async (dispatch) => {
	try {
		var transactions = await transactionsService.getAll(q);
		// console.log(transactions);
		dispatch({
			type: 'GET_TRANSACTIONS',
			payload: transactions.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}
