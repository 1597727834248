import alertModal from './AlertModal'
import confirmModal from './ConfirmModal'
import deleteModal from './DeleteModal'
import editModal from './EditModal'
import emptyModal from './EmptyModal'

const modalTypes = {
  alertModal,
  confirmModal,
  deleteModal,
  editModal,
  emptyModal
}

export default modalTypes
