import React from 'react'
import Avatar  from 'components/Avatar';

const EmployeeListItemHeader = ({ employee }) => {
  return (
	<div className="card-top">
		<Avatar userId={employee.id} className="avatar-md" alt={employee.name} />

		<div className="card-top-info">
			<h2 className="card-title">{ employee.name } { employee.surname }</h2>
			<h3 className="card-subtitle">{ employee.position }</h3>
		</div>
	</div>
  )
}

export default EmployeeListItemHeader