import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus, FiSearch } from "react-icons/fi";
import { FormGroup, FormControl } from "react-bootstrap";
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import RuleList from 'components/Rules';
import RuleEditModal from 'components/Rules/RuleEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as rulesActions from 'actions/rulesActions';
import * as timesActions from 'actions/timesActions';
import * as shiftsActions from 'actions/shiftsActions';
import Modal from 'components/Modal';

class Rules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchRule: '',
    }

    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openNewRuleModal = this.openNewRuleModal.bind(this);
  }

  componentDidMount() {
    this.getRules();
    this.props.getSelectableAccounts();
    this.props.getSelectableTimeTypes();
    this.props.getSelectableShifts();
    this.props.getRuleTypes();
    this.props.getAccountTypes();
    this.props.getContracts();
  }

  handleChangeSearch = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => {
      this.getRules()
    });
  }

  async getRules() {
    await this.props.getAllRules(this.state.searchRule);
  }

  closeModal() {
    this.props.hideModal()
  }

  openNewRuleModal() {
    this.props.showModal({
      open: true,
      title: 'Nueva regla',
      style: { width: '670px' },
      content: <RuleEditModal handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  getContent = () => {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.rules) {
      return "Error cargando las reglas de transacción";
    }

    return (<RuleList rules={this.props.rules} />);
  }

  render() {

    return (
      <>
        <Layout className="page-rules" context="rules">

          <div className="heading">
            <h1 className="title">Reglas de transacción</h1>

            <div className="heading-filters">
              <FormGroup className="form-search" controlId="searchRule">
                <FormControl
                  type="text"
                  placeholder="Buscar..."
                  value={this.state.searchRule}
                  onChange={this.handleChangeSearch}
                />
                <FiSearch />
              </FormGroup>
            </div>
            <div className="heading-actions">
              <button type="button" title="Crear regla" onClick={this.openNewRuleModal} className="btn btn-new"><FiPlus /></button>
            </div>
          </div>

          {this.getContent()}

        </Layout>

        <Modal hideModal={this.props.hideModal} />
      </>
    )
  }
}



const mapStateToProps = (reducers) => {
  return {
    ...reducers.rulesReducer,
    ...reducers.timesReducer,
    ...reducers.shiftsReducer,
    loading: reducers.rulesReducer.loading
  }
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllRules: (q) => dispatch(rulesActions.getAllRules(q)),
  getSelectableAccounts: () => dispatch(rulesActions.getSelectableAccounts()),
  getSelectableTimeTypes: () => dispatch(timesActions.getSelectableTimeTypes()),
  getSelectableShifts: () => dispatch(shiftsActions.getSelectableShifts()),
  getRuleTypes: () => dispatch(rulesActions.getRuleTypes()),
  getAccountTypes: () => dispatch(rulesActions.getAccountTypes()),
  getContracts: (options) => dispatch(rulesActions.getContracts(options)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Rules)