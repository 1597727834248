import { handleResponse } from "utils/utils";
import { authService } from "services";

export const timeTypesService = {
    getAll,
    getSelectable,
    getDefault,
    getById,
    getContractTimeTypes,
    add,
    update,
    remove,
};

// get time types
function getAll(q) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name';

    if (q !== undefined)
        query += "&q=" + q;

    return fetch(`${global.BASE_API}time-types${query}`, requestOptions).then(handleResponse);
}

// get selectable time types
function getSelectable() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name&variant=select&enabled=true';


    return fetch(`${global.BASE_API}time-types${query}`, requestOptions).then(handleResponse);
}

// get default time type
function getDefault() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?is_default=true';

    return fetch(`${global.BASE_API}time-types${query}`, requestOptions).then(handleResponse);
}

// get time type by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}time-types/${id}`, requestOptions).then(handleResponse);
}

// get time type by employee id
function getContractTimeTypes(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name';

    if (options) {
        const { employee_id } = options;

        if (employee_id)
            query += "&employee_id=" + employee_id;
    }

    return fetch(`${global.BASE_API}time-types/contract/${query}`, requestOptions).then(handleResponse);
}

// add new time type
function add(time_type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(time_type)
    };

    return fetch(`${global.BASE_API}time-types/`, requestOptions).then(handleResponse);
}

// update time type
function update(time_type) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(time_type)
    };

    return fetch(`${global.BASE_API}time-types/${time_type.id}`, requestOptions).then(handleResponse);
}

// remove time type
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}time-types/${id}`, requestOptions).then(handleResponse);
}