import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import ContractEditModal from 'components/Contracts/ContractEditModal';
import { showModal, hideModal } from "actions/modalActions";
import Avatars from 'components/Avatar/Avatars';
import * as rulesActions from 'actions/rulesActions';
import { contractsService } from "services";
import { errorFormating } from "utils/utils";

class ContractsList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openEditContractModal = this.openEditContractModal.bind(this);
		this.openDeleteContractModal = this.openDeleteContractModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.getContracts = this.getContracts.bind(this);
	}

	componentDidMount() {

	}

	closeModal() {
		this.props.hideModal()
	}

	getContracts() {
		this.props.getContracts({ variant: "detailed" });
	}

	openEditContractModal(contract) {
		this.props.showModal({
			open: true,
			title: 'Editar contrato',
			style: { width: '860px' },
			content: <ContractEditModal contract={contract} getContracts={this.getContracts} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteContractModal(idContract) {
		this.props.showModal({
			open: true,
			title: 'Eliminar contrato',
			style: { width: '400px' },
			message: '¿Desea eliminar el contrato? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idContract),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(title, message) {
		this.props.showModal({
			open: true,
			title: title,
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idContract) => {
		const response = await contractsService.remove(idContract);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal("Error al eliminar", textError.id);
		}
		else {
			this.getContracts();
			this.closeModal();
		}
	}

	cloneContract = async (idContract) => {
		const response = await contractsService.clone(idContract);
		// console.log(response);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal("Error al clonar", textError.id || "Se ha producido un error al clonar");
		}
		else {
			this.getContracts();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="6" className="no-result">
					Sin resultados
				</td>
			</tr>
		);
	}

	getRows = () => this.props.contracts.map((contract) => {
		// console.log(contract);
		let empleados_count = "Ningún empleado asignado";
		let total_employees = contract.total_employees;
		let employees_avatars = contract.employees;

		if (total_employees === 1)
			empleados_count = "1 empleado";
		else if (total_employees > 1)
			empleados_count = total_employees + " empleados";

		return (
			<tr key={contract.id}>
				<td>
					<button className="btn-transparent" onClick={() => this.openEditContractModal(contract)}>{contract.name}</button>
					<span className='contract-description'>{contract.description}</span>
				</td>
				<td>
					<div className="avatar-container">
						{total_employees > 0 &&
							<Avatars employees={employees_avatars} />
						}
						{empleados_count}
					</div>
				</td>
				<td className="aright">
					{contract.target_hours_per_week.toString().replace(".", ",")} h
				</td>
				<td className="aright">
					{contract.work_days_per_week} {contract.work_days_per_week === 1 ? "día" : "días"}
				</td>
				<td>
					{contract.vacation_per_year} {contract.vacation_type}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditContractModal(contract)}>Editar</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.cloneContract(contract.id)}>Clonar</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openDeleteContractModal(contract.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	render() {
		return (
			<table className="table table-contracts table-zebra">
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Empleados</th>
						<th className="aright">Horas/Semana</th>
						<th className="aright">Días/Semana</th>
						<th>Vacaciones</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{this.props.contracts.length === 0 ?
						this.getEmptyRow()
						:
						this.getRows()
					}
				</tbody>
			</table>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.rulesReducer;
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getContracts: (options) => dispatch(rulesActions.getContracts(options)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ContractsList)