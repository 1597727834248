import { plansService } from "services";
import { planViewsService } from "services";

export const removeLoading = (query) => async (dispatch) => {
	dispatch({
		type: "UNLOADING",
	});
};

export const getAllPlans = (query) => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await plansService.getAll(query);

		dispatch({
			type: "GET_PLANS",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getPlanDays = (group_id, start, end) => async (dispatch) => {
	//dispatch({	type: 'LOADING'	});

	try {
		const response = await plansService.getPlanDays(group_id, start, end);

		let days = [];
		if (response.days !== undefined) days = response.days;

		// console.log(days);
		dispatch({
			type: "GET_PLAN_DAYS",
			payload: days,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getPlanAssignments = (options) => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const response = await plansService.getPlanAssignments(options);
		// console.log(response);

		dispatch({
			type: "GET_PLAN_ASSIGNMENTS",
			payload: response.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const setPlanAssignments = (options) => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const response = await plansService.setAssignments(options);
		//console.log(response);

		dispatch({
			type: "SET_PLAN_ASSIGNMENTS",
			payload: response.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

// export const getPlanEmployees = (group_id, start, end) => async (dispatch) => {

// 	dispatch({
// 		type: 'LOADING'
// 	});

// 	try{
// 		const response = await plansService.getPlanEmployees(group_id, start, end);
// 		// console.log(response);
// 		let employees = [];
// 		if (response.employees !== undefined)
// 			employees = response.employees;

// 		dispatch({
// 			type: 'GET_PLAN_EMPLOYEES',
// 			payload: employees
// 		});
// 	}
// 	catch (error){
// 		console.log('Error: ' + error.message);
// 			dispatch({
// 				type: 'ERROR',
// 				payload: error.message
// 			});
// 	}
// }

export const getPlanVisits = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const response = await plansService.getVisits();
		// console.log(response);
		dispatch({
			type: "GET_PLAN_VISITS",
			payload: response,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getPlanViews = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const response = await planViewsService.getAll();
		// console.log(response);

		dispatch({
			type: "GET_PLAN_VIEWS",
			payload: response.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getDefaultPlanView = () => async (dispatch) => {
	try {
		var default_type = await planViewsService.getDefault();
		//console.log(default_type);
		dispatch({
			type: "GET_DEFAULT_PLAN_VIEW",
			payload: default_type,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

// export const getPlanCalculations = (options) => async (dispatch) => {
// 	// dispatch({
// 	// 	type: 'LOADING'
// 	// });

// 	try {
// 		console.log("entra");
// 		const response = await plansService.getPlanCalculations(options);
// 		console.log(response);
// 		console.log(response.data);
// 		dispatch({
// 			type: "GET_PLAN_CALCULATIONS",
// 			payload: response.data,
// 		});
// 	} catch (error) {
// 		console.log("Error: " + error.message);
// 		dispatch({
// 			type: "ERROR",
// 			payload: error.message,
// 		});
// 	}
// };
