import { customFieldsService } from "services";

export const getAllCustomFields = (options) => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try {
		const list = await customFieldsService.getAll(options);

		dispatch({
			type: 'GET_CUSTOM_FIELDS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}
