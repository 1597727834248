import React from 'react';
import logoDefault from "assets/logo.svg";
import isoDefault from "assets/isotype.svg";
import { getAppName } from 'utils/utils';

const Logo = ({ type = "logo", className = "" }) => {
  const skinFolder = localStorage.getItem("skin") || "default"; // "default" como fallback

  // Construir las rutas dinámicas para el logo y el isotype
  const logoPath = `/${skinFolder}/logo.svg`;
  const isoPath = `/${skinFolder}/isotype.svg`;

  const imagePath = type === "iso" ? isoPath : logoPath;
  const defaultImage = type === "iso" ? isoDefault : logoDefault;

  const appName = getAppName();
  const alt = "Logo " + appName;

  return (
    <img
      className={`${className}`.trim()}
      src={imagePath}
      onError={(e) => (e.target.src = defaultImage)}
      alt={alt}
    />
  );
};

export default Logo;
