import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { accountsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as rulesActions from 'actions/rulesActions';

class AccountEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		let account = {
			name: '',
		}

		let isEdit = false;

		if (props.account) {
			account = props.account;
			isEdit = true;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			account
		};
	}

	handleChange = event => {
		const { account } = this.state;

		this.setState({
			account: {
				...account,
				[event.target.id]: event.target.value
			},
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;

		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await accountsService.update(this.state.account);
		}
		else {
			response = await accountsService.add(this.state.account);
		}

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getAccounts();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	render() {
		const { account } = this.state;
		const { account_types } = this.props;

		const optionList = account_types.length > 0 && account_types.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">

					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={account.name}
							onChange={this.handleChange}
							placeholder="Nombre de la cuenta"
							isInvalid={this.checkValidField('name')}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="type">
						<FormLabel>Tipo de cuenta <span className="label-required">*</span></FormLabel>

						<FormControl
							onChange={this.handleChange}
							onBlur={this.handleBlur}
							value={account.type}
							isInvalid={this.checkValidField('type')}
							as="select">
							<option value="-1">Seleccionar tipo</option>
							{optionList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{this.state.errors.type}
						</FormControl.Feedback>
					</FormGroup>

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (reducers) => {
	return reducers.rulesReducer;
};


export default connect(mapStateToProps, rulesActions)(AccountEdit)