import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus, FiSearch, FiDownload, FiMoreHorizontal } from "react-icons/fi";
import { FormGroup, FormControl, Dropdown, } from "react-bootstrap";
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import CalendarList from 'components/Calendars';
import CalendarEditModal from 'components/Calendars/CalendarEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as calendarsActions from 'actions/calendarsActions';
import Modal from 'components/Modal';

class Calendars extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchCalendar: '',
    }

    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openNewCalendarModal = this.openNewCalendarModal.bind(this);
  }

  componentDidMount() {
    this.props.getWeekdaysDefault();
    this.props.getSelectableDayTypes();
    this.getCalendars();
  }

  handleChangeSearch = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => {
      this.getCalendars()
    });
  }

  async getCalendars() {
    await this.props.getAllCalendars(this.state.searchCalendar);
  }

  closeModal() {
    this.props.hideModal()
  }

  openNewCalendarModal() {
    this.props.showModal({
      open: true,
      title: 'Nuevo calendario',
      style: { width: '670px' },
      content: <CalendarEditModal handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  getContent = () => {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.calendars) {
      return "Error cargando los calendarios";
      // return (<p className="mt30"><strong>Próximamente disponible.</strong>  <br/>De momento puedes configurar los <Link to="/day-types">tipos de día</Link></p>);
    }

    return (<CalendarList calendars={this.props.calendars} />);
  }

  render() {

    return (
      <>
        <Layout className="page-calendars" context="calendars">

          <div className="heading">
            <h1 className="title">Calendarios</h1>

            <div className="heading-filters">
              <FormGroup className="form-search" controlId="searchCalendar">
                <FormControl
                  type="text"
                  placeholder="Buscar..."
                  value={this.state.searchCalendar}
                  onChange={this.handleChangeSearch}
                />
                <FiSearch />
              </FormGroup>
            </div>
            <div className="heading-actions">
              <button type="button" title="Crear calendario" onClick={this.openNewCalendarModal} className="btn btn-new"><FiPlus /></button>

              <Dropdown>
                <Dropdown.Toggle variant="action">
                  <FiMoreHorizontal />
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight>
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      this.props.history.push("/calendars/import")
                    }
                  >
                    <FiDownload /> Importar días especiales a calendario
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {this.getContent()}

        </Layout>

        <Modal hideModal={this.props.hideModal} />
      </>
    )
  }
}



const mapStateToProps = (reducers) => {
  return reducers.calendarsReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllCalendars: (q) => dispatch(calendarsActions.getAllCalendars(q)),
  getSelectableDayTypes: () => dispatch(calendarsActions.getSelectableDayTypes()),
  getWeekdaysDefault: () => dispatch(calendarsActions.getWeekdaysDefault()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Calendars)