import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { FiMoreHorizontal } from "react-icons/fi";

class DocumentsBulkList extends React.Component {
	getActions = () => {
		return (
			<Dropdown>
				<Dropdown.Toggle variant="action">
					<FiMoreHorizontal />
				</Dropdown.Toggle>
				<Dropdown.Menu alignRight>
					<Dropdown.Item as="button">Descargar</Dropdown.Item>
					<Dropdown.Item as="button">Eliminar</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	render() {
		return (
			<table className="table table-documents table-zebra">
				<thead>
					<tr>
						<th>Documento</th>
						<th>Tamaño</th>
						<th>Enviado</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr key="1">
						<td>
							<button className="btn-transparent btn-editable document-name">
								49648876Z_nomina_04.pdf
							</button>
						</td>
						<td>0,25Mb</td>
						<td>30/04/2021</td>
						<td className="td-actions">{this.getActions()}</td>
					</tr>
					<tr key="2">
						<td>
							<button className="btn-transparent btn-editable document-name">
								37451125M_nomina_04.pdf
							</button>
						</td>
						<td>0,25Mb</td>
						<td>30/04/2021</td>
						<td className="td-actions">{this.getActions()}</td>
					</tr>
					<tr key="3">
						<td>
							<button className="btn-transparent btn-editable document-name">
								49648876Z_nomina_03.pdf
							</button>
						</td>
						<td>0,26Mb</td>
						<td>31/03/2021</td>
						<td className="td-actions">{this.getActions()}</td>
					</tr>
					<tr key="4">
						<td>
							<button className="btn-transparent btn-editable document-name">
								37451125M_nomina_03.pdf
							</button>
						</td>
						<td>0,26Mb</td>
						<td>31/03/2021</td>
						<td className="td-actions">{this.getActions()}</td>
					</tr>
					<tr key="5">
						<td>
							<button className="btn-transparent btn-editable document-name">
								49648876Z_nomina_02.pdf
							</button>
						</td>
						<td>0,25Mb</td>
						<td>28/02/2021</td>
						<td className="td-actions">{this.getActions()}</td>
					</tr>
					<tr key="6">
						<td>
							<button className="btn-transparent btn-editable document-name">
								37451125M_nomina_02.pdf
							</button>
						</td>
						<td>0,25Mb</td>
						<td>28/02/2021</td>
						<td className="td-actions">{this.getActions()}</td>
					</tr>
					<tr key="7">
						<td>
							<button className="btn-transparent btn-editable document-name">
								49648876Z_contratos.zip
							</button>
						</td>
						<td>0,25Mb</td>
						<td>01/02/2021</td>
						<td className="td-actions">{this.getActions()}</td>
					</tr>
				</tbody>
			</table>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		// ...reducers.shiftsReducer,
		// ...reducers.absencesReducer,
		user: reducers.authReducer.user,
	};
};

export default connect(mapStateToProps, null)(DocumentsBulkList);
