import React, { Component } from "react";
import { connect } from "react-redux";
import { FiMoreHorizontal, FiLock } from "react-icons/fi";
import { Dropdown } from "react-bootstrap";
import DayTypeEditModal from "components/DayTypes/DayTypeEditModal";
import { showModal, hideModal } from "actions/modalActions";
import * as calendarsActions from "actions/calendarsActions";
import { dayTypesService } from "services";
import { errorFormating } from "utils/utils";

class DayTypesList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openEditDayTypeModal = this.openEditDayTypeModal.bind(this);
		this.openDeleteDayTypeModal = this.openDeleteDayTypeModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	componentDidMount() {}

	closeModal() {
		this.props.hideModal();
	}

	openEditDayTypeModal(day_type) {
		this.props.showModal(
			{
				open: true,
				title: "Editar tipo de día",
				style: { width: "400px" },
				content: (
					<DayTypeEditModal day_type={day_type} handleClose={this.closeModal} />
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	openDeleteDayTypeModal(idDayType) {
		this.props.showModal(
			{
				open: true,
				title: "Eliminar tipo de día",
				style: { width: "400px" },
				message:
					"¿Desea eliminar el tipo de día? Esta acción no se puede deshacer",
				deleteAction: () => this.confirmDelete(idDayType),
				closeModal: this.closeModal,
			},
			"delete"
		);
	}

	openAlertModal(message) {
		this.props.showModal(
			{
				open: true,
				title: "Error al eliminar",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	confirmDelete = async (idDayType) => {
		const response = await dayTypesService.remove(idDayType);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		} else {
			this.props.getAllDayTypes();
			this.closeModal();
		}
	};

	getEmptyRow = () => {
		return <p>Sin resultados.</p>;
	};

	getRows = () =>
		this.props.day_types.map((day_type) => (
			<div className="col-sm-6 col-lg-4 col-xl-3" key={day_type.id}>
				<article className="card" key={day_type.id}>
					{day_type.system && (
						<FiLock className="icon-locked" title="Tipo de día de sistema" />
					)}
					<div className="card-top">
						<h2
							className="card-title card-title-colored"
							style={{
								backgroundColor: day_type.color && day_type.color,
								borderColor: day_type.color && day_type.color,
							}}
						>
							{day_type.name}
						</h2>
					</div>
					<div className="card-content">
						<span className="daytype-description">
							{day_type.description}
							{day_type.holiday && <span className="festivo">(festivo)</span>}
						</span>
					</div>

					<div className="card-actions bottom-right">
						<Dropdown>
							<Dropdown.Toggle variant="action">
								<FiMoreHorizontal />
							</Dropdown.Toggle>
							<Dropdown.Menu alignRight>
								<Dropdown.Item
									as="button"
									onClick={() => this.openEditDayTypeModal(day_type)}
								>
									Editar
								</Dropdown.Item>
								{!day_type.system && (
									<Dropdown.Item
										as="button"
										onClick={() => this.openDeleteDayTypeModal(day_type.id)}
									>
										Eliminar
									</Dropdown.Item>
								)}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</article>
			</div>
		));

	render() {
		return (
			<>
				{this.props.day_types.length === 0 ? (
					this.getEmptyRow()
				) : (
					<div className="row list-daytypes">{this.getRows()}</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return reducers.calendarsReducer;
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getAllDayTypes: (q) => dispatch(calendarsActions.getAllDayTypes(q)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DayTypesList);
