import React from "react";
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import { companyService } from "services";

import Layout from "components/Layout";
import Loading from "components/Loading";
import * as companyActions from "actions/companyActions";


class MyCompany extends React.Component {
	constructor(props) {
		super(props);
		//console.log(props);

		this.state = {
			isLoading: false,
			company: {},
			errors: {},
			user: this.props.user,
		};
	}

	async componentDidMount() {
		const companyId = this.props.user.company.id;
		await this.props.getCompany(companyId);
		await this.props.getCountries();

		this.setState({
			company: this.props.company,
		});
	}

	handleChange = (event) => {
		const { company } = this.state;
		this.setState({
			company: {
				...company,
				[event.target.id]: event.target.value,
			},
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const { company } = this.state;
		const response = await companyService.update(company);
		//console.log(response);

		if (!response.ok) {
			this.setState({
				submitted: true,
				isLoading: false,
				errors: errorFormating(response),
			});
		} else {
			toastr.success("¡Bien!", "Cambios guardados correctamente");

			this.setState({
				submitted: false,
				isLoading: false,
				errors: {},
			});
		}
	};

	getContent() {
		const { company } = this.state;
		const { countries } = this.props;
		//console.log(company);

		if (this.props.loading || Object.keys(company).length <= 0) {
			return <Loading />;
		}

		const optionList =
			countries.length > 0 &&
			countries.map((item, i) => {
				return (
					<option key={i} value={item.iso}>
						{item.name}
					</option>
				);
			}, this);

		return (
			<form className="form-company" onSubmit={this.handleSubmit}>
				<Row>
					<Col sm={6} md={4}>
						<FormGroup controlId="name">
							<FormLabel>
								Nombre empresa <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.name}
								onChange={this.handleChange}
								placeholder="Nombre empresa"
								isInvalid={this.checkValidField("name")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.name}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="vat_number">
							<FormLabel>
								NIF/CIF <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.vat_number}
								onChange={this.handleChange}
								placeholder="NIF o CIF de la empresa"
								isInvalid={this.checkValidField("vat_number")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.vat_number}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col sm={6} md={4}>
						<FormGroup controlId="address">
							<FormLabel>
								Dirección <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.address}
								onChange={this.handleChange}
								placeholder="Dirección empresa"
								isInvalid={this.checkValidField("address")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.address}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="postal_code">
							<FormLabel>
								Código postal <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.postal_code}
								onChange={this.handleChange}
								placeholder="Código postal empresa"
								isInvalid={this.checkValidField("postal_code")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.postal_code}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="city">
							<FormLabel>
								Ciudad <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.city}
								onChange={this.handleChange}
								placeholder="Ciudad empresa"
								isInvalid={this.checkValidField("city")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.city}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="province">
							<FormLabel>
								Provincia <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.province}
								onChange={this.handleChange}
								placeholder="Provincia empresa"
								isInvalid={this.checkValidField("province")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.province}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="country_iso">
							<FormLabel>
								País <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								onChange={this.handleChange}
								value={company.country_iso}
								isInvalid={this.checkValidField("country_iso")}
								as="select"
							>
								<option value="-1">Seleccionar país</option>
								{optionList}
							</FormControl>
							<FormControl.Feedback type="invalid">
								{this.state.errors.country_iso}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="email">
							<FormLabel>
								E-mail <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.email}
								onChange={this.handleChange}
								placeholder="E-mail empresa"
								isInvalid={this.checkValidField("email")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.email}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="web">
							<FormLabel>Web</FormLabel>
							<FormControl
								type="text"
								value={company.web}
								onChange={this.handleChange}
								placeholder="Web empresa"
								isInvalid={this.checkValidField("web")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.web}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="phone">
							<FormLabel>Teléfono</FormLabel>
							<FormControl
								type="text"
								value={company.phone}
								onChange={this.handleChange}
								placeholder="Teléfono empresa"
								isInvalid={this.checkValidField("phone")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.phone}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
				</Row>

				<SubmitButton
					type="submit"
					isLoading={this.state.isLoading}
					text="Guardar datos"
					loadingText="Guardando..."
				/>
			</form>
		);
	}

	render() {
		return (
			<Layout className="page-company">
				<div className="page-container container">
					<div className="heading">
						<h1 className="title">Mi empresa</h1>
					</div>

					<div className="page-content">{this.getContent()}</div>
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.companyReducer,
		user: reducers.authReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCompany: (company_id) => dispatch(companyActions.getCompany(company_id)),
	getCountries: () => dispatch(companyActions.getCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCompany);
