import React from 'react'

const EmptyModal = ({ closeModal, title, content, style, hasFooter }) => {
  return (
    <div className="modal-content" style={style}>
      <div className="modal-header">
      	{ title && 
	        <h5
	          className="modal-title"
	        >{title}</h5>
    	  }
        <button type="button" className="close" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {content}
      </div>

      { hasFooter &&
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={closeModal}>Cerrar</button>
      </div>
      }
    </div>
  )
}

export default EmptyModal