export const showModal = ({ modalProps, modalType }) => dispatch => {
  dispatch({
    type: 'SHOW_MODAL',
    modalProps,
    modalType
  })
}

export const hideModal = () => dispatch => {
  dispatch({
    type: 'HIDE_MODAL'
  })
}


export const updateModalLoading = (isLoading) => ({
  type: 'UPDATE_LOADING',
  payload: { isLoading }
});