import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from "react-bootstrap"
import {
	FiMoreHorizontal,
	FiSettings,
} from "react-icons/fi";
import * as timesActions from 'actions/timesActions'
import Layout from 'components/Layout'
import { widgetsService } from "services";
import { isAdmin, isSupervisor } from "utils/utils";
import { getWidgetComponent } from "components/WidgetTemplates/utils";
import Loading from "components/Loading";
import { default as WithoutTime } from "components/Dashboard/Dashboard_WithoutTime"
import { default as TimeRegistrationAlerts } from "components/Dashboard/Dashboard_TimeAlerts"

class Dashboard extends Component {

	constructor(props) {
		super(props);
		// console.log(props);

		this.state = {
			employee_widgets: []
		};
	}

	async componentDidMount() {
		this.props.getDefaultTimeType();

		const employee_id = this.props.user.id;
		this.setState({
			loading: true,
		});

		const employee_widgets = await widgetsService.getByEmployeeId(employee_id);
		// console.log(employee_widgets);

		this.setState({
			employee_widgets,
			loading: false,
		});
	}

	getWidgets = () => this.state.employee_widgets.map((widget) => getWidgetComponent(widget));

	getContent = () => {
		if (this.state.loading && !this.state.employee_widgets) {
			return <Loading />;
		}

		if (!this.state.employee_widgets) {
			return "Error cargando los widgets";
		}

		return (
			<>
				{this.getWidgets()}

				{(isAdmin() || isSupervisor()) && (<>
					<div className="widget_2x1">
						<article className="card card-without-time">
							<div className="card-top">
								<h2>Fichajes pendientes de hoy</h2>
							</div>
							<div className="card-content">
								<WithoutTime />
							</div>
						</article>
					</div>

					<div className="widget_2x1">
						<article className="card card-time-alerts">
							<TimeRegistrationAlerts />
						</article>
					</div>
				</>)}
			</>)
	};

	render() {
		return (
			<Layout className="page-dashboard">

				<div className="heading">
					<div className="heading-left">
						<h1 className="title">Inicio</h1>
					</div>

					<div className="heading-actions">
						<Dropdown>
							<Dropdown.Toggle variant="action">
								<FiMoreHorizontal />
							</Dropdown.Toggle>
							<Dropdown.Menu alignRight>
								<Dropdown.Item
									as="button"
									onClick={() => this.props.history.push("/widget-templates")}
								>
									<FiSettings /> Configurar plantillas de widgets
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>

					</div>
				</div>

				<section className="list-widgets">
					{this.getContent()}
				</section>
			</Layout>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.timesReducer,
		user: reducers.authReducer.user,
	}
};

const mapDispatchToProps = dispatch => ({
	getDefaultTimeType: () => dispatch(timesActions.getDefaultTimeType()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);