import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as employeesActions from 'actions/employeesActions';

class EmployeeUbicationEdit extends React.Component {
	constructor(props) {
		super(props);

		// console.log(props);

		let employee_ubication = {
			ubication_id: 0,
			skill: -1,
			is_default: true,
		}

		let isEdit = false;

		if (props.employee_ubication) {
			employee_ubication = {
				...props.employee_ubication,
				ubication_id: props.employee_ubication.ubication.id
			};
			isEdit = true;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			employee_ubication
		};
	}
	handleSwitch = event => {
		const { employee_ubication } = this.state;

		this.setState({
			employee_ubication: {
				...employee_ubication,
				[event.target.id]: event.target.checked
			},
		});
	}

	handleChange = event => {
		const { employee_ubication } = this.state;

		this.setState({
			employee_ubication: {
				...employee_ubication,
				[event.target.id]: event.target.value
			},
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();
		const props = this.props;

		this.setState({ isLoading: true });
		let response = '';


		const temp_employee_ubication = this.state.employee_ubication;

		if (temp_employee_ubication.skill === "-1" || temp_employee_ubication.skill === -1) {
			temp_employee_ubication.skill = null;
		}

		// console.log(temp_employee_ubication);

		// if edit => update; else => new    
		if (this.state.isEdit) {
			response = await employeesService.updateUbication(props.employee.id, temp_employee_ubication);
		}
		else {
			response = await employeesService.addUbication(props.employee.id, temp_employee_ubication);
		}

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getEmployeeUbications(props.employee.id);
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	render() {
		const { ubications } = this.props;
		const { employee_ubication } = this.state;

		var isDefault = employee_ubication.is_default;

		const optionUbicationList = ubications.length > 0 && ubications.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		const optionSkillList = Array.from({ length: 10 }, (v, i) => (
			<option key={i} value={i + 1}>{`${i + 1}`}</option>
		));

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">
					<FormGroup controlId="ubication_id">
						<FormLabel>Ubicación</FormLabel>
						<FormControl
							onChange={this.handleChange}
							onBlur={this.handleBlur}
							value={employee_ubication.ubication_id}
							isInvalid={this.checkValidField('ubication_id')}
							as="select">
							<option value="-1">Seleccionar ubicación</option>
							{optionUbicationList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{this.state.errors.ubication_id}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="skill">
						<FormLabel>Habilidad</FormLabel>
						<FormControl
							onChange={this.handleChange}
							onBlur={this.handleBlur}
							value={employee_ubication.skill}
							isInvalid={this.checkValidField('skill')}
							as="select">
							<option value="-1">Seleccionar habilidad</option>
							{optionSkillList}
							<option key="100" value="100">100</option>
						</FormControl>

						<FormControl.Feedback type="invalid">
							{this.state.errors.skill}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup className="form-group-flex mb0" controlId="is_default">
						<FormLabel>Defecto</FormLabel>
						<Switch
							controlId="is_default"
							isOn={isDefault}
							handleToggle={this.handleSwitch}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.is_default}
						</FormControl.Feedback>
					</FormGroup>

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (state, myProps) => {
	return {
		...state.employeesReducer,
		ubications: state.ubicationsReducer.ubications,
		employee: myProps.employee,
	}
};

export default connect(mapStateToProps, employeesActions)(EmployeeUbicationEdit);