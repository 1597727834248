import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { authService } from "services";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(null);

	useEffect(() => {
		const authenticate = async () => {
			if (!authService.currentUser) {
				const user = await authService.checkCookieAndSetToken();
				if (!user) {
					// Continuar solo si el usuario no ha sido autenticado por cookie
					setIsAuthenticated(false);
					// console.log("no autenticado");
				} else {
					setIsAuthenticated(true);
					// console.log("autenticado 1");
				}
			} else {
				setIsAuthenticated(true);
				// console.log("autenticado 2");

			}
		};

		authenticate();
	}, []);

	// Mientras se autentica, muestra un loading o nada
	if (isAuthenticated === null) {
		return null;
	}

	return (
		<Route
			{...rest}
			render={(props) => {
				if (!isAuthenticated) {
					// not logged in so redirect to login page with the return url
					return (
						<Redirect
							to={{ pathname: "/login", state: { from: props.location } }}
						/>
					);
				}

				const currentUser = authService.currentUser;

				// check if route is restricted by role
				if (roles) {
					const matchRole = roles.some((r) => currentUser.roles.indexOf(r) >= 0);
					// role not authorised so redirect to home page
					if (!matchRole) return <Redirect to={{ pathname: "/" }} />;
				}

				// authorised so return component
				return <Component {...props} />;
			}}
		/>
	);
};

