import React from "react";
import { connect } from "react-redux";
import * as absencesActions from "actions/absencesActions";
import { chunkArray } from "utils/utils";
import Loading from "components/Loading";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class AbsenceCalendar extends React.Component {

	getMonths = () =>
		Array.from({ length: 12 }, (item, i) => {
			const fullCalendar = this.props.employee_absence_calendar;

			const monthCalendar = fullCalendar.filter((o) => {
				let [year, month, date] = o.day.split("-")

				return month * 1 === i + 1 ? true : false
			});

			// console.log(monthCalendar);

			// si el primer dia del mes no es lunes, se añaden días vacíos de relleno
			const weekDay = monthCalendar[0].weekday;
			if (weekDay !== 1) {
				for (let i = weekDay; i > 1; i--) {
					monthCalendar.unshift({ day: null });
				}
			}

			// separa mes en semanas
			const monthCalendarWithWeeks = chunkArray(monthCalendar, 7)

			return (
				<div className="column col-sm-6 col-lg-4 col-xl-3 col-xxl-2" key={i}>
					<article className="card month">
						<h3>{new Date(0, i).toLocaleString('es-ES', { month: 'long' })}</h3>

						<table className="table-calendar">
							<thead>
								<tr>
									{this.getWeekDays()}
								</tr>
							</thead>
							<tbody>
								{this.getWeeks(monthCalendarWithWeeks)}
							</tbody>
						</table>
					</article>
				</div>
			)
		});

	// starts with a monday
	getWeekDays = () =>
		Array.from({ length: 7 }, (item, i) => (
			<th key={i}>
				{new Date(2021, 10, i + 1).toLocaleString('es-ES', { weekday: 'narrow' })}
			</th>
		));

	getWeeks = (monthCalendarWithWeeks) => monthCalendarWithWeeks.map((week, i) => {
		//console.log(week);
		return (
			<tr key={i}>
				{this.getCalendarDays(week)}
			</tr>
		);
	});


	getCalendarDays = (week) => week.map((o, i) => {
		if (o.day === null) return (<td key={i}></td>)

		let [year, month, date] = o.day.split("-")
		var className = "td-container";

		if (o.absence || o.day_type.holiday) {

			var color, tooltipContent;

			if (o.absence) {
				const shift = o.shift;
				tooltipContent = o.comments ? o.comments : shift.name + " " + shift.description;
				color = shift.background_color;
			}

			if (o.day_type.holiday) {
				const day_type = o.day_type;
				tooltipContent = o.comments ? o.comments : day_type.name + " " + day_type.description;
				color = day_type.color;
			}

			if (o.worked_holiday) {
				className += " worked-holiday";
				tooltipContent = (<><strong>[Festivo trabajado]</strong><br /> {tooltipContent}</>);
			}

			const tooltip = <Tooltip className="tooltip-calendar">{tooltipContent}</Tooltip>;



			return (
				<td key={i}>
					<div className={className}>
						<OverlayTrigger key={i} placement="top" overlay={tooltip}>
							<span style={{ color: color && color }}>{date}</span>
						</OverlayTrigger>
					</div>
				</td>
			)
		}

		return (
			<td key={i}>{date}</td>
		)
	});

	render() {
		if (this.props.loading && this.props.employee_absence_calendar.length == 0) {
			return <Loading />;
		}
		else if (this.props.employee_absence_calendar.length === 0) {
			return <p className="no-results">Error cargando el calendario.</p>;
		}

		return (<div className="row list-calendar">{this.getMonths()}</div>);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.shiftsReducer,
		...reducers.absencesReducer,
		...reducers.employeesReducer,
		user: reducers.authReducer.user,
		loading: reducers.absencesReducer.loading,
	};
};

export default connect(mapStateToProps, absencesActions)(AbsenceCalendar);
