import React from 'react';
import { connect } from "react-redux";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import moment from 'moment';
import { plansService, appService } from "services";
import { showModal, hideModal, updateModalLoading } from "actions/modalActions";
import { errorFormating } from "utils/utils";
import { DateRangePicker } from "react-dates";
import Switch from "components/Switch";
import SubmitButton from "components/SubmitButton";

class ExportPlan extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		this.state = {
			start: "",
			end: "",
			hasTimes: false,
			isLoading: false,
			submitted: false,
			temp_group: {},
			selected_groups: [],
			new_item_errors: {},
			errors: {},
			error: "",
		};
	}

	handleSubmit = async event => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const { selected_groups, start, end, hasTimes } = this.state;
		const group_ids = [];
		var token;
		var filename;

		selected_groups.length > 0 && selected_groups.map((group) => {
			group_ids.push(group.id)
			return true;
		});

		if (hasTimes) {
			// exportación plan con horarios
			token = await plansService.exportPlan({ group_ids, start, end, });
			filename = "planificacion.xlsx";
		} else {
			// exportación plan solo turnos
			token = await plansService.exportShifts({ group_ids, start, end, });
			filename = "turnos_planificados.xlsx";
		}

		if (token.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					this.openExportPlanificacionModal(token.access_token, filename);
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(token),
			});

			// this.openAlertModal(
			// 	"Ha ocurrido un error en la exportación",
			// 	"Error al exportar"
			// );
		}
	}

	// EXPORT planificación
	openExportPlanificacionModal(token, filename) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar planificación",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"La planificación se ha exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.props.handleClose,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, filename);
				},
			},
			"confirm"
		);
	}

	async downloadExport(token, filename) {
		const response = await appService.getExported(token);
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = filename;
		a.click();

		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación
	}


	onDatesChange = (dates) => {
		var { startDate, endDate } = dates;

		if (startDate)
			startDate = startDate.format("YYYY-MM-DD");

		if (endDate)
			endDate = endDate.format("YYYY-MM-DD");

		this.setState({
			start: startDate,
			end: endDate,
		});
	};

	checkInvalidRange = () => {
		return (
			this.state.submitted &&
			(this.state.start === "" ||
				this.state.end === "" ||
				this.state.start === null ||
				this.state.end === null ||
				this.state.error !== "")
		);
	};

	handleChange = event => {
		const key = event.target.id;

		this.setState({
			[key]: key === "hasTimes" ? event.target.checked : event.target.value
		});
	}

	addGroup = () => {
		let { selected_groups, temp_group } = this.state;
		let isValid = true;
		let group;
		const new_item_errors = {};

		if (temp_group === undefined) {
			isValid = false;
		}
		else {
			group = this.props.detailed_groups.find(item => item.id === parseInt(temp_group));
			if (group === undefined) {
				isValid = false;
				new_item_errors.group = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_groups.find(item => item.id === parseInt(temp_group));

			if (!exists) {
				selected_groups = selected_groups.concat(group);
			}

			this.setState({
				selected_groups,
				new_item_errors: {},
				temp_group: {},
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}
	removeGroup = (idGroup) => {
		this.setState((prevState, props) => {
			return {
				selected_groups: prevState.selected_groups.filter(item => item.id !== parseInt(idGroup))
			};
		});
	}

	getSelectedGroups = () => this.state.selected_groups.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeGroup(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	render() {
		const { hasTimes, error, temp_group, new_item_errors } = this.state;
		const { detailed_groups } = this.props;

		const optionGroupList = detailed_groups.length > 0 && detailed_groups.map((item, i) => {
			return (
				<option key={i} value={item.id}>
					{item.name}
				</option>
			);
		}, this);

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body plan-export">
					<FormGroup
						className={`form-group-periodo ${this.checkInvalidRange() ? "is-invalid" : ""
							}`}
					>
						<FormLabel>Indica el periodo a exportar</FormLabel>
						<DateRangePicker
							startDateId="startDateId"
							endDateId="endDateId"
							startDate={
								this.state.start ? moment(this.state.start) : null
							}
							endDate={this.state.end ? moment(this.state.end) : null}
							startDatePlaceholderText="Inicio"
							endDatePlaceholderText="Fin"
							onDatesChange={this.onDatesChange}
							focusedInput={this.state.focusedInput}
							hideKeyboardShortcutsPanel={true}
							isOutsideRange={() => false}
							minimumNights={0}
							onFocusChange={(focusedInput) =>
								this.setState({ focusedInput })
							}
						/>
						<FormControl
							className="hidden"
							isInvalid={this.checkInvalidRange()}
						/>

						<FormControl.Feedback type="invalid">
							{error !== "" ? error : "Indica inicio y fin de periodo"}
						</FormControl.Feedback>
					</FormGroup>


					<div
						className="form-group-flex form-group-main mb15"
					>
						<div className="flex-group mb10">
							<FormGroup controlId="temp_group">
								<FormLabel>Selecciona los grupos a planificar</FormLabel>
								<FormControl
									type="text"
									value={temp_group}
									placeholder="Selecciona el grupo"
									onChange={this.handleChange}
									isInvalid={new_item_errors.group !== undefined}
									as="select"
								>
									<option value="">Selecciona el grupo</option>
									{optionGroupList}
								</FormControl>
							</FormGroup>
							<button type="button" onClick={this.addGroup} className="btn btn-primary">Añadir</button>
						</div>
						<ul className="list-tags">
							{this.getSelectedGroups()}
						</ul>
					</div>


					<FormGroup
						className="form-group-flex form-row-switch form-group-main"
						controlId="hasTimes"
					>
						<FormLabel>
							Exportar planificación con horarios
						</FormLabel>
						<Switch
							controlId="hasTimes"
							isOn={hasTimes}
							handleToggle={this.handleChange}
						/>
					</FormGroup>


					{this.state.submitted && this.state.hasErrors && (
						<div className="form-error">
							Hay errores en el formulario. Revisa que todos los valores estén informados.
						</div>
					)}

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Exportar"
						loadingText="Exportando..."
					/>
				</div>
			</form>
		)
	}
}



const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
	}
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	updateModalLoading: (isLoading) => dispatch(updateModalLoading(isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportPlan);