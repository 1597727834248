import { timeTypesService } from "services";

export const getTimeTypes = (q) => async (dispatch) => {
	dispatch({
		type: 'LOADING'
	});

	try{

		var tipos = await timeTypesService.getAll(q);
		//console.log(tipos);
		dispatch({
			type: 'GET_TIME_TYPES',
			payload: tipos.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}

export const getSelectableTimeTypes = () => async (dispatch) => {
	try{
		// var tipos = await timeTypesService.getAll(); // test

		var tipos = await timeTypesService.getSelectable();
		 // console.log(tipos);
		dispatch({
			type: 'GET_SELECT_TIME_TYPES',
			payload: tipos.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}

export const getDefaultTimeType = () => async (dispatch) => {
	try{
		var default_type = await timeTypesService.getDefault();
		// console.log(tipos);
		dispatch({
			type: 'GET_DEFAULT_TIME_TYPE',
			payload: default_type.data[0]
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}