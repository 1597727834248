import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import CustomFieldEditModal from 'components/CustomFields/CustomFieldEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as customFieldsActions from 'actions/customFieldsActions';
import { customFieldsService } from "services";
import { errorFormating } from "utils/utils";

export const CUSTOM_FIELDS_TYPES = [{
  type: "TEXT",
  label: "Texto"
},
{
  type: "TEXT_AREA",
  label: "Texto ampliado"
},
{
  type: "NUMBER",
  label: "Numérico"
},
{
  type: "DATE",
  label: "Fecha"
},
];

class CustomFieldsList extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openEditCustomFieldModal = this.openEditCustomFieldModal.bind(this);
    this.openDeleteCustomFieldModal = this.openDeleteCustomFieldModal.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
  }

  closeModal() {
    this.props.hideModal()
  }

  openEditCustomFieldModal(custom_field) {
    this.props.showModal({
      open: true,
      title: 'Editar campo personalizado',
      style: { width: '400px' },
      content: <CustomFieldEditModal custom_field={custom_field} handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  openDeleteCustomFieldModal(idCustomField) {
    this.props.showModal({
      open: true,
      title: 'Eliminar campo personalizado',
      style: { width: '400px' },
      message: '¿Desea eliminar el campo personalizado? Esta acción no se puede deshacer',
      deleteAction: () => this.confirmDelete(idCustomField),
      closeModal: this.closeModal
    }, 'delete')
  }

  openAlertModal(message) {
    this.props.showModal({
      open: true,
      title: "Error al eliminar",
      style: { width: '400px' },
      message: message,
      closeModal: this.closeModal
    }, 'alert')
  }

  confirmDelete = async (idCustomField) => {
    const response = await customFieldsService.remove(idCustomField);

    if (!response.ok) {
      let textError = errorFormating(response);
      this.openAlertModal(textError.id);
    }
    else {
      this.props.getAllCustomFields();
      this.closeModal();
    }
  }

  getTypeLabel = (type) => {
    const custom_type = CUSTOM_FIELDS_TYPES.find(item => item.type === type);
    return custom_type.label;
  }

  getEmptyRow = () => {
    return (
      <tr>
        <td colSpan="3" className="no-result">
          Sin resultados
        </td>
      </tr>
    );
  }

  getRows = () => this.props.custom_fields.map((custom_field) => {
    const custom_type = this.getTypeLabel(custom_field.type);

    return (
      <tr key={custom_field.id}>
        <td>
          <button className="btn-transparent btn-editable" onClick={() => this.openEditCustomFieldModal(custom_field)}>{custom_field.name}</button>
        </td>
        <td>{custom_type}</td>
        <td className="td-actions">
          <Dropdown>
            <Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item as="button" onClick={() => this.openEditCustomFieldModal(custom_field)}>Editar</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => this.openDeleteCustomFieldModal(custom_field.id)}>Eliminar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  });

  render() {
    return (
      <table className="table table-custom-fields table-zebra">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Tipo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.custom_fields.length === 0 ?
            this.getEmptyRow()
            :
            this.getRows()
          }
        </tbody>
      </table>
    )
  }
}


const mapStateToProps = (reducers) => {
  return reducers.customFieldsReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllCustomFields: (options) => dispatch(customFieldsActions.getAllCustomFields(options)),
})


export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsList)