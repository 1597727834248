import { handleResponse, handleBlobResponse } from "utils/utils";
import { authService } from "services";

export const workflowsService = {
	getAllAbsences,
	getPendingAbsences,
	getManagedAbsences,
	getCounters,
	getUpcomingAbsences,
	getById,
	add,
	addWithAttachments,
	update,
	remove,
	getTypes,
	getAttachment,
	updateAttachments,
	removeAttachment,
	downloadImportTemplate,
	prepareImport,
	runImport,
	exportWorkflows
};

// get all absences
function getAllAbsences(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	const { employee_id, workflow_status } = options;
	let query = "?workflow_type=Ausencias&sort=-requested&page_size=1000"; // TEMPORAL

	if (workflow_status !== undefined)
		query += "&workflow_status=" + workflow_status;

	if (employee_id !== undefined) query += "&employee_id=" + employee_id;

	return fetch(`${global.BASE_API}workflows${query}`, requestOptions).then(
		handleResponse
	);
}

// get pending absences
function getPendingAbsences(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	let query =
		"?workflow_type=Ausencias&workflow_status=Pendiente&sort=-requested&page_size=1000"; // TEMPORAL

	if (options) {
		const { employee_id, start_from, start_to } = options;

		if (employee_id && employee_id > 0)
			query += "&employee_id=" + employee_id;

		if (start_from)
			query += "&start_from=" + start_from;

		if (start_to)
			query += "&start_to=" + start_to;
	}

	return fetch(`${global.BASE_API}workflows${query}`, requestOptions).then(
		handleResponse
	);
}

// get managed absences (Approved & denied)
function getManagedAbsences(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	let query =
		"?workflow_type=Ausencias&workflow_status=Aprobado,Denegado&sort=-requested&page_size=1000"; // TEMPORAL

	if (options) {
		const { employee_id, start_from, start_to } = options;

		if (employee_id && employee_id > 0)
			query += "&employee_id=" + employee_id;

		if (start_from)
			query += "&start_from=" + start_from;

		if (start_to)
			query += "&start_to=" + start_to;
	}

	return fetch(`${global.BASE_API}workflows${query}`, requestOptions).then(
		handleResponse
	);
}

// get workflow counters (currently total pending absences)
function getCounters() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	let query = ""; // in the future ¿?

	return fetch(
		`${global.BASE_API}workflows/counters${query}`,
		requestOptions
	).then(handleResponse);
}

// get upcoming absences
function getUpcomingAbsences(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	const { absence_in_days } = options;

	let query = "?workflow_status=Aprobado&workflow_type=Ausencias&sort=+start&page_size=1000"; // TEMPORAL

	if (absence_in_days)
		query += "&absence_in_days=" + absence_in_days;

	return fetch(`${global.BASE_API}workflows${query}`, requestOptions).then(
		handleResponse
	);
}

// get one workflow by id
function getById(id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}workflows/${id}`, requestOptions).then(
		handleResponse
	);
}

// add new workflow
function add(workflow) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(workflow),
	};

	return fetch(`${global.BASE_API}workflows`, requestOptions).then(
		handleResponse
	);
}

function addWithAttachments(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(`${global.BASE_API}workflows`, requestOptions).then(
		handleResponse
	);
}

// update workflow
function update(workflow) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(workflow),
	};

	return fetch(
		`${global.BASE_API}workflows/${workflow.id}`,
		requestOptions
	).then(handleResponse);
}

// remove workflow
function remove(id) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}workflows/${id}`, requestOptions).then(
		handleResponse
	);
}

// get workflow types
function getTypes() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}workflows/types`, requestOptions).then(
		handleResponse
	);
}

// attachments

// get one attachment by uuid
function getAttachment(workflowId, attachmentId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}workflows/${workflowId}/attachments/${attachmentId}`,
		requestOptions
	).then(handleBlobResponse);
}

// update workflow with attachments
function updateAttachments(id, formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(
		`${global.BASE_API}workflows/${id}/attachments`,
		requestOptions
	).then(handleResponse);
}

// delete attachment from workflow
function removeAttachment(workflowId, attachmentId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}workflows/${workflowId}/attachments/${attachmentId}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** IMPORT PLANS
 ***********************************************************************/

function downloadImportTemplate() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}workflows/imports/template`,
		requestOptions
	).then(handleBlobResponse);
}

function prepareImport(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(`${global.BASE_API}workflows/imports`, requestOptions).then(
		handleResponse
	);
}

function runImport(token) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}workflows/imports/${token}`,
		requestOptions
	).then(handleResponse);
}


// exportar ausencias
function exportWorkflows(options) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`,
		},
	};

	const { start_from, start_to, employee_id, workflow_status, sort } = options;

	let query = "?page_size=1000&workflow_type=Ausencias";

	if (sort !== undefined)
		query += "&sort=" + sort;
	else
		query += "&sort=-requested";

	if (start_from !== undefined)
		query += "&start_from=" + start_from;

	if (start_to !== undefined)
		query += "&start_to=" + start_to;

	if (workflow_status !== undefined)
		query += "&workflow_status=" + workflow_status;

	if (employee_id !== undefined && employee_id > 0)
		query += "&employee_id=" + employee_id;

	return fetch(`${global.BASE_API}workflows/export${query}`, requestOptions).then(handleResponse);
}