import React from 'react'

const DeleteModal = ({ closeModal, deleteAction, title, message, messageHTML, cancelText, deleteText, style }) => {
  return (
    <div className="modal-content" style={style}>
      <div className="modal-header">
        <h5
          className="modal-title"
        >{title}</h5>
        <button type="button" className="close" aria-label="Cerrar" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">

        { message &&
          <p>{message}</p>
        }

        { messageHTML &&
          <div className="modal-HTML-text" dangerouslySetInnerHTML={{__html:messageHTML}}></div>
        }

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={closeModal}>{cancelText || 'Cancelar'}</button>
        <button type="button" className="btn btn-danger" onClick={deleteAction}>{deleteText || 'Continuar'}</button>
      </div>
    </div>
  )
}

export default DeleteModal
