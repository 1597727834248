import { handleResponse, handleBlobResponse } from "../utils/utils";
import { authService } from "services";

export const appService = {
	resetPassword,
	getPasswordToken,
	newPassword,
	getInvitation,
	postInvitationPassword,
	getExported,
	getCountries,
};

// reset password (and send email to request a new one)
function resetPassword(email) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			email,
		}),
	};

	return fetch(`${global.BASE_API}auth/password`, requestOptions).then(
		handleResponse
	);
}

// checks if password token is valid to create a new one
function getPasswordToken(token) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${global.BASE_API}auth/password/${token}`, requestOptions).then(
		handleResponse
	);
}

// saves a new password
function newPassword(token, password_new) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			password_new,
		}),
	};

	return fetch(`${global.BASE_API}auth/password/${token}`, requestOptions).then(
		handleResponse
	);
}

// gets invited user from token
function getInvitation(token) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}apps/invitations/${token}`,
		requestOptions
	).then(handleResponse);
}

// saves new password for the invited user
function postInvitationPassword(token, password_new) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			password_new,
		}),
	};

	return fetch(
		`${global.BASE_API}apps/invitations/${token}/password`,
		requestOptions
	).then(handleResponse);
}

function getExported(token) {
	const requestOptions = {
		method: "GET",
		headers: {
			//   'Authorization' : `Bearer ${global.API_KEY}`,
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}store/download/${token}`,
		requestOptions
	).then(handleBlobResponse);
}

// gets countries
function getCountries() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${global.BASE_API}apps/countries?sort=+name`, requestOptions).then(
		handleResponse
	);
}
