import React from "react";
import { Link } from "react-router-dom";
import Avatar from "components/Avatar";


const getPlanHeader = (props) => props.plan_assignments.map((assignment, i) => {
	const employee = assignment.employee;
	// console.log(employee);
	const funct =
		employee.employee_with_function &&
			employee.employee_with_function.function
			? employee.employee_with_function.function.name
			: "";
	var text_ubication = "";
	if (employee.employee_with_ubication && employee.employee_with_ubication.ubication) {
		const ubication = employee.employee_with_ubication.ubication;
		text_ubication =
			(<>
				en {ubication.name}
			</>)
	}

	return (
		<th key={i} className="th-employee">
			<div className="th-wrap-inverted employee-main">
				<Link
					to={`/employees/${employee.id}`}
					target="_blank"
					className="employee-link-avatar"
					title="Abrir ficha"
				>
					<Avatar
						userId={employee.id}
						className="avatar-xxs"
						alt={employee.name}
					/>
				</Link>
				<div>
					<Link
						to={`/employees/${employee.id}`}
						target="_blank"
						className="employee-link-name substring"
						title="Abrir ficha"
					>
						{employee.name} {employee.surname}
					</Link>
					<span className="employee-info">
						<span className="employee-function substring">{funct}</span>
						<span className="employee-ubication substring">{text_ubication}</span>
					</span>
				</div>
			</div>
		</th>
	);
});

const PlanTableHead = (props) => {
	return (
		<thead>
			<tr>
				<th className="th-day th-day-extended"><div className="th-day-content"></div></th>
				{getPlanHeader(props)}
			</tr>
		</thead>
	)
};

export default PlanTableHead;
