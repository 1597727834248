import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import CalendarEditModal from 'components/Calendars/CalendarEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as calendarsActions from 'actions/calendarsActions';
import { calendarsService } from "services";
import { errorFormating } from "utils/utils";

class CalendarList extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openEditCalendarModal = this.openEditCalendarModal.bind(this);
    this.openDeleteCalendarModal = this.openDeleteCalendarModal.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
  }

  closeModal() {
    this.props.hideModal()
  }

  openEditCalendarModal(calendar) {
    this.props.showModal({
      open: true,
      title: 'Editar calendario',
      style: { width: '670px' },
      content: <CalendarEditModal calendar={calendar} handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  openDeleteCalendarModal(idCalendar) {
    this.props.showModal({
      open: true,
      title: 'Eliminar calendario',
      style: { width: '400px' },
      message: '¿Desea eliminar el calendario? Esta acción no se puede deshacer',
      deleteAction: () => this.confirmDelete(idCalendar),
      closeModal: this.closeModal
    }, 'delete')
  }

  openAlertModal(title, message) {
    this.props.showModal({
      open: true,
      title: title,
      style: { width: '400px' },
      message: message,
      closeModal: this.closeModal
    }, 'alert')
  }

  confirmDelete = async (idCalendar) => {
    const response = await calendarsService.remove(idCalendar);

    if (!response.ok) {
      let textError = errorFormating(response);
      this.openAlertModal("Error al eliminar", textError.id);
    }
    else {
      this.props.getAllCalendars();
      this.closeModal();
    }
  }

  cloneCalendar = async (idCalendar) => {
    const response = await calendarsService.clone(idCalendar);
    // console.log(response);

    if (!response.ok) {
      let textError = errorFormating(response);
      this.openAlertModal("Error al clonar", textError.id || "Se ha producido un error al clonar");
    }
    else {
      this.props.getAllCalendars();
      this.closeModal();
    }
  }

  getEmptyRow = () => {
    return (
      <tr>
        <td colSpan="3" className="no-result">
          Sin resultados
        </td>
      </tr>
    );
  }

  getRows = () => this.props.calendars.map((calendar) => {
    return (
      <tr key={calendar.id}>
        <td>
          <button className="btn-transparent btn-editable" onClick={() => this.openEditCalendarModal(calendar)}>{calendar.name}</button>
        </td>
        <td>{calendar.description}</td>
        <td className="td-actions">
          <Dropdown>
            <Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item as="button" onClick={() => this.openEditCalendarModal(calendar)}>Editar</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => this.cloneCalendar(calendar.id)}>Clonar</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => this.openDeleteCalendarModal(calendar.id)}>Eliminar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  });

  render() {
    return (
      <table className="table table-calendars table-zebra">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.calendars.length === 0 ?
            this.getEmptyRow()
            :
            this.getRows()
          }
        </tbody>
      </table>
    )
  }
}


const mapStateToProps = (reducers) => {
  return reducers.calendarsReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllCalendars: (q) => dispatch(calendarsActions.getAllCalendars(q)),
})


export default connect(mapStateToProps, mapDispatchToProps)(CalendarList)