import { ubicationsService } from "services";

export const getAllUbications = (options) => async (dispatch) => {

	// dispatch({
	// 	type: 'LOADING'
	// });

	try {
		const list = await ubicationsService.getAll(options);

		dispatch({
			type: 'GET_UBICATIONS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getSelectableUbications = (options) => async (dispatch) => {

	try {
		const list = await ubicationsService.getSelectable(options);

		dispatch({
			type: 'GET_SELECT_UBICATIONS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getEnabledUbications = () => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try {
		const list = await ubicationsService.getAll('', true);

		dispatch({
			type: 'GET_UBICATIONS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}