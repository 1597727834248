import React from 'react';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { FormLabel, FormControl } from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import { WIDGET_NAMES } from "components/WidgetTemplates/utils";

const DragHandle = sortableHandle(() => <span className="column-move"><FiMenu className="column-move-icon" /></span>);

const ColumnItem = SortableElement(({ item, index, handleColumn }) => {
	let id = item.id;


	const checked = item.checked || false;
	const name = WIDGET_NAMES[item.name];

	return (
		<li key={index} aria-label={name} title={name} className="column-item">
			<div className="column-left checkbox">
				<input
					type="checkbox"
					id={id}
					onChange={(e) => handleColumn(item, e, { action: 'select' })}
					checked={checked}
				/>
				<label
					htmlFor={id}
					className="btn-check-label"
				>
					<span className="column-item-label">{name}</span>
				</label>
			</div>
			<div className="column-right">
				<div className="column-item-selectbox">
					<FormLabel htmlFor={`width_${id}`}>
						Anchura
					</FormLabel>
					<FormControl
						id={`width_${id}`}
						onChange={(e) => handleColumn(item, e, { action: 'edit' })}
						value={item.width}
						as="select"
					>
						{[1, 2, 3, 4].map(number => (
							<option key={number} value={number}>
								{number}
							</option>
						))}
					</FormControl>
				</div>
				<div className="column-item-selectbox">
					<FormLabel htmlFor={`height_${id}`}>
						Altura
					</FormLabel>
					<FormControl
						id={`height_${id}`}
						onChange={(e) => handleColumn(item, e, { action: 'edit' })}
						value={item.height}
						as="select"
					>
						{[1, 2, 3, 4].map(number => (
							<option key={number} value={number}>
								{number}
							</option>
						))}
					</FormControl>
				</div>

				<DragHandle />
			</div>
		</li>
	)
});

const ColumnList = SortableContainer(({ items, handleColumn }) => {
	return (
		<ul className="list-columns">
			{items.map((item, index) => (
				<ColumnItem key={`item-${index}`} handleColumn={handleColumn} sortIndex={index} index={index} item={item} />
			))}
		</ul>
	);
});

export default ColumnList;