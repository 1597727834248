import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { FiDelete } from "react-icons/fi";
import { IoMdColorFill } from "react-icons/io";
import { functionsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as functionsActions from 'actions/functionsActions';
import { TwitterPicker } from 'react-color';


class FunctionEdit extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);

    // 'function' is a reserved word
    let func = {
      name: '',
      enabled: true,
      background_color: '',
      border_color: '',
    }

    let isEdit = false;

    if (props.function) {
      func = props.function;
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      func,
      isBorderPickerVisible: false,
      isBackgroundPickerVisible: false,
    };
  }

  handleChange = event => {
    const { func } = this.state;

    this.setState({
      func: {
        ...func,
        [event.target.id]: event.target.value
      },
    });
  }

  handleSwitch = event => {
    const { func } = this.state;

    this.setState({
      func: {
        ...func,
        [event.target.id]: event.target.checked
      },
    });
  }

  handleColorChange = ({ hex }, type) => {
    const { func } = this.state;

    if (hex === undefined)
      hex = '';

    this.setState({
      func: {
        ...func,
        [type]: hex
      },
      isBackgroundPickerVisible: false,
      isBorderPickerVisible: false,
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';
    const props = this.props;

    // if edit => update; else => new
    if (this.state.isEdit) {
      response = await functionsService.update(this.state.func);
    }
    else {
      response = await functionsService.add(this.state.func);
    }

    //console.log(response);

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getFunctions();
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  render() {
    const { func, errors } = this.state;
    var isActive = func.enabled;

    const onToggleBackgroundPicker = () => this.setState({ isBackgroundPickerVisible: !this.state.isBackgroundPickerVisible })
    const onToggleBorderPicker = () => this.setState({ isBorderPickerVisible: !this.state.isBorderPickerVisible })

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <FormGroup controlId="name">
            <FormLabel>Nombre <span className="label-required">*</span></FormLabel>
            <FormControl
              type="text"
              value={func.name}
              onChange={this.handleChange}
              placeholder="Nombre de la función"
              isInvalid={this.checkValidField('name')}
            />

            <FormControl.Feedback type="invalid">
              {errors.name}
            </FormControl.Feedback>
          </FormGroup>

          <Row>
            <Col sm={6} >
              <FormGroup controlId="background_color" className="form-color">
                <div className="flex-between">
                  <FormLabel>Color fondo</FormLabel>
                  <FiDelete className={`icon-remove-color ${!func.background_color && 'hidden'} `} onClick={() => this.handleColorChange("", "background_color")} />
                </div>
                <FormControl
                  type="text"
                  value={func.background_color}
                  placeholder="#FFFFFF"
                  readOnly
                  isInvalid={this.checkValidField('background_color')}
                  style={{ backgroundColor: func.background_color && func.background_color }}
                  onClick={onToggleBackgroundPicker}
                />
                <FormControl.Feedback type="invalid">
                  {errors.background_color}
                </FormControl.Feedback>

                <IoMdColorFill onClick={onToggleBackgroundPicker} />

                {this.state.isBackgroundPickerVisible && (
                  <div style={{ position: 'absolute' }}>
                    <TwitterPicker
                      color={func.background_color ? func.background_color : '#ffffff'}
                      onChangeComplete={(hex) => this.handleColorChange(hex, "background_color")}
                    />
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col sm={6} >
              <FormGroup controlId="border_color" className="form-color">

                <div className="flex-between">
                  <FormLabel>Color borde</FormLabel>
                  <FiDelete className={`icon-remove-color ${!func.border_color && 'hidden'} `} onClick={() => this.handleColorChange("", "border_color")} />
                </div>

                <FormControl
                  type="text"
                  value={func.border_color}
                  placeholder="#FFFFFF"
                  readOnly
                  isInvalid={this.checkValidField('border_color')}
                  style={{ backgroundColor: func.border_color && func.border_color }}
                  onClick={onToggleBorderPicker}
                />
                <FormControl.Feedback type="invalid">
                  {errors.border_color}
                </FormControl.Feedback>

                <IoMdColorFill onClick={onToggleBorderPicker} />

                {this.state.isBorderPickerVisible && (
                  <div style={{ position: 'absolute' }}>
                    <TwitterPicker
                      color={func.border_color ? func.border_color : '#ffffff'}
                      onChangeComplete={(hex) => this.handleColorChange(hex, "border_color")}
                    />
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <FormGroup className="form-group-flex mb0" controlId="enabled">
            <FormLabel>Activo</FormLabel>
            <Switch
              controlId="enabled"
              isOn={isActive}
              handleToggle={this.handleSwitch}
            />

            <FormControl.Feedback type="invalid">
              {errors.enabled}
            </FormControl.Feedback>
          </FormGroup>
        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}


const mapStateToProps = (reducers) => {
  return reducers.functionsReducer;
};


export default connect(mapStateToProps, functionsActions)(FunctionEdit)
