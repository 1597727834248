import React from 'react'
import Moment from 'react-moment';
import { getDiffDays } from "utils/datetime";
import { FiCalendar } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";

const AbsenceListItemPeriod = ({ absence }) => {
	// console.log(absence);



	let nTime = absence.effective_days;
	if (nTime === 0)
		nTime = getDiffDays(absence.start, absence.end);

	let sTime = "días";
	if (nTime === 1)
		sTime = "día"


	if (absence.workflow_hourly_requested) {
		nTime = absence.duration;

		sTime = "horas";
		if (nTime === 1)
			sTime = "hora"


		nTime = nTime.toString().replace(".", ",")
	}

	return (
		<>
			<div className="form-group no-form">
				<h4 className="ausencia-tipo">{absence.shift_type}</h4>
				<p className="ausencia-resumen">Petición de <span>{nTime}</span> {sTime}</p>
			</div>

			<div className="form-group no-form form-group-period flex-between">
				<div>
					<FiCalendar />
					<span>
						<Moment className="tag-dia" format="D [de] MMM">{absence.start}</Moment>
						{absence.end &&
							<Moment className="tag-dia" format="D [de] MMM">{absence.end}</Moment>
						}
					</span>
				</div>
				{(absence.attachments && absence.attachments.length > 0) && <MdAttachFile title="Tiene adjuntos" className="absence-attachment" />}
			</div>
		</>
	)
}

export default AbsenceListItemPeriod