import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import GroupSupervisorEdit from "./GroupSupervisorEditModal";
import { showModal, hideModal } from "actions/modalActions";
import Avatar from 'components/Avatar';
import * as groupsActions from "actions/groupsActions";
import { Link } from "react-router-dom";
import { groupsService } from "services";
import { errorFormating } from "utils/utils";


class GroupSupervisors extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.openNewSupervisorModal = this.openNewSupervisorModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	componentDidMount() {
		this.getSupervisors();
	}

	async getSupervisors() {
		const groupId = this.props.group.id;
		await this.props.getGroupSupervisors(groupId);
	}

	closeModal() {
		this.props.hideModal();
	}

	openNewSupervisorModal() {
		const { group } = this.props;

		this.props.showModal(
			{
				open: true,
				title: "Nuevo supervisor",
				style: { width: "400px" },
				content: (
					<GroupSupervisorEdit handleClose={this.closeModal} group={group} />
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	openEditSupervisorModal(group_supervisor) {
		const { group } = this.props;

		this.props.showModal({
			open: true,
			title: 'Editar supervisor',
			style: { width: '400px' },
			content: <GroupSupervisorEdit group_supervisor={group_supervisor} group={group} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteSupervisorModal(groupSupervisorId) {
		this.props.showModal(
			{
				open: true,
				style: { width: "350px" },
				title: "Eliminar supervisor",
				message:
					"¿Desea eliminar el supervisor de este grupo? Esta acción no se puede deshacer",
				deleteAction: () => this.confirmDelete(groupSupervisorId),
				closeModal: this.closeModal,
			},
			"delete"
		);
	}

	openAlertModal(message) {
		this.props.showModal(
			{
				open: true,
				title: "Error al eliminar",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	confirmDelete = async (groupSupervisorId) => {
		const groupId = this.props.group.id;

		const response = await groupsService.removeSupervisor(
			groupId,
			groupSupervisorId
		);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		} else {
			this.getSupervisors();
			this.closeModal();
		}
	};

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="3" className="no-result">
					Todavía no hay ningún supervisor asignado.
				</td>
			</tr>
		);
	};

	getRows = () =>
		this.props.group_supervisors.map((group_supervisor) => {
			return (
				<tr key={group_supervisor.id}>
					<td>
						<Avatar userId={group_supervisor.employee.id} className="avatar-sm" alt={group_supervisor.employee.name} />
						<Link
							className="table-link"
							to={`/employees/${group_supervisor.employee.id}`}
						>
							{group_supervisor.employee.name} {group_supervisor.employee.surname}
						</Link>
					</td>
					<td>
						{group_supervisor.read_only ? "NO" : "SI"}
					</td>
					<td className="td-actions">
						<Dropdown>
							<Dropdown.Toggle variant="action">
								<FiMoreHorizontal />
							</Dropdown.Toggle>
							<Dropdown.Menu alignRight>
								<Dropdown.Item
									as="button"
									onClick={() =>
										this.openEditSupervisorModal(group_supervisor)
									}
								>
									Editar
								</Dropdown.Item>
								<Dropdown.Item
									as="button"
									onClick={() =>
										this.openDeleteSupervisorModal(group_supervisor.id)
									}
								>
									Eliminar
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</td>
				</tr>
			);
		});

	getContent = () => {
		const { group_supervisors } = this.props;
		if (group_supervisors) {
			return (
				<table className="table table-groups table-zebra-reverse">
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Editar planificación</th>
							<th className="td-actions">
								<button
									type="button"
									title="Añadir supervisor"
									onClick={this.openNewSupervisorModal}
									className="btn btn-new"
								>
									<FiPlus />
								</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{group_supervisors.length === 0
							? this.getEmptyRow()
							: this.getRows()}
					</tbody>
				</table>
			);
		}
	};
	render() {
		return <>{this.getContent()}</>;
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
		// ...reducers.employeesReducer,
		user: reducers.authReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getGroupSupervisors: (id) => dispatch(groupsActions.getGroupSupervisors(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupSupervisors);
