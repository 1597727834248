import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal, FiChevronsUp, FiChevronsDown, FiLock } from "react-icons/fi";
import { FaFlask } from "react-icons/fa";
import { Dropdown } from 'react-bootstrap';
import { TabletOrDesktop, Mobile } from 'utils/responsive';
import RuleEditModal from 'components/Rules/RuleEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as rulesActions from 'actions/rulesActions';
import { rulesService } from "services";
import { errorFormating } from "utils/utils";

class RuleList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openEditRuleModal = this.openEditRuleModal.bind(this);
		this.openDeleteRuleModal = this.openDeleteRuleModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	closeModal() {
		this.props.hideModal()
	}

	openEditRuleModal(rule) {
		this.props.showModal({
			open: true,
			title: (rule.system) ? 'Ver regla de transacción' : 'Editar regla de transacción',
			style: { width: '670px' },
			content: <RuleEditModal rule={rule} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteRuleModal(idRule) {
		this.props.showModal({
			open: true,
			title: 'Eliminar regla de transacción',
			style: { width: '400px' },
			message: '¿Desea eliminar la regla de transacción? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idRule),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idRule) => {
		const response = await rulesService.remove(idRule);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {
			this.props.getAllRules();
			this.closeModal();
		}
	}

	getPrettyFormula = (rule) => {
		var formula_pretty = '';

		if (rule.formula && rule.accounts_in_formula) {
			formula_pretty = rule.formula;

			rule.accounts_in_formula.forEach((account) => {
				const name = account.name.replaceAll(" ", "_");
				formula_pretty = formula_pretty.replaceAll("account_id=" + account.id, name);
			});
		}
		return formula_pretty;
	}

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="6" className="no-result">
					Sin resultados
				</td>
			</tr>
		);
	}

	getRowsMobile = () => this.props.rules.map((rule) => {
		//console.log(rule);
		const labelAction = (rule.system) ? "Ver" : "Editar";
		const formula_pretty = this.getPrettyFormula(rule);

		return (
			<tr key={rule.id}>
				<td>
					<button className="btn-transparent btn-editable" onClick={() => this.openEditRuleModal(rule)}>{rule.name}</button>  <br />

					<span className="label-mini">{rule.type}</span>
					{rule.shift && rule.shift.name} {rule.time_type && rule.time_type.name} {rule.account_type && rule.account_type}
					<br />

					{rule.account ?
						(<><span className="label-mini">Cuenta</span> {rule.account.name}</>)
						:
						(<div className="substring"><span className="label-mini">Fórmula</span>{formula_pretty}</div>)
					}
				</td>
				<td className="td-icon">
					{rule.type !== "Calculada" && rule.type !== "Calculated" && rule.is_add &&
						(<FiChevronsUp title="Suma" aria-label="Suma" className="icon-operacion color-green" />)
					}
					{rule.type !== "Calculada" && rule.type !== "Calculated" && !rule.is_add &&
						(<FiChevronsDown title="Resta" aria-label="Resta" className="icon-operacion color-red" />)
					}
					{rule.type === "Calculada" && rule.type === "Calculated" &&
						(<FaFlask title="Formula" aria-label="Formula" className="icon-operacion icon-formula" />)
					}
				</td>
				<td className="td-icon">
					{rule.system &&
						<FiLock className="icon-locked" title="Cuenta de sistema" />
					}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditRuleModal(rule)}>{labelAction}</Dropdown.Item>
							{!rule.system &&
								(<Dropdown.Item as="button" onClick={() => this.openDeleteRuleModal(rule.id)}>Eliminar</Dropdown.Item>)
							}
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	getRows = () => this.props.rules.map((rule) => {

		const labelAction = (rule.system) ? "Ver" : "Editar";
		const formula_pretty = this.getPrettyFormula(rule);

		return (
			<tr key={rule.id}>
				<td>
					<button className="btn-transparent btn-editable" onClick={() => this.openEditRuleModal(rule)}>{rule.name}</button>
				</td>

				<td>
					<span className="label-mini">{rule.type}</span>
					{rule.shift && rule.shift.name} {rule.time_type && rule.time_type.name} {rule.account_type && rule.account_type}
				</td>

				<td>
					{rule.account ?
						(<><span className="label-mini">Cuenta</span> {rule.account.name}</>)
						:
						(<div className="substring"><span className="label-mini">Fórmula</span>{formula_pretty}</div>)
					}
				</td>

				<td className="td-icon">
					{rule.type !== "Calculada" && rule.is_add &&
						(<FiChevronsUp title="Suma" aria-label="Suma" className="icon-operacion color-green" />)
					}
					{rule.type !== "Calculada" && !rule.is_add &&
						(<FiChevronsDown title="Resta" aria-label="Resta" className="icon-operacion color-red" />)
					}
					{rule.type === "Calculada" &&
						(<FaFlask title="Formula" aria-label="Formula" className="icon-operacion icon-formula" />)
					}
				</td>

				<td className="td-icon">
					{rule.system &&
						<FiLock className="icon-locked" title="Cuenta de sistema" />
					}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditRuleModal(rule)}>{labelAction}</Dropdown.Item>
							{!rule.system &&
								(<Dropdown.Item as="button" onClick={() => this.openDeleteRuleModal(rule.id)}>Eliminar</Dropdown.Item>)
							}
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	render() {
		return (
			<>
				<Mobile>
					<table className="table table-rules table-mobile">
						<tbody>
							{this.props.rules.length === 0 ?
								this.getEmptyRow()
								:
								this.getRowsMobile()
							}
						</tbody>
					</table>
				</Mobile>

				<TabletOrDesktop>
					<table className="table table-rules table-zebra">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Tipo</th>
								<th>Detalles</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.rules.length === 0 ?
								this.getEmptyRow()
								:
								this.getRows()
							}
						</tbody>
					</table>
				</TabletOrDesktop>
			</>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.rulesReducer;
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getAllRules: (q) => dispatch(rulesActions.getAllRules(q)),
})


export default connect(mapStateToProps, mapDispatchToProps)(RuleList)