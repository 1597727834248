const initialState = {
	calendars: [],
	select_calendars: [],
	day_types: [],
	select_day_types: [],
	weekdays_default: [],
	loading: false,
	error: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_CALENDARS':
			return { 
				...state, 
				calendars: action.payload,
				loading: false,
				error: ''
			};
		case 'GET_SELECT_CALENDARS':
			return { 
				...state, 
				select_calendars: action.payload,
				loading: false,
				error: ''
			};			
		case 'GET_DAY_TYPES':
			return { 
				...state, 
				day_types: action.payload,
				loading: false,
				error: ''
			};		
		case 'GET_SELECT_DAY_TYPES':
			return { 
				...state, 
				select_day_types: action.payload,
				loading: false,
				error: ''
			};				
		case 'GET_WEEKDAYS_DEFAULT':
			return { 
				...state, 
				weekdays_default: action.payload,
				loading: false,
				error: ''
			};				
		case 'LOADING':
			return { ...state, loading: true };
		case 'ERROR':
			return { 
				...state, 
				error: action.payload,
				loading: false
			};			

		default: 
			return state;
	};
};