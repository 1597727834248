import React, { Component } from "react";
import moment from "moment";
import Moment from "react-moment";
import { FiGift } from "react-icons/fi";
import { employeesService } from "services";
import Avatar from "components/Avatar";

class Dashboard_Birthdays extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.state = {
			upcommig_birthdays: [],
			totalToday: 0,
		};
	}

	componentDidMount() {
		this.getUpcommingBirthdays();
	}

	async getUpcommingBirthdays() {
		const bdays = await employeesService.getByBirthday();
		// console.log(bdays);

		if (bdays.ok) {
			const list = bdays.data;

			if (list.length > 0) {
				var totalToday = 0;
				list.forEach((employee, index) => {
					const bday = employee.birthday;
					const now = new Date();

					const month = moment(bday).format("M");
					const day = moment(bday).format("D");
					const year = moment(now).format("YYYY");

					const fakeDate = new Date(year, month - 1, day);
					// var age = moment(now).diff(bday, "years");
					const isToday = moment(fakeDate).isSame(now, "day");

					employee.isToday = isToday;
					// employee.age = age;
					employee.fakeDate = fakeDate;

					if (isToday) totalToday += 1;
				});
			}

			this.setState({
				upcommig_birthdays: list.slice(0, 4),
				totalToday,
			});
		}
	}

	getBirthdayRows = (isToday) =>
		this.state.upcommig_birthdays.map((employee, index) => {
			// var age = employee.age;

			// if (!isToday) age += 1;

			if (employee.isToday === isToday) {
				return (
					<li key={index}>
						<Avatar
							userId={employee.id}
							className="avatar-xs"
							alt={employee.name}
						/>
						<div className="item-main">
							<span className="item-name">
								{employee.name} {employee.surname}
							</span>
							{!isToday && (
								<span className="item-info">
									<Moment format="DD/MM/YY">{employee.fakeDate}</Moment>
								</span>
							)}
						</div>
					</li>
				);
			} else return "";
		});

	render() {
		const { upcommig_birthdays, totalToday } = this.state;

		if (upcommig_birthdays.length > 0) {
			return (
				<>
					<h4 className="title">Hoy</h4>
					{totalToday > 0 ? (
						<ul className="list-employees">{this.getBirthdayRows(true)}</ul>
					) : (
						<p>No hay ningún cumpleaños hoy</p>
					)}

					<h4 className="title">Próximos días</h4>

					{upcommig_birthdays.length - totalToday > 0 ? (
						<ul className="list-employees">{this.getBirthdayRows(false)}</ul>
					) : (
						<p className="mt15">No hay cumpleaños en los próximos 15 días</p>
					)}
				</>
			);
		} else {
			return (
				<div className="empty">
					<FiGift />
					<p>No hay cumpleaños en los próximos 15 días</p>
				</div>
			);
		}
	}
}

export default Dashboard_Birthdays;
