import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import { TabletOrDesktop, Mobile } from 'utils/responsive';
import ShiftPatternEditModal from 'components/ShiftPatterns/ShiftPatternEditModal';
import { showModal, hideModal } from "actions/modalActions";
import { shiftPatternsService } from "services";
import { errorFormating } from "utils/utils";

class ShiftPatternsList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openEditShiftPatternModal = this.openEditShiftPatternModal.bind(this);
		this.openDeleteShiftPatternModal = this.openDeleteShiftPatternModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.getShiftPatterns = this.props.getShiftPatterns.bind(this);
	}

	closeModal() {
		this.props.hideModal()
	}

	openEditShiftPatternModal(shift_pattern) {
		this.props.showModal({
			open: true,
			title: 'Editar rueda de turno',
			style: { width: '850px' },
			content: <ShiftPatternEditModal getShiftPatterns={this.getShiftPatterns} shift_pattern={shift_pattern} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteShiftPatternModal(idShiftPattern) {
		this.props.showModal({
			open: true,
			title: 'Eliminar rueda de turno',
			style: { width: '400px' },
			message: '¿Desea eliminar la rueda de turno? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idShiftPattern),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(title, message) {
		this.props.showModal({
			open: true,
			title: title,
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	cloneShiftPattern = async (idShiftPattern) => {
		const response = await shiftPatternsService.clone(idShiftPattern);
		// console.log(response);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal("Error al clonar", textError.id || "Se ha producido un error al clonar");
		}
		else {
			this.getShiftPatterns();
			this.closeModal();
		}
	}

	confirmDelete = async (idShiftPattern) => {
		const response = await shiftPatternsService.remove(idShiftPattern);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal("Error al eliminar", textError.id);
		}
		else {
			this.getShiftPatterns();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="5" className="no-result">
					Sin resultados
				</td>
			</tr>
		);
	}
	getRowsMobile = () => this.props.shift_patterns.map((shift_pattern) => {
		return (
			<tr key={shift_pattern.id}>
				<td>
					<button className="btn-transparent btn-editable" onClick={() => this.openEditShiftPatternModal(shift_pattern)}>
						{shift_pattern.name} <br />
						<span className="label-mini">{shift_pattern.description}</span>
					</button>
				</td>
				<td>{shift_pattern.items.length} días</td>
				<td>
					{shift_pattern.enabled
						? <span title='Activo' className='estado estado-si'>Activo</span>
						: <span title='Inactivo' className='estado estado-no'>Inactivo</span>
					}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditShiftPatternModal(shift_pattern)}>Editar</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.cloneShiftPattern(shift_pattern.id)}>Clonar</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openDeleteShiftPatternModal(shift_pattern.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	getRows = () => this.props.shift_patterns.map((shift_pattern) => {

		return (
			<tr key={shift_pattern.id}>
				<td>
					<button className="btn-transparent btn-editable" onClick={() => this.openEditShiftPatternModal(shift_pattern)}>{shift_pattern.name}</button>
				</td>
				<td>{shift_pattern.description}</td>
				<td>{shift_pattern.items.length} días</td>
				<td>
					{shift_pattern.enabled
						? <span title='Activo' className='estado estado-si'>Activo</span>
						: <span title='Inactivo' className='estado estado-no'>Inactivo</span>
					}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditShiftPatternModal(shift_pattern)}>Editar</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.cloneShiftPattern(shift_pattern.id)}>Clonar</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openDeleteShiftPatternModal(shift_pattern.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	render() {
		return (
			<>
				<Mobile>
					<table className="table table-shift-patterns table-mobile">
						<tbody>
							{this.props.shift_patterns.length === 0 ?
								this.getEmptyRow()
								:
								this.getRowsMobile()
							}
						</tbody>
					</table>
				</Mobile>

				<TabletOrDesktop>
					<table className="table table-shift-patterns table-zebra">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Descripción</th>
								<th>Longitud</th>
								<th>Estado</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.shift_patterns.length === 0 ?
								this.getEmptyRow()
								:
								this.getRows()
							}
						</tbody>
					</table>
				</TabletOrDesktop>
			</>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.shiftsReducer;
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
})


export default connect(mapStateToProps, mapDispatchToProps)(ShiftPatternsList)