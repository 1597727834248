import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as userActions from 'actions/userActions';
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Logo from "components/Logo";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      submitted: false,
      username: "",
      password: "",
      user: {},
      errors: {}
    };
  }

  enableSubmitButton() {
    return (
      this.state.username.length > 0 &&
      this.state.password.length > 0
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const { username, password } = this.state;

    await this.props.login(username, password);

    if (this.props.loggedIn) {
      this.props.history.push("/");
    }
    else {
      this.setState({
        submitted: true,
        isLoading: false,
        errors: errorFormating(this.props)
      });
    }
  }

  render() {
    const { error_message } = this.props;

    return (
      <div className="Auth">
        <Logo className="logo" type="logo" />

        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="username">
            <FormLabel>Email</FormLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.username}
              onChange={this.handleChange}
              isInvalid={this.checkValidField('username')}
              placeholder="E-mail de acceso"
            />
            <FormControl.Feedback type="invalid">
              {this.state.errors.username}
            </FormControl.Feedback>
          </FormGroup>
          <FormGroup controlId="password">
            <FormLabel>Contraseña</FormLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              isInvalid={this.checkValidField('password')}
              placeholder="Contraseña"
            />
            <FormControl.Feedback type="invalid">
              {this.state.errors.password}
            </FormControl.Feedback>
          </FormGroup>

          {error_message && <div className="alert alert-danger">{error_message}</div>}

          <SubmitButton
            block
            disabled={!this.enableSubmitButton()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Iniciar sesión"
            loadingText="Iniciando..."
          />

        </form>

        <p className="remember-password">
          <Link to="/remember-password">¿Has olvidado la contraseña?</Link>
        </p>

        <p className="login-register">
          ¿No tienes cuenta? <Link to="/register">Regístrate</Link>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.authReducer;
};

export default connect(mapStateToProps, userActions)(Login);