import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { FiTarget } from "react-icons/fi";
import { MdWorkOutline } from "react-icons/md";
import { IoMdStats } from "react-icons/io";
import moment from "moment";
import { accountsService } from "services";
import { isAdmin, isSupervisor } from "utils/utils";

const CalendarHeader = ({ employee_id, year, plan_views, updateIndicator }) => {
	const [counters, setCounters] = useState([]);
	const [worked_hours, setWorkedHours] = useState([]);
	const [show, setShow] = useState(false);
	// console.log(plan_views);
	var isDefaultCalendar = false;

	if (isAdmin() || isSupervisor()) {
		const selected_plan_view = plan_views.find(plan_view => plan_view.my_calendar === true);
		isDefaultCalendar = !selected_plan_view || plan_views.length < 2 || selected_plan_view.system === true;
	}
	// console.log({ plan_views });
	// console.log({ isDefaultCalendar });

	// ñapa chunga para validar que son las cuentas por defecto
	const validateAccounts = (data) => {
		// Definir los labels requeridos como un Set para búsqueda eficiente
		const requiredLabelsSet = new Set([
			"Horas trabajadas",
			"Horas objetivo",
			"Balance periodo",
			"Vacaciones a disfrutar año",
			"Vacaciones disfrutadas año",
			"Vacaciones pendientes"
		]);

		// Crear un Set a partir de los labels de entrada para eliminar duplicados y permitir búsqueda eficiente
		const inputLabelsSet = new Set(data.map(item => item.label));

		// Comprobar que cada label requerido esté presente en los labels de entrada
		const allRequiredPresent = [...requiredLabelsSet].every(label => inputLabelsSet.has(label));

		// Comprobar que no haya labels en los datos de entrada que no estén en los requeridos
		const noExtraLabels = [...inputLabelsSet].every(label => requiredLabelsSet.has(label));

		return allRequiredPresent && noExtraLabels;
	};

	useEffect(() => {
		const start_from = moment(year + "-01-01").startOf("year").format("YYYY-MM-DD");
		const start_to = moment(year + "-01-01").endOf("year").format("YYYY-MM-DD");

		async function getCounters() {
			const response = await accountsService.getAccountAssignments({
				employee_id,
				start: start_from,
				end: start_to,
				my_calendar: true
			});

			// console.log("getCounters");
			// console.log(response);
			setCounters(response);
		}

		async function getWorkedHours() {
			const response = await accountsService.getWorkedHours({
				employee_id,
				start: start_from,
				end: start_to
			});

			// console.log("getWorkedHours");
			// console.log(response);
			setWorkedHours(response);
		}

		// request info
		getCounters();
		getWorkedHours();

	}, [employee_id, year, updateIndicator]);

	function findAndFormatValue(data, property, name) {
		const raw = data[0].counters.find(counter => counter[property] && counter[property].name === name);
		return raw ? raw.value.toLocaleString("de-DE", { maximumFractionDigits: 2 }) : null;
	}
	let horas_trabajadas = 0;
	let horas_objetivo = 0;
	let balance = 0;


	// default counters
	if (counters && counters.ok) {
		const data = counters.data;
		// console.log(data);

		if (data && data.length > 0 && data[0].counters) {
			isDefaultCalendar = validateAccounts(data[0].counters);
			// console.log({ isDefaultCalendar });

			horas_trabajadas = findAndFormatValue(data, 'account', 'Horas trabajadas');
			horas_objetivo = findAndFormatValue(data, 'account', 'Horas objetivo');
			balance = findAndFormatValue(data, 'transaction_rule', 'Balance');
		}
	}



	const showDefaultCounters = () => {
		return (<>
			<Col sm={12} md={6} lg={4}>
				<article className="widget-content">
					<span className="widget-title title-yellow">
						Horas trabajadas
					</span>
					<div className="widget-main">
						<span className="widget-counter">{horas_trabajadas}</span>
						<span className="icon-calendar-wrapper">
							<MdWorkOutline className="icon-calendar" />
						</span>
					</div>
				</article>
			</Col>
			<Col sm={12} md={6} lg={4}>
				<article className="widget-content">
					<span className="widget-title title-blue">
						Horas objetivo
					</span>
					<div className="widget-main">
						<span className="widget-counter">{horas_objetivo}</span>
						<span className="icon-calendar-wrapper">
							<FiTarget className="icon-calendar" />
						</span>
					</div>
				</article>
			</Col>
			<Col sm={12} md={6} lg={4}>
				<article className="widget-content">
					<span className="widget-title title-green">
						Balance
					</span>
					<div className="widget-main">
						<span className="widget-counter">{balance}</span>
						<span className="icon-calendar-wrapper">
							<IoMdStats className="icon-calendar" />
						</span>
					</div>
				</article>
			</Col>
		</>);
	}

	const showCustomCounters = (data) => {
		const colorClasses = ['title-red', 'title-blue', 'title-green', 'title-yellow', 'title-blue-dark', 'title-purple', 'title-orange', 'title-grey'];
		const countersLength = data && data[0].counters.length;

		// Determina el valor de las columnas basado en el número de elementos
		let columns;
		if (countersLength > 6) {
			columns = 3;
		} else if (countersLength === 2) {
			columns = 6;
		} else {
			columns = 4;
		}

		return data && data[0].counters.map((counter, index) => {
			const colorClass = colorClasses[index % colorClasses.length];
			const value = counter.value.toLocaleString("de-DE", { maximumFractionDigits: 2 });

			return (
				<Col sm={12} md={6} lg={columns} key={index}>
					<article className="widget-content">
						<span className={`widget-title ${colorClass}`}>
							{counter.label}
						</span>
						<div className="widget-main">
							<span className="widget-counter">{value}</span>
						</div>
					</article>
				</Col>
			);
		});
	};
	return (
		<>
			<Row className="header-widgets">
				{isDefaultCalendar ? showDefaultCounters() : showCustomCounters(counters.data)}
			</Row>
			{horas_trabajadas != 0 && worked_hours.length > 0 &&
				<button className="btn btn-outline-primary btn-caption" onClick={() => setShow(!show)}>
					{show ? "Ocultar detalles" : "Ver más detalles"}
				</button>}
			{show && getInfo(worked_hours)}
		</>
	);
};

const getInfo = (worked_hours) => {
	return (<div className="calendar-caption">
		<Tabla worked_hours={worked_hours} />
	</div>);
}

const Tabla = ({ worked_hours }) => {
	const calcularTotales = (time_intervals) => {
		let entrada = null;
		let salida = null;
		let totalHoras = 0;

		const trabajoIntervals = time_intervals.filter(ti => ti.time_type.name === 'Trabajo');

		if (trabajoIntervals.length > 0) {
			entrada = trabajoIntervals[0].start;
			salida = trabajoIntervals[trabajoIntervals.length - 1].end;

			totalHoras = trabajoIntervals.reduce((acc, curr) => {
				let startHour = parseInt(curr.start.split(':')[0]);
				let startMinute = parseInt(curr.start.split(':')[1]);
				let endHour = parseInt(curr.end.split(':')[0]);
				let endMinute = parseInt(curr.end.split(':')[1]);

				let diff;

				if (endHour >= startHour) {
					// Trabajo durante el mismo día
					diff = ((endHour * 60 + endMinute) - (startHour * 60 + startMinute)) / 60;
				} else {
					// Trabajo nocturno (pasa al siguiente día)
					diff = ((24 * 60 - (startHour * 60 + startMinute)) + (endHour * 60 + endMinute)) / 60;
				}

				return acc + diff;
			}, 0);
		}

		// console.log(totalHoras);

		return {
			entrada,
			salida,
			totalHoras
		};
	};


	return (
		<table className="table table-hours table-bordered">
			<thead>
				<tr>
					<th>Turno</th>
					<th>Entrada</th>
					<th>Salida</th>
					<th>Total día</th>
					<th>Total Horas</th>
				</tr>
			</thead>
			<tbody>
				{worked_hours.length > 0 && worked_hours.map((dato, index) => {
					const { entrada, salida, totalHoras } = calcularTotales(dato.shift.time_intervals);

					return (
						<tr key={index}>
							<td style={{ backgroundColor: dato.shift.background_color }}>
								{dato.shift.name}
							</td>
							<td>{entrada}</td>
							<td>{salida}</td>
							<td>{totalHoras}h</td>
							<td>{dato.value}h</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default CalendarHeader;
