const initialState = {
	shifts: [],
	default_shift: {},
	select_shifts: [],
	attendance_shifts: [],
	non_attendance_shifts: [],
	shift_types: [],
	shift_patterns: [],
	select_shift_patterns: [],
	working_hours: {},
	loading: false,
	error: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_SHIFTS':
			return {
				...state,
				shifts: action.payload,
				loading: false,
				error: ''
			};
		case 'GET_DEFAULT_SHIFT':
			return {
				...state,
				default_shift: action.payload,
				loading: false,
				error: ''
			};
		case 'GET_SELECT_SHIFTS':
			return {
				...state,
				select_shifts: action.payload,
				loading: false,
				error: ''
			};
		case 'GET_ATTENDANCE_SHIFTS':
			return {
				...state,
				attendance_shifts: action.payload,
				loading: false,
				error: ''
			};
		case 'GET_NON_ATTENDANCE_SHIFTS':
			return {
				...state,
				non_attendance_shifts: action.payload,
				loading: false,
				error: ''
			};
		case 'GET_SHIFT_TYPES':
			return {
				...state,
				shift_types: action.payload,
				loading: false,
			};
		case 'GET_SHIFT_PATTERNS':
			return {
				...state,
				shift_patterns: action.payload,
				loading: false,
			};
		case 'GET_SELECT_SHIFT_PATTERNS':
			return {
				...state,
				select_shift_patterns: action.payload,
				loading: false,
			};
		case 'GET_WORKING_HOURS':
			return {
				...state,
				working_hours: action.payload,
				loading: false,
			};
		case 'LOADING':
			return { ...state, loading: true };
		case 'ERROR':
			return {
				...state,
				error: action.payload,
				loading: false
			};

		default:
			return state;
	};
};