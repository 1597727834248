import { handleResponse } from "utils/utils";
import { authService } from "services";

export const contractsService = {
    getAll,
    getById,
    add,
    update,
    remove,
    clone,
};

// get contracts
function getAll(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name';

    if (options) {
        let { variant, q } = options;

        if (q !== undefined)
            query += "&q=" + q;

        if (variant !== undefined && variant !== "")
            query += "&variant=" + variant;
    }

    return fetch(`${global.BASE_API}contracts${query}`, requestOptions).then(handleResponse);
}

// get contract by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}contracts/${id}`, requestOptions).then(handleResponse);
}

// add new contract
function add(contract) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(contract)
    };

    return fetch(`${global.BASE_API}contracts/`, requestOptions).then(handleResponse);
}

// update contract
function update(contract) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(contract)
    };

    return fetch(`${global.BASE_API}contracts/${contract.id}`, requestOptions).then(handleResponse);
}

// remove contract
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}contracts/${id}`, requestOptions).then(handleResponse);
}

// clone contract
function clone(id) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
    };

    return fetch(`${global.BASE_API}contracts/${id}/clone`, requestOptions).then(
        handleResponse
    );
}