import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
	FiMoreHorizontal,
	FiExternalLink,
	FiFileText,
	FiUser,
	FiInbox,
} from "react-icons/fi";

class MyDocuments extends React.Component {
	getCategories = () => {
		return (
			<div className="row list-documents">
				<div className="col-sm-6">
					<NavLink className="category-link" to="/documents/paysheets" exact>
						<article className="document-category">
							<FiInbox className="category-icon" />
							<h3 className="category-name">Nóminas</h3>
							<p className="category-count">3 documentos</p>
						</article>
					</NavLink>
				</div>
				<div className="col-sm-6">
					<article className="document-category">
						<FiFileText className="category-icon" />
						<h3 className="category-name">Contratos</h3>
						<p className="category-count">1 documento</p>
					</article>
				</div>
				<div className="col-sm-6">
					<article className="document-category">
						<FiExternalLink className="category-icon" />
						<h3 className="category-name">Ausencias</h3>
						<p className="category-count">0 documentos</p>
					</article>
				</div>
				<div className="col-sm-6">
					<article className="document-category">
						<FiUser className="category-icon" />
						<h3 className="category-name">Documentos personales</h3>
						<p className="category-count">3 documentos</p>
					</article>
				</div>
			</div>
		);
	};

	getContent = (category) => {
		if (category == "absences") {
			var icon = <FiExternalLink className="category-icon" />;
			var categoryName = "Ausencias";
		} else if (category == "contracts") {
			var icon = <FiFileText className="category-icon" />;
			var categoryName = "Contratos";
		} else if (category == "personal") {
			var icon = <FiUser className="category-icon" />;
			var categoryName = "Documentos personales";
		} else {
			var icon = <FiInbox className="category-icon" />;
			var categoryName = "Nóminas";
		}

		return (
			<>
				<div className="category-header">
					{icon}
					<h3 className="category-name">{categoryName}</h3>
				</div>
				<table className="table table-documents table-zebra">
					<thead>
						<tr>
							<th>Documento</th>
							<th>Tamaño</th>
							<th>Creado</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr key="1">
							<td>
								<button className="btn-transparent btn-editable document-name">
									Nómina_abril_2021.pdf
								</button>
							</td>
							<td>0,25Mb</td>
							<td>30/04/2021</td>
							<td className="td-actions">{this.getActions()}</td>
						</tr>
						<tr key="2">
							<td>
								<button className="btn-transparent btn-editable document-name">
									Nómina_marzo_2021.pdf
								</button>
							</td>
							<td>0,26Mb</td>
							<td>31/03/2021</td>
							<td className="td-actions">{this.getActions()}</td>
						</tr>
						<tr key="3">
							<td>
								<button className="btn-transparent btn-editable document-name">
									Nómina_febrero_2021.pdf
								</button>
							</td>
							<td>0,25Mb</td>
							<td>28/02/2021</td>
							<td className="td-actions">{this.getActions()}</td>
						</tr>
					</tbody>
				</table>
			</>
		);
	};

	getActions = () => {
		return (
			<Dropdown>
				<Dropdown.Toggle variant="action">
					<FiMoreHorizontal />
				</Dropdown.Toggle>
				<Dropdown.Menu alignRight>
					<Dropdown.Item as="button">Descargar</Dropdown.Item>
					<Dropdown.Item as="button">Eliminar</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	render() {
		console.log(this.props.category);
		const cat = this.props.category;

		return cat !== undefined ? this.getContent(cat) : this.getCategories();
	}
}

const mapStateToProps = (reducers) => {
	return {
		// ...reducers.shiftsReducer,
		// ...reducers.absencesReducer,
		user: reducers.authReducer.user,
	};
};

export default connect(mapStateToProps, null)(MyDocuments);
