import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, FormControl } from "react-bootstrap";
import { FiPlus, FiSearch } from "react-icons/fi";
import CustomFieldEditModal from 'components/CustomFields/CustomFieldEditModal';
import CustomFieldsList from 'components/CustomFields';
import * as customFieldsActions from "actions/customFieldsActions";
import Loading from "components/Loading";
import Layout from "components/Layout";
import Modal from "components/Modal";
import { showModal, hideModal } from "actions/modalActions";

class CustomFields extends Component {
	constructor(props) {
		super(props);
		//console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.openNewCustomFieldModal = this.openNewCustomFieldModal.bind(this);

		this.state = {
			search_field: "",
		};
	}

	handleChangeSearch = event => {
		const targetId = event.target.id;

		this.setState({
			[targetId]: event.target.value
		}, () => {
			this.getCustomFields()
		});
	}

	async getCustomFields() {
		await this.props.getAllCustomFields({
			q: this.state.search_field,
		});
	}

	componentDidMount() {
		this.getCustomFields();
	}

	closeModal() {
		this.props.hideModal();
	}

	openNewCustomFieldModal() {
		this.props.showModal(
			{
				open: true,
				title: "Nuevo campo personalizado",
				style: { width: "400px" },
				content: <CustomFieldEditModal handleClose={this.closeModal} />,
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	openEditCustomFieldModal(custom_field) {
		this.props.showModal(
			{
				open: true,
				title: "Editar campo personalizado",
				style: { width: "400px" },
				content: (
					<CustomFieldEditModal
						custom_field={custom_field}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	getContent = () => {
		if (this.props.loading) {
			return <Loading />;
		}

		if (this.props.error) {
			return "No se ha podido cargar el listado de campos personalizados";
		}

		return <CustomFieldsList />
	};

	render() {
		return (<>
			<Layout className="page-custom-fields" context="employees">
				<div className="heading">
					<div className="heading-left">
						<h1 className="title">Campos personalizados</h1>
					</div>
					<div className="heading-filters">
						<FormGroup className="form-search" controlId="search_field">
							<FormControl
								type="text"
								placeholder="Buscar..."
								value={this.state.search_field}
								onChange={this.handleChangeSearch}
							/>
							<FiSearch />
						</FormGroup>
					</div>

					<div className="heading-actions">
						<button
							type="button"
							title="Crear grupo"
							onClick={this.openNewCustomFieldModal}
							className="btn btn-new"
						>
							<FiPlus />
						</button>
					</div>
				</div>

				{this.getContent()}
			</Layout>

			<Modal hideModal={this.props.hideModal} />
		</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.customFieldsReducer,
		loading: reducers.customFieldsReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getAllCustomFields: (options) => dispatch(customFieldsActions.getAllCustomFields(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);