const initialState = {
	rules: [],
	rule_types: [],
	accounts: [],
	contracts: [],
	select_accounts: [],
	account_types:[],
	account_assignments:[],
	transactions:[],
	loading: false,
	error: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_RULES':
			return { 
				...state, 
				rules: action.payload,
				loading: false,
				error: ''
			};	
		case 'GET_RULE_TYPES':
			return { 
				...state, 
				rule_types: action.payload,
				loading: false,
				error: ''
			};				
		case 'GET_ACCOUNTS':
			return { 
				...state, 
				accounts: action.payload,
				loading: false,
				error: ''
			};	
		case 'GET_SELECT_ACCOUNTS':
			return { 
				...state, 
				select_accounts: action.payload,
				loading: false,
				error: ''
			};				
		case 'GET_ACCOUNT_TYPES':
			return { 
				...state, 
				account_types: action.payload,
				loading: false,
				error: ''
			};	
		case 'GET_ACCOUNT_ASSIGNMENTS':
			return { 
				...state, 
				account_assignments: action.payload,
				loading: false,
				error: ''
			};				
		case 'GET_CONTRACTS':
			return { 
				...state, 
				contracts: action.payload,
				loading: false,
				error: ''
			};	
		case 'GET_TRANSACTIONS':
			return { 
				...state, 
				transactions: action.payload,
				loading: false,
				error: ''
			};														
		case 'LOADING':
			return { ...state, loading: true };

		case 'ERROR':
			return { 
				...state, 
				error: action.payload,
				loading: false
			};			

		default: 
			return { 
				...state, 
				loading: false
			};	
	};
};


