const initialState = {
	functions: [],
	select_functions: [],
	loading: false,
	error: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_FUNCTIONS':
			return { 
				...state, 
				functions: action.payload,
				loading: false,
				error: ''
			};			
		case 'GET_SELECT_FUNCTIONS':
			return { 
				...state, 
				select_functions: action.payload,
				loading: false,
				error: ''
			};			
		case 'LOADING':
			return { ...state, loading: true };
		case 'ERROR':
			return { 
				...state, 
				error: action.payload,
				loading: false
			};			

		default: 
			return state;
	};
};