import {
	handleResponse,
	handleImgResponse,
	handleBlobResponse,
} from "utils/utils";
import { authService } from "services";

export const employeesService = {
	getAll,
	getSelectable,
	getActiveEmployees,
	getById,
	add,
	update,
	remove,
	newPassword,
	getByBirthday,
	sendInvitation,
	downloadImportTemplate,
	prepareImport,
	runImport,
	exportEmployees,
	saveAvatar,
	getAvatar,
	deleteAvatar,
	getGroups,
	addGroup,
	updateGroup,
	removeGroup,
	getFunctions,
	addFunction,
	updateFunction,
	removeFunction,
	getUbications,
	addUbication,
	updateUbication,
	removeUbication,
	getShiftPatterns,
	addShiftPattern,
	updateShiftPattern,
	removeShiftPattern,
	downloadShiftPatternImportTemplate,
	prepareShiftPatternImport,
	runShiftPatternImport,
	downloadUbicationImportTemplate,
	prepareUbicationImport,
	runUbicationImport,
	downloadFunctionImportTemplate,
	prepareFunctionImport,
	runFunctionImport,
	getContracts,
	addContract,
	updateContract,
	removeContract,
	getAttachment,
	addAttachments,
	removeAttachment,
	getAbsences,
	getEmployeesByGroupOrdered,
	updateEmployeesOrder,
	getSeparatedRestsEmployees,
	addSeparatedRestsEmployee,
	removeSeparatedRestsEmployee,
	getSeparatedRestsBackup,
	addSeparatedRestsBackup,
	removeSeparatedRestsBackup,
};

// gets all employees
function getAll(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	let query = "?sort=+name,+surname&page_size=1000"; // TEMPORAL, HASTA QUE SE IMPLEMENTE PAGINACION EN EL BACKEND

	if (options) {
		const { q, group_id, enabled, variant } = options;

		if (q !== undefined)
			query += "&q=" + q;

		if (group_id !== undefined)
			query += "&group_id=" + group_id;

		if (enabled)
			query += "&enabled=" + enabled;

		if (variant)
			query += "&variant=" + variant;
	}

	return fetch(`${global.BASE_API}employees${query}`, requestOptions).then(
		handleResponse
	);
}

// get selectable employees
function getSelectable(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};
	// add enabled true/false?
	let query = "?sort=+name,+surname&variant=select&page_size=1000"; // TEMPORAL, HASTA QUE SE IMPLEMENTE PAGINACION EN EL BACKEND

	if (options) {
		const { q, group_id, enabled } = options;

		if (q !== undefined)
			query += "&q=" + q;

		if (group_id !== undefined)
			query += "&group_id=" + group_id;

		if (enabled)
			query += "&enabled=" + enabled;
	}

	return fetch(`${global.BASE_API}employees${query}`, requestOptions).then(
		handleResponse
	);
}

// get enabled employees
function getActiveEmployees() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"X-Ninja-In-Subscription-Process": "true",
		},
	};

	let query = "?sort=+name,+surname&enabled=true&variant=select&page_size=1000"; // TEMPORAL, HASTA QUE SE IMPLEMENTE PAGINACION EN EL BACKEND

	return fetch(`${global.BASE_API}employees${query}`, requestOptions).then(
		handleResponse
	);
}

// get one employee by id
function getById(id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}employees/${id}`, requestOptions).then(
		handleResponse
	);
}

// add new employee
function add(employee) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employee),
	};

	return fetch(`${global.BASE_API}employees/`, requestOptions).then(
		handleResponse
	);
}

// update employee
function update(employee) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employee),
	};

	return fetch(
		`${global.BASE_API}employees/${employee.id}`,
		requestOptions
	).then(handleResponse);
}

// remove employee
function remove(id) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}employees/${id}`, requestOptions).then(
		handleResponse
	);
}

// update password from employee
function newPassword(password_old, password_new) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			password_old,
			password_new,
		}),
	};

	return fetch(
		`${global.BASE_API}employees/${authService.currentUser.id}/password`,
		requestOptions
	).then(handleResponse);
}

// get employees by closest bday date
function getByBirthday() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};
	let query =
		"?enabled=true&page=0&birthday_in_days=15&sort=+birthday,+name,+surname";

	return fetch(`${global.BASE_API}employees${query}`, requestOptions).then(
		handleResponse
	);
}

/**********************************************************************
 ****** INVITATION
 ***********************************************************************/

// resend invitation to employee (to create new password)
function sendInvitation(id) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}employees/${id}/invitations`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** IMPORT EMPLOYEES
 ***********************************************************************/

function downloadImportTemplate() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}employees/imports/template`,
		requestOptions
	).then(handleBlobResponse);
}

function prepareImport(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(`${global.BASE_API}employees/imports`, requestOptions).then(
		handleResponse
	);
}

function runImport(token) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/imports/${token}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** EXPORT EMPLOYEES
 ***********************************************************************/
function exportEmployees(options) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`,
		},
	};

	const { enabled, group_id, q, sort } = options;

	let query = "?page_size=1000&variant=select";

	if (sort !== undefined)
		query += "&sort=" + sort;
	else
		query += "&sort=+name,+surname";

	if (enabled !== undefined)
		query += "&enabled=" + enabled;

	if (group_id !== undefined)
		query += "&group_id=" + group_id;

	if (q !== undefined)
		query += "&q=" + q;


	return fetch(`${global.BASE_API}employees/export${query}`, requestOptions).then(handleResponse);
}

/**********************************************************************
 ****** AVATARS
 ***********************************************************************/

// save avatar
function saveAvatar(id, image_data) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			mime_type: "image/png",
			file_data: image_data,
		}),
	};

	return fetch(`${global.BASE_API}employees/${id}/avatar`, requestOptions).then(
		handleResponse
	);
}

// get avatar
function getAvatar(id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${global.BASE_API}employees/${id}/avatar`, requestOptions).then(
		handleImgResponse
	);
}

// remove avatar
function deleteAvatar(id) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${global.BASE_API}employees/${id}/avatar`, requestOptions).then(
		handleResponse
	);
}

/**********************************************************************
 ****** GROUPS
 ***********************************************************************/

function getGroups(employeeId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/groups`,
		requestOptions
	).then(handleResponse);
}

// add new group to employee
function addGroup(employeeId, employeeGroup) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeGroup),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/groups`,
		requestOptions
	).then(handleResponse);
}

// update employee-group
function updateGroup(employeeId, employeeGroup) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeGroup),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/groups/${employeeGroup.id}`,
		requestOptions
	).then(handleResponse);
}

// remove employee-group
function removeGroup(employeeId, employeeGroupId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/groups/${employeeGroupId}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** FUNCTIONS
 ***********************************************************************/

function getFunctions(employeeId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/functions`,
		requestOptions
	).then(handleResponse);
}

// add new function to employee
function addFunction(employeeId, employeeFunction) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeFunction),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/functions`,
		requestOptions
	).then(handleResponse);
}

// update employee-function
function updateFunction(employeeId, employeeFunction) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeFunction),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/functions/${employeeFunction.id}`,
		requestOptions
	).then(handleResponse);
}

// remove employee-function
function removeFunction(employeeId, employeeFunctionId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/functions/${employeeFunctionId}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** UBICATIONS
 ***********************************************************************/

function getUbications(employeeId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/ubications`,
		requestOptions
	).then(handleResponse);
}

// add new ubication to employee
function addUbication(employeeId, employeeUbication) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeUbication),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/ubications`,
		requestOptions
	).then(handleResponse);
}

// update employee-ubication
function updateUbication(employeeId, employeeUbication) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeUbication),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/ubications/${employeeUbication.id}`,
		requestOptions
	).then(handleResponse);
}

// remove employee-ubication
function removeUbication(employeeId, employeeUbicationId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/ubications/${employeeUbicationId}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** SHIFT PATTERNS
 ***********************************************************************/

function getShiftPatterns(employeeId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/shift-patterns`,
		requestOptions
	).then(handleResponse);
}

// add new shift-pattern to employee
function addShiftPattern(employeeId, employeeShiftPattern) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeShiftPattern),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/shift-patterns`,
		requestOptions
	).then(handleResponse);
}

// update employee - shift-pattern
function updateShiftPattern(employeeId, employeeShiftPattern) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeShiftPattern),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/shift-patterns/${employeeShiftPattern.id}`,
		requestOptions
	).then(handleResponse);
}

// remove employee - shift pattern
function removeShiftPattern(employeeId, employeeShiftPatternId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/shift-patterns/${employeeShiftPatternId}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** IMPORTS
 ***********************************************************************/

// SHIFT PATTERNS

function downloadShiftPatternImportTemplate() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}employees/shift-patterns/imports/template`,
		requestOptions
	).then(handleBlobResponse);
}

function prepareShiftPatternImport(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(`${global.BASE_API}employees/shift-patterns/imports`, requestOptions).then(
		handleResponse
	);
}

function runShiftPatternImport(token) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/shift-patterns/imports/${token}`,
		requestOptions
	).then(handleResponse);
}


// UBICATIONS

function downloadUbicationImportTemplate() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}employees/ubications/imports/template`,
		requestOptions
	).then(handleBlobResponse);
}

function prepareUbicationImport(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(`${global.BASE_API}employees/ubications/imports`, requestOptions).then(
		handleResponse
	);
}

function runUbicationImport(token) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/ubications/imports/${token}`,
		requestOptions
	).then(handleResponse);
}

// FUNCTIONS

function downloadFunctionImportTemplate() {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}employees/functions/imports/template`,
		requestOptions
	).then(handleBlobResponse);
}

function prepareFunctionImport(formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(`${global.BASE_API}employees/functions/imports`, requestOptions).then(
		handleResponse
	);
}

function runFunctionImport(token) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/functions/imports/${token}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** CONTRACTS
 ***********************************************************************/

function getContracts(employeeId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/contracts`,
		requestOptions
	).then(handleResponse);
}

// add new contract to employee
function addContract(employeeId, employeeContract) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeContract),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/contracts`,
		requestOptions
	).then(handleResponse);
}

// update employee-contract
function updateContract(employeeId, employeeContract) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employeeContract),
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/contracts/${employeeContract.id}`,
		requestOptions
	).then(handleResponse);
}

// remove employee-contract
function removeContract(employeeId, employeeContractId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/contracts/${employeeContractId}`,
		requestOptions
	).then(handleResponse);
}

// attachments (documents)

// get one employee attachment by uuid
function getAttachment(employeeId, attachmentId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/attachments/${attachmentId}`,
		requestOptions
	).then(handleBlobResponse);
}

// add attachments to employee
function addAttachments(employeeId, formData) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
		body: formData,
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/attachments`,
		requestOptions
	).then(handleResponse);
}

// delete attachment from employee
function removeAttachment(employeeId, attachmentId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employeeId}/attachments/${attachmentId}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** ABSENCES/WORKFLOWS
 ***********************************************************************/

// get all absences
function getAbsences(employeeId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	let query = "?workflow_type=Ausencias&sort=-requested";

	return fetch(
		`${global.BASE_API}employees/${employeeId}/workflows${query}`,
		requestOptions
	).then(handleResponse);
}


/**********************************************************************
 ****** Orden empleados dentro de un grupo
 ***********************************************************************/


// get employees
function getEmployeesByGroupOrdered(group_id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}employees/plan-group-order/${group_id}`, requestOptions).then(
		handleResponse
	);
}


// update order
function updateEmployeesOrder(employee_list_ids, group_id) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(employee_list_ids),
	};

	return fetch(
		`${global.BASE_API}employees/plan-group-order/${group_id}`,
		requestOptions
	).then(handleResponse);
}


/**********************************************************************
 ****** Añadir empleados a restricciones de planificación
 ***********************************************************************/

// get empleados que no se pueden solapar
function getSeparatedRestsEmployees(employee_id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}employees/${employee_id}/separated-rests`, requestOptions).then(
		handleResponse
	);
}

// añadir empleado de restricciones de planificación
function addSeparatedRestsEmployee(employee_main_id, employee_other_id) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employee_main_id}/separated-rests/${employee_other_id}`,
		requestOptions
	).then(handleResponse);
}

// delete empleado de restricciones de planificación
function removeSeparatedRestsEmployee(employee_main_id, employee_other_id) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employee_main_id}/separated-rests/${employee_other_id}`,
		requestOptions
	).then(handleResponse);
}



/**********************************************************************
 ****** Empleado de backup
 ***********************************************************************/

// get empleado backup
function getSeparatedRestsBackup(employee_id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}employees/${employee_id}/separated-rests-backup`, requestOptions).then(
		handleResponse
	);
}

// añadir empleado debackup
function addSeparatedRestsBackup(employee_id, employee_backup_id) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employee_id}/separated-rests-backup/${employee_backup_id}`,
		requestOptions
	).then(handleResponse);
}

// delete empleado de backup
function removeSeparatedRestsBackup(employee_id) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}employees/${employee_id}/separated-rests-backup/`,
		requestOptions
	).then(handleResponse);
}
