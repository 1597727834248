import React, { Component } from "react";
import { connect } from "react-redux";
import { FiPlus, FiMoreHorizontal, FiUpload } from "react-icons/fi";
import { FormGroup, FormControl, Dropdown } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
// import Loading from 'components/Loading';
import Layout from "components/Layout";
import TransactionList from "components/Transactions";
import TransactionEditModal from "components/Transactions/TransactionEditModal";
import { showModal, hideModal, updateModalLoading } from "actions/modalActions";
import { transactionsService, appService } from "services";
import * as rulesActions from "actions/rulesActions";
import * as employeesActions from "actions/employeesActions";
import Modal from "components/Modal";

import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";

///transactions/:employee/:account@:transaction_rule/:start@:end

class Transactions extends Component {
	constructor(props) {
		super(props);

		const today = new Date();
		const startOfMonth = moment(today).startOf("month").format("YYYY-MM-DD");
		const endOfMonth = moment(today).endOf("month").format("YYYY-MM-DD");

		this.state = {
			filterEmployeeId: this.props.match.params.employee || "",
			filterAccountId: this.props.match.params.account || "",
			filterDayFrom: this.props.match.params.start || startOfMonth,
			filterDayTo: this.props.match.params.end || endOfMonth,
			filterType: "",
			focusedInput: null,
			transactions: [],
			page: 0, // result pagination
			hasMore: true, // list has more results to fetch
		};

		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewTransactionModal = this.openNewTransactionModal.bind(this);
		this.getTransactions = this.getTransactions.bind(this);
		this.openExportModal = this.openExportModal.bind(this);
	}

	componentDidMount() {
		this.getTransactions();
		this.props.getSelectableAccounts();
		this.props.getSelectableEmployees();
	}

	fetchMoreData = () => {
		const { page } = this.state;
		this.setState(
			{
				page: page + 1,
			},
			() => {
				this.getTransactions();
			}
		);
	};

	async getTransactions() {
		const {
			transactions,
			filterEmployeeId,
			filterAccountId,
			filterDayFrom,
			filterDayTo,
			filterType,
			page,
		} = this.state;
		const response = await transactionsService.getAll(
			filterEmployeeId,
			filterAccountId,
			filterDayFrom,
			filterDayTo,
			filterType,
			page
		);
		//console.log(response);
		let new_transactions = response.data;

		if (page > 0) {
			new_transactions = transactions.concat(new_transactions);
		}

		// console.log(new_transactions);

		if (response.ok) {
			this.setState({
				transactions: new_transactions,
				hasMore: response.data.length > 0,
			});
		} else {
			this.setState({
				error: true,
			});
		}
	}

	handleChangeSearch = (event) => {
		// console.log(event);
		this.setState(
			{
				[event.target.id]: event.target.value,
				page: 0,
			},
			() => {
				this.getTransactions();
			}
		);
	};

	onDatesChange = (dates) => {
		var { startDate, endDate } = dates;

		if (startDate) startDate = startDate.format("YYYY-MM-DD");

		if (endDate) endDate = endDate.format("YYYY-MM-DD");

		this.setState(
			{
				page: 0, // reset pagination
				filterDayFrom: startDate,
				filterDayTo: endDate,
			},
			() => {
				this.getTransactions();
			}
		);
	};

	closeModal() {
		this.props.hideModal();
	}

	openNewTransactionModal() {
		this.props.showModal(
			{
				open: true,
				title: "Nueva transacción manual",
				style: { width: "420px" },
				content: (
					<TransactionEditModal
						getTransactions={this.getTransactions}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	// export transactions

	openExportModal(token) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar transacciones",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"Las transacciones del periodo seleccionado se han exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token);
				},
			},
			"confirm"
		);
	}


	async handleExport() {
		const { filterDayFrom, filterDayTo, filterEmployeeId, filterAccountId, filterType, } = this.state;
		const token = await transactionsService.exportList({
			day_from: filterDayFrom,
			day_to: filterDayTo,
			employee_id: filterEmployeeId,
			account_id: filterAccountId,
			type: filterType
		});

		if (token.ok) {
			this.openExportModal(token.access_token);
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación de transacciones",
				"Error al exportar"
			);
		}
	}

	async downloadExport(token) {
		//console.log(token);
		const response = await appService.getExported(token);
		// console.log(response);

		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = "transacciones.xlsx";
		a.click();

		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación
	}

	getContent = () => {
		if (!this.state.transactions) {
			return "Error cargando las transacciones";
		}

		return (
			<TransactionList
				getTransactions={this.getTransactions}
				transactions={this.state.transactions}
			/>
		);
	};

	render() {
		const { select_accounts, select_employees } = this.props;

		const optionEmployeeList =
			select_employees.length > 0 &&
			select_employees.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name} {item.surname}
					</option>
				);
			}, this);

		const optionAccountList =
			select_accounts.length > 0 &&
			select_accounts.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		return (
			<>
				<Layout className="page-transactions" context="rules">
					<div className="heading">
						<h1 className="title">Transacciones</h1>

						<div className="heading-filters">
							<FormGroup
								className="form-select-user"
								controlId="filterEmployeeId"
							>
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.filterEmployeeId}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="">Todos los empleados</option>
									{optionEmployeeList}
								</FormControl>
							</FormGroup>

							<FormGroup className="form-search" controlId="filterAccountId">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.filterAccountId}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="">Filtrar por cuenta</option>
									{optionAccountList}
								</FormControl>
							</FormGroup>
							<FormGroup className="form-search" controlId="filterType">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.filterType}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="">Filtrar por tipo</option>
									<option value="Contrato">Contrato</option>
									<option value="Manual">Manual</option>
									<option value="Regla">Regla</option>
								</FormControl>
							</FormGroup>
							<DateRangePicker
								startDateId="filterDayFrom"
								endDateId="filterDayTo"
								startDate={
									this.state.filterDayFrom
										? moment(this.state.filterDayFrom)
										: null
								}
								endDate={
									this.state.filterDayTo ? moment(this.state.filterDayTo) : null
								}
								startDatePlaceholderText="Inicio"
								endDatePlaceholderText="Fin"
								onDatesChange={this.onDatesChange}
								focusedInput={this.state.focusedInput}
								hideKeyboardShortcutsPanel={true}
								isOutsideRange={() => false}
								minimumNights={0}
								onFocusChange={(focusedInput) =>
									this.setState({ focusedInput })
								}
							/>
						</div>
						<div className="heading-actions">
							<button
								type="button"
								title="Crear transacción"
								onClick={this.openNewTransactionModal}
								className="btn btn-new"
							>
								<FiPlus />
							</button>

							<Dropdown>
								<Dropdown.Toggle variant="action">
									<FiMoreHorizontal />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item
										as="button"
										onClick={() => this.handleExport()}
									>
										<FiUpload className="dropdown-icon" /> Exportar a excel

									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					<InfiniteScroll
						dataLength={this.state.transactions.length}
						next={this.fetchMoreData}
						endMessage={
							this.state.transactions.length > 0 && (
								<p className="no-more-results">No hay más transacciones</p>
							)
						}
						hasMore={this.state.hasMore}
					//loader={<Loading />}
					>
						{this.getContent()}
					</InfiniteScroll>
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.employeesReducer,
		...reducers.rulesReducer,
		loading: reducers.rulesReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	updateModalLoading: (isLoading) => dispatch(updateModalLoading(isLoading)),
	getSelectableAccounts: () => dispatch(rulesActions.getSelectableAccounts()),
	getSelectableEmployees: (options) =>
		dispatch(employeesActions.getSelectableEmployees(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
