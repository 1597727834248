import { handleResponse } from "utils/utils";
import { authService } from "services";

export const groupsService = {
	getAll,
	getById,
	add,
	update,
	remove,
	getStaffingLevels,
	addStaffingLevel,
	updateStaffingLevel,
	removeStaffingLevel,
	getSupervisors,
	addSupervisor,
	editSupervisor,
	removeSupervisor,
};

// gets all groups
function getAll(options) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	let query = "?sort=+name";

	if (options) {
		let { id, sort, variant, q, is_multigroup, calendar_id_null, include_own_group } = options;

		if (q === undefined) q = "";

		query = "?q=" + q;

		if (id) query += "&id=" + id;

		if (sort !== undefined && sort !== "")
			query += "&sort=" + sort;
		else
			query += "&sort=+name";

		if (variant !== undefined && variant !== "") query += "&variant=" + variant;

		if (calendar_id_null !== undefined)
			query += "&calendar_id_null=" + calendar_id_null;

		if (is_multigroup !== undefined) query += "&is_multigroup=" + is_multigroup;

		if (include_own_group) query += "&include_own_group=true";
	}

	return fetch(`${global.BASE_API}groups${query}`, requestOptions).then(
		handleResponse
	);
}

// get one group by id
function getById(id) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}groups/${id}`, requestOptions).then(
		handleResponse
	);
}

// add new group
function add(group) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(group),
	};

	return fetch(`${global.BASE_API}groups/`, requestOptions).then(
		handleResponse
	);
}

// update group
function update(group) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(group),
	};

	return fetch(`${global.BASE_API}groups/${group.id}`, requestOptions).then(
		handleResponse
	);
}

// remove group
function remove(id) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}groups/${id}`, requestOptions).then(
		handleResponse
	);
}

/**********************************************************************
 ****** staffing levels assigned to groups
 ***********************************************************************/

function getStaffingLevels(groupId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}groups/${groupId}/staffing-levels`,
		requestOptions
	).then(handleResponse);
}

// add new staffing level to group
function addStaffingLevel(groupId, groupStaffingLevel) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(groupStaffingLevel),
	};

	return fetch(
		`${global.BASE_API}groups/${groupId}/staffing-levels`,
		requestOptions
	).then(handleResponse);
}

// update group-staffing
function updateStaffingLevel(groupId, groupStaffingLevel) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(groupStaffingLevel),
	};

	return fetch(
		`${global.BASE_API}groups/${groupId}/staffing-levels/${groupStaffingLevel.id}`,
		requestOptions
	).then(handleResponse);
}

// remove group-staffing
function removeStaffingLevel(groupId, groupStaffingLevelId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}groups/${groupId}/staffing-levels/${groupStaffingLevelId}`,
		requestOptions
	).then(handleResponse);
}

/**********************************************************************
 ****** group supervisors
 ***********************************************************************/

function getSupervisors(groupId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}groups/${groupId}/supervisors`,
		requestOptions
	).then(handleResponse);
}

// add new staffing level to group
function addSupervisor(groupId, groupSupervisor) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(groupSupervisor),
	};

	return fetch(
		`${global.BASE_API}groups/${groupId}/supervisors`,
		requestOptions
	).then(handleResponse);
}


function editSupervisor(groupId, groupSupervisor) {
	const requestOptions = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(groupSupervisor),
	};

	return fetch(
		`${global.BASE_API}groups/${groupId}/supervisors/${groupSupervisor.id}`,
		requestOptions
	).then(handleResponse);
}

// remove group-staffing
function removeSupervisor(groupId, groupSupervisorId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(
		`${global.BASE_API}groups/${groupId}/supervisors/${groupSupervisorId}`,
		requestOptions
	).then(handleResponse);
}
