import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiThumbsUp } from "react-icons/fi"
import { Link } from 'react-router-dom'
import { FormGroup, FormControl } from "react-bootstrap";
import * as groupsActions from "actions/groupsActions";
import Avatar from "components/Avatar";
import { timesService } from "services";
import { TIME_ALERTS } from "components/Times/utils";



class Dashboard_TimeAlerts extends Component {

	constructor(props) {
		super(props);
		this.state = {
			alerts: [],
			filtered_alerts: [],
		};
	}

	componentDidMount() {
		this.getAlerts();
		this.getGroups();
	}

	async getAlerts() {
		const response = await timesService.alertsToday();

		// console.log(response);

		if (response.ok) {
			this.setState({
				alerts: response,
				filtered_alerts: response,
				loading: false,
			});
		}
	}

	async getGroups() {
		await this.props.getAllGroups();
	}

	handleChangeSearch = event => {
		const { alerts } = this.state;
		const value = event.target.value;

		this.setState({
			filtered_alerts: (value < 0) ? alerts : alerts.filter((a) => a.group_id === parseInt(value))
		});
	}

	getAlertRows = () => this.state.filtered_alerts.map((alert, i) => {
		return (
			<tr key={i}>
				<td>
					<span className='employee-name'>
						<Link to={`/times/${alert.employee_id}/employee`}>
							<Avatar
								userId={alert.employee_id}
								className="avatar-xs"
								alt={alert.employee_name}
							/>
							{alert.employee_name}
						</Link>
					</span>

				</td>
				<td>
					{alert.plan_time ? alert.plan_time : "-"}
				</td>
				<td>
					{alert.time_registration_time ? alert.time_registration_time : "-"}
				</td>
				<td>{TIME_ALERTS[alert.type]}</td>
			</tr>
		)
	});

	getCardTop = () => {
		const { groups } = this.props;
		const { alerts } = this.state;


		const optionList = groups.length > 0 && groups.map((item, i) => {
			return (
				<option key={i} value={item.id}>
					{item.name}
				</option>
			);
		}, this);

		return (
			<div className="heading">
				<h2>
					Alertas de fichajes
				</h2>
				{alerts.length > 0 &&
					<div className="heading-actions">
						<FormGroup className="form-search" controlId="filterGroupId">
							<FormControl
								type="text"
								placeholder="Buscar..."
								value={this.state.filterGroupId}
								onChange={this.handleChangeSearch}
								as="select"
							>
								<option value="-1">Filtrar por grupo</option>
								{optionList}
							</FormControl>
						</FormGroup>
					</div>
				}
			</div>
		);
	};

	getContent = () => {
		const { alerts, filtered_alerts } = this.state;

		if (alerts.length === 0 && filtered_alerts.length === 0) {
			return (
				<div className="empty">
					<FiThumbsUp />
					<p>No hay alertas</p>
				</div>
			);
		}
		else if (filtered_alerts.length > 0) {
			return (
				<table className="table table-zebra table-alerts">
					<thead>
						<tr>
							<th>Empleado</th>
							<th>Hora plan</th>
							<th>Hora real</th>
							<th>Tipo</th>
						</tr>
					</thead>
					<tbody>
						{this.getAlertRows()}
					</tbody>
				</table>
			);
		} else {
			return (
				<div className="empty">
					<p>No hay resultados para este grupo</p>
				</div>
			);
		}
	};

	render() {
		return (
			<>
				<div className="card-top">
					{this.getCardTop()}
				</div>
				<div className="card-content">
					{this.getContent()}
				</div>
			</>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.timesReducer,
		...reducers.groupsReducer,
		user: reducers.authReducer.user,
	}
};

const mapDispatchToProps = (dispatch) => ({
	getAllGroups: () => dispatch(groupsActions.getAllGroups({ sort: "+name" })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard_TimeAlerts);