import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal, FiLock } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import AccountEditModal  from 'components/Accounts/AccountEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as rulesActions from 'actions/rulesActions';
import { accountsService } from "services";
import { errorFormating } from "utils/utils";

class AccountsList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openEditAccountModal = this.openEditAccountModal.bind(this);
		this.openDeleteAccountModal = this.openDeleteAccountModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	componentDidMount() {

	}

	closeModal() {
		this.props.hideModal()
	}

	openEditAccountModal(account) {
		this.props.showModal({
		  open: true,
		  title: 'Editar cuenta',
		  style: {width: '400px'},
		  content: <AccountEditModal account={account}  handleClose={this.closeModal}  />,
		  closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteAccountModal(idAccount) {
		this.props.showModal({
		  open: true,
		  title: 'Eliminar cuenta',
		  style: {width: '350px'},
		  message:'¿Desea eliminar la cuenta? Esta acción no se puede deshacer',
		  deleteAction: () => this.confirmDelete(idAccount),
		  closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
		 open: true,
		 title: "Error al eliminar",
		 style: {width: '400px'},
		 message: message,
		 closeModal: this.closeModal
		}, 'alert')
	} 	

	confirmDelete= async(idAccount) =>  {
		const response = await accountsService.remove(idAccount);

		if (!response.ok){
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else{
			this.props.getAccounts();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return(
			<tr>
				<td colSpan="4" className="no-result">
					Sin resultados
				</td>
			</tr>
		);
	} 	

	getRows = () =>  this.props.accounts.map((account)  => {

		return (
			<tr key={ account.id }>
				<td> 
					{account.system
						?  account.name  
						: <button className="btn-transparent" onClick={()=> this.openEditAccountModal(account)}>{ account.name }</button>
					}				
				</td>
				<td>
					{account.type}
				</td>
				<td className="td-actions">
					{account.system 
						?
						  <FiLock className="icon-locked" title="Cuenta de sistema" />
						: (<Dropdown>
								<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item as="button" onClick={()=> this.openEditAccountModal(account)}>Editar</Dropdown.Item>
									<Dropdown.Item as="button" onClick={()=> this.openDeleteAccountModal(account.id)}>Eliminar</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>)
					}					
				</td>
			</tr>
		)
	});  	

	render() {
		return(
			<table className="table table-accounts table-zebra">
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Tipo cuenta</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{this.props.accounts.length === 0 ?
						this.getEmptyRow() 
					:
						this.getRows() 
					}
				</tbody>
			</table>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.rulesReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAccounts: (q) => dispatch(rulesActions.getAccounts(q)),
})


export default connect(mapStateToProps, mapDispatchToProps)(AccountsList)