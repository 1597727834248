import { handleResponse } from "utils/utils";
import { authService } from "services";

export const customFieldsService = {
    getAll,
    getById,
    add,
    update,
    remove,
};

// get all custom fields
function getAll(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=-created';

    if (options) {
        const { q } = options;

        if (q !== undefined)
            query += "&q=" + q;
    }

    return fetch(`${global.BASE_API}custom-fields${query}`, requestOptions).then(handleResponse);
}

// get one custom fields by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}custom-fields/${id}`, requestOptions).then(handleResponse);
}

// add new custom fields
function add(func) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(func)
    };

    return fetch(`${global.BASE_API}custom-fields/`, requestOptions).then(handleResponse);
}

// update custom fields
function update(func) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(func)
    };

    return fetch(`${global.BASE_API}custom-fields/${func.id}`, requestOptions).then(handleResponse);
}

// remove custom fields
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}custom-fields/${id}`, requestOptions).then(handleResponse);
}