const initialState = {
	// absences: [],
	pending_absences: [],
	managed_absences: [],
	loading: false,
	error: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		// case 'GET_ABSENCES':
		// 	return {
		// 		...state,
		// 		absences: action.payload,
		// 		loading: false,
		// 		error: ''
		// 	};
		case "GET_PENDING_ABSENCES":
			return {
				...state,
				pending_absences: action.payload,
				loading: false,
			};
		case "GET_MANAGED_ABSENCES":
			return {
				...state,
				managed_absences: action.payload,
				loading: false,
			};
		case "LOADING":
			return { ...state, loading: true };
		case "ERROR":
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			return state;
	}
};
