import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FiSearch } from "react-icons/fi";
import { FormGroup, FormControl, FormLabel, Row, Col, Nav, Tab, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ContextMenu, MenuItem, ContextMenuTrigger, connectMenu } from "react-contextmenu";
import { shiftPatternsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import ShiftPreviewTooltip from 'components/Shifts/ShiftPreviewTooltip';
import Switch from "components/Switch";
import * as shiftsActions from 'actions/shiftsActions';
import * as ubicationsActions from 'actions/ubicationsActions';
const MENU_ID = 'shift_pattern_menu';


class ShiftPatternEdit extends React.Component {

	constructor(props) {
		super(props);
		console.log(props);

		let shift_pattern = {
			name: '',
			items: [],
			length: 7,
			enabled: true
		}

		const default_shift = this.props.default_shift;

		let isEdit = false;

		if (props.shift_pattern) {
			isEdit = true;
			shift_pattern = props.shift_pattern;
			shift_pattern.length = shift_pattern.items.length;
		}

		// prepare list of options for the lentgh selector
		const length_options = [];
		for (var i = 0; i < 186; i++) {
			length_options.push({
				index: i,
				shift_ubications: [
					{ shift: default_shift, ubications: [] }
				]
			});
		}

		this.state = {
			searchShift: '',
			searchUbication: '',
			filterTypeId: '',
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			shift_list: props.select_shifts || [],
			ubication_list: props.select_ubications || [],
			shift_pattern,
			length_options
		};
	}

	componentDidMount() {
		this.updateItemList();
		this.getShifts();
	}

	async getShifts() {
		await this.props.getAllShifts({ q: this.state.searchShift, shift_type: this.state.filterTypeId });
		const shift_list = this.props.shifts;

		this.setState({
			shift_list
		});
	}

	async getUbications() {
		await this.props.getAllUbications({ q: this.state.searchUbication, enabled: true });
		const ubication_list = this.props.ubications;
		// console.log(ubication_list);

		this.setState({
			ubication_list
		});
	}

	updateItemList = () => {
		const { shift_pattern, length_options } = this.state;
		var items = [];

		// console.log(length_options);
		// console.log(shift_pattern.items.length);
		// console.log(shift_pattern.length);

		// si no había una lista de items previa, se usa la lista vacía
		if (shift_pattern.items.length === 0)
			items = length_options.slice(0, shift_pattern.length);
		// si la lista previa era más larga que la nueva longitud, acortamos
		else if (shift_pattern.items.length >= shift_pattern.length * 1) {
			items = shift_pattern.items.slice(0, shift_pattern.length);
		}
		// si la lista previa era más corta sumamos los elementos restantes de la lista vacía
		else {
			const temp_items_1 = length_options.slice(shift_pattern.items.length, shift_pattern.length);
			const temp_items_2 = shift_pattern.items.slice(0, shift_pattern.length);

			items = temp_items_2.concat(temp_items_1);
		}

		this.setState({
			shift_pattern: {
				...shift_pattern,
				items: items
			},
		});
	}

	handleChange = event => {
		const { shift_pattern } = this.state;
		const key = event.target.id;

		this.setState({
			shift_pattern: {
				...shift_pattern,
				[event.target.id]: event.target.value
			},
		}, () => {
			// when the updated field is length, update the whole grid
			if (key === "length") {
				this.updateItemList();
			}
		});
	}

	handleSwitch = (event) => {
		const { shift_pattern } = this.state;

		this.setState({
			shift_pattern: {
				...shift_pattern,
				[event.target.id]: event.target.checked,
			},
		});
	};

	handleShiftSelection = event => {
		const { shift_list } = this.state;
		const countSelected = shift_list.filter(o => o.checked === true).length;
		var id = event.target.id;
		id = id.replace("shift_", "");
		const value = event.target.checked;

		if (countSelected >= 3 && value) {
			toastr.error('', 'Puedes seleccionar un máximo de 3 turnos por jornada');
		}
		else {
			this.setState({
				shift_list: this.state.shift_list.map(o => (o.id === parseInt(id) ? { ...o, 'checked': value } : o))
			});
		}
	}

	handleUbicationSelection = event => {
		const { ubication_list } = this.state;
		const countSelected = ubication_list.filter(o => o.checked === true).length;
		var id = event.target.id;
		id = id.replace("ubication_", "");
		const value = event.target.checked;

		if (countSelected >= 2 && value) {
			toastr.error('', 'Solo se pueden seleccionar dos ubicaciones por turno');
		}
		else {
			this.setState({
				ubication_list: this.state.ubication_list.map(o => (o.id === parseInt(id) ? { ...o, 'checked': value } : o))
			});
		}
	}

	handleDaySelection = event => {
		const { shift_pattern } = this.state;

		var id = event.target.id;
		id = id.replace("day_", "");
		const value = event.target.checked;

		shift_pattern.items = shift_pattern.items.map(o => (o.index === parseInt(id) ? { ...o, 'checked': value } : o))

		this.setState({
			shift_pattern,
		});
	}

	selectSingleDay = index => {
		const { shift_pattern } = this.state;

		shift_pattern.items = shift_pattern.items.map(o => (o.index === index ? { ...o, 'checked': true } : o))

		this.setState({
			shift_pattern,
		});
	}

	handleChangeSearch = event => {
		const target = event.target.id;
		// console.log(target);

		this.setState({
			[target]: event.target.value
		}, () => {
			if (target === "searchUbication") {
				this.getUbications();
			} else {
				this.getShifts();

			}
		});
	}


	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	prepareShiftPatternSubmit = () => {
		const { shift_pattern } = this.state;

		shift_pattern.items.map(function (sp_item) {
			const shift_ubication_ids = [];

			if (sp_item.shift_ubications) {
				sp_item.shift_ubications.map(shift_ubication => {

					const shift_ubication_item = {
						shift_id: shift_ubication.shift.id,
						ubication_ids: []
					}

					const ubication_ids = [];

					if (shift_ubication.ubications.length > 0) {
						shift_ubication.ubications.map(ubication => {
							ubication_ids.push(ubication.id);
							return true;
						});

						shift_ubication_item.ubication_ids = ubication_ids;
					}

					shift_ubication_ids.push(shift_ubication_item);

					return true;
				});

				// console.log(shift_ubication_ids);
				sp_item.shift_ubication_ids = shift_ubication_ids;
			}
			return true;
		});
		// console.log(shift_pattern);
		return shift_pattern;
	}


	handleSubmit = async event => {
		event.preventDefault();
		const { isEdit } = this.state;

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;
		const shift_pattern = this.prepareShiftPatternSubmit();

		// if edit => update; else => new
		if (isEdit) {
			response = await shiftPatternsService.update(shift_pattern);
		}
		else {
			response = await shiftPatternsService.add(shift_pattern);
		}

		// console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				props.getShiftPatterns();
				props.handleClose();

			});
		}
		else {
			console.log(errorFormating(response));
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	_hasSelection = (list) => {
		const result = list.find(o => o.checked === true);
		return result !== undefined;
	}

	_filterSelected = (list) => {
		return list.filter(o => o.checked === true);
	}

	_uncheckSelectedList = (list) => {
		return list.map(item => {
			item.checked = false;
			return item;
		});
	}

	handleMenuClick = (e, data, target) => {
		// console.log(data);
		if (data.action === 'paste-shifts') {
			this.handlePasteShifts();
		}
		if (data.action === 'remove-shifts') {
			this.handleRemoveShifts();
		}
		if (data.action === 'paste-ubication') {
			this.handlePasteUbication(data);
		}
		if (data.action === 'remove-ubications') {
			this.handleRemoveUbications(data);
		}
	}

	handlePasteShifts = () => {
		const { shift_pattern, shift_list } = this.state;
		const filteredShifts = this._filterSelected(shift_list);

		// for each selected day, save shift list 
		shift_pattern.items.map(item => {
			if (item.checked) {
				const shift_ubications = [];

				filteredShifts.map(shift => {
					shift_ubications.push({ shift, ubications: [] })
					return true;
				});

				item.shift_ubications = shift_ubications;

				// back to false
				item.checked = false;
			}
			return true;
		});

		this.setState({
			shift_pattern,
			shift_list: this._uncheckSelectedList(this.state.shift_list)
		});
	}

	handleRemoveShifts = () => {
		const { shift_pattern } = this.state;
		const default_shift = this.props.default_shift;

		// for each selected day, delete shift list 
		shift_pattern.items.map(item => {
			if (item.checked) {
				// item.shifts = [default_shift];
				item.shift_ubications = [{ shift: default_shift, ubications: [] }];

				// back to false
				item.checked = false;
			}
			return true;
		});

		// console.log(shift_pattern);

		this.setState({
			shift_pattern,
			errors: {},
		});
	}

	handlePasteUbication = (data) => {
		const { shiftId, index } = data;
		const { shift_pattern, ubication_list } = this.state;
		const filteredUbications = this._filterSelected(ubication_list);
		// console.log(filteredUbications);

		// for each selected day and shift, save ubication 
		shift_pattern.items.map(item => {
			if (item.index === index) {
				item.shift_ubications.map(shift_ubication => {
					if (shift_ubication.shift.id === shiftId) {
						shift_ubication.ubications = filteredUbications;
					}
				});
			}
		});

		this.setState({
			shift_pattern,
			ubication_list: this._uncheckSelectedList(this.state.ubication_list)
		});
	}

	handleRemoveUbications = (data) => {
		const { shiftId, ubicationId, index } = data;
		const { shift_pattern } = this.state;

		// remove ubication by item index and shiftId
		shift_pattern.items.map(item => {
			if (item.index === index) {
				item.shift_ubications.map(shift_ubication => {
					if (shift_ubication.shift.id === shiftId) {

						if (ubicationId) {
							shift_ubication.ubications = shift_ubication.ubications.filter((u) => u.id !== ubicationId);
						}
						else {
							shift_ubication.ubications = [];
						}
					}
				});
			}
			return true;
		});

		this.setState({
			shift_pattern,
			errors: {},
		});
	}

	getShiftList = () => this.state.shift_list.map((item) => {
		const checked = item.checked || false;
		const tooltip = <Tooltip className="tooltip-shift"><ShiftPreviewTooltip shift={item} time_intervals={item.time_intervals} /></Tooltip>;

		return (
			<OverlayTrigger key={item.id} overlay={tooltip}>
				<li title={`${item.name} - ${item.description}`} className={`shift-item btn-checkbox ${checked ? 'active' : ''}`} style={{ backgroundColor: item.background_color && item.background_color, borderColor: item.background_color && item.background_color }}>
					<input
						type="checkbox"
						id={`shift_${item.id}`}
						onChange={this.handleShiftSelection}
						checked={checked}
					/>
					<label
						htmlFor={`shift_${item.id}`}
						className="btn-check-label"
					>
						{item.name}
					</label>
				</li>
			</OverlayTrigger>
		)
	});


	getUbicationList = () => this.state.ubication_list.map((item) => {
		// console.log(item);
		const checked = item.checked || false;

		return (
			<li key={item.id} title={`${item.name} - ${item.description}`} className={`shift-item btn-checkbox ${checked ? 'active' : ''}`} style={{ backgroundColor: item.background_color && item.background_color, borderColor: item.background_color && item.background_color }}>
				<input
					type="checkbox"
					id={`ubication_${item.id}`}
					onChange={this.handleUbicationSelection}
					checked={checked}
				/>
				<label
					htmlFor={`ubication_${item.id}`}
					className="btn-check-label"
				>
					{item.name}
				</label>
			</li>
		)
	});

	getAssignedShifts = (sp_item) => sp_item.shift_ubications.map((shift_ubication) => {
		const shift = shift_ubication.shift;
		return (
			<li key={shift.id} title={`${shift.name} - ${shift.description}`} className="shift-item" style={{ backgroundColor: shift.background_color && shift.background_color }}>
				{shift.name}
			</li>
		)
	});

	getAssignedShiftsWithUbications = (sp_item) => sp_item.shift_ubications.map((shift_ubication) => {
		const shift = shift_ubication.shift;
		const ubications = shift_ubication.ubications;
		return (
			<li
				key={shift.id}
				title={`${shift.name} - ${shift.description}`}
				className="shift-item"
				style={{ borderColor: shift.background_color && shift.background_color }}
			>
				<ContextMenuTrigger
					id={MENU_ID}
					holdToDisplay={1000}
					collect={collect}
					onItemClick={this.handleMenuClick}
					index={sp_item.index}
					shiftId={shift.id}
					cellType="ubication"
				>
					<span
						className="shift-name"
					// style={{ color: shift.background_color && shift.background_color !== "#ffffff" && shift.background_color }}
					>
						{shift.name}
					</span>
					{ubications.length > 0 ? (
						<ul className="shift-list-items-ubications">
							{this.getAssignedUbications(sp_item, shift_ubication, ubications)}
						</ul>) : "-"}

				</ContextMenuTrigger>
			</li>
		)
	});

	getAssignedUbications = (sp_item, shift_ubication, ubication_list) => ubication_list.map((ubication, i) => {
		const shift = shift_ubication.shift;
		return (
			<li
				key={i}
				title={`${ubication.name} - ${ubication.description}`}
				className="ubication-item"
				style={{
					backgroundColor: ubication.background_color && ubication.background_color,
					borderColor: ubication.border_color && ubication.border_color,
				}}
				onClick={() => props.handleEditShift(plan)}
			>
				<ContextMenuTrigger
					id={MENU_ID}
					holdToDisplay={1000}
					collect={collect}
					onItemClick={this.handleMenuClick}
					index={sp_item.index}
					shiftId={shift.id}
					ubicationId={ubication.id}
					cellType="ubication"
				>
					{ubication.name}
				</ContextMenuTrigger>
			</li>
		);
	});


	getShiftCells = () => this.state.shift_pattern.items.map((item, i) => {
		const checked = item.checked || false;
		const { errors } = this.state;
		const hasError = (errors['items[' + i + '].shift_ids'] !== undefined) || false;

		// console.log(item);

		return (
			<article key={item.index} className={`pattern-cell btn-checkbox ${checked ? 'active' : ''} ${hasError ? 'error' : ''}`}>
				<ContextMenuTrigger
					id={MENU_ID}
					holdToDisplay={1000}
					collect={collect}
					onItemClick={this.handleMenuClick}
					index={item.index}
					cellType="shift"
				>
					<input
						type="checkbox"
						id={`day_${item.index}`}
						onChange={this.handleDaySelection}
						checked={checked}
					/>
					<label
						htmlFor={`day_${item.index}`}
						className="btn-check-label"
						onContextMenu={() => this.selectSingleDay(item.index)}
						draggable
						onDragEnter={() => this.selectSingleDay(item.index)}
					></label>

					<span className="cell-number">{item.index + 1}</span>

					{item.shift_ubications &&
						(<ul className="shift-list-items">
							{this.getAssignedShifts(item)}
						</ul>)
					}
				</ContextMenuTrigger>
			</article>
		)
	});

	getUbicationCells = () => this.state.shift_pattern.items.map((item, i) => {
		const { errors } = this.state;
		// TODO provar:
		const hasError = (errors['items[' + i + '].ubication_id'] !== undefined) || false;

		return (
			<article key={item.index} className={`pattern-cell ubication-cell ${hasError ? 'error' : ''}`}>
				<span className="cell-number">{item.index + 1}</span>

				{item.shift_ubications &&
					(<ul className="shifts-with-ubications">
						{this.getAssignedShiftsWithUbications(item)}
					</ul>)
				}
			</article>
		)
	});

	// TODO provar (com?)
	getItemError = () => {
		const { shift_pattern, errors } = this.state;
		var errorMsg = "";

		shift_pattern.items.map((item, i) => {
			if (errors['items[' + i + '].shift_ids'] !== undefined) {
				errorMsg = errors['items[' + i + '].shift_ids'];
			}
			else if (errors['items[' + i + '].ubication_id'] !== undefined) {
				errorMsg = errors['items[' + i + '].ubication_id'];
			}
			return true;
		});

		if (errorMsg !== "") {
			return (
				<div className="alert alert-danger alert-dismissible fade show mt15" role="alert">
					{errorMsg}
				</div>
			)
		}
	}

	getShiftContent = () => {
		const { shift_types } = this.props;

		const optionShiftTypeList = shift_types.length > 0 && shift_types.map((item, i) => {
			return (
				<option key={i} value={item.name}>{item.name}</option>
			)
		}, this);

		return (
			<>
				<p className="text-info">Selecciona un máximo de 3 turnos y asígnalos a uno o varios días con botón derecho =&gt; Pegar turnos</p>

				<div className="pattern-container">
					<div className="pattern-filter">

						<div className="heading-filters">
							<FormGroup className="form-search" controlId="searchShift">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.searchShift}
									onChange={this.handleChangeSearch}
								/>
								<FiSearch />
							</FormGroup>
							<FormGroup className="form-search" controlId="filterTypeId">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.filterTypeId}
									onChange={this.handleChangeSearch}
									as="select">
									<option value="">Filtrar por tipo</option>
									{optionShiftTypeList}
								</FormControl>
							</FormGroup>
						</div>

						<ul className="pattern-list">
							{this.getShiftList()}
						</ul>

					</div>
					<div className="pattern-plan shift-plan">
						{this.getShiftCells()}
					</div>
				</div>
			</>
		)
	}

	getUbicationContent = () => {
		return (
			<>
				<p className="text-info">Selecciona una ubicación y asígnala a uno o varios días con botón derecho =&gt; Pegar ubicaciones</p>

				<div className="pattern-container">
					<div className="pattern-filter">

						<div className="heading-filters">
							<FormGroup className="form-search" controlId="searchUbication">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.searchUbication}
									onChange={this.handleChangeSearch}
								/>
								<FiSearch />
							</FormGroup>
						</div>

						<ul className="pattern-list">
							{this.getUbicationList()}
						</ul>

					</div>
					<div className="pattern-plan ubication-plan">
						{this.getUbicationCells()}
					</div>
				</div>
			</>
		)
	}

	getTabsContent = () => {
		return (<>
			<Tab.Container defaultActiveKey="turnos">
				<Nav variant="tabs">
					<Nav.Item>
						<Nav.Link eventKey="turnos">Turnos</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="ubicaciones">Ubicaciones</Nav.Link>
					</Nav.Item>
				</Nav>
				<Tab.Content>
					<Tab.Pane eventKey="turnos">
						{this.getShiftContent()}
					</Tab.Pane>
					<Tab.Pane eventKey="ubicaciones">
						{this.getUbicationContent()}
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container></>)
	}

	render() {
		const { shift_pattern, length_options, errors } = this.state;

		const optionShiftPatternLength = length_options.length > 0 && length_options.map((item) => {
			return (
				<option key={item.index} value={item.index + 1}>{item.index + 1} días</option>
			)
		}, this);

		const isShiftSelected = this._hasSelection(this.state.shift_list) && this._hasSelection(this.state.shift_pattern.items);
		// const isUbicationSelected = this._hasSelection(this.state.ubication_list) && this._hasSelection(this.state.shift_pattern.items);
		const isUbicationSelected = this._hasSelection(this.state.ubication_list);
		const hasUbications = shift_pattern.has_ubications;
		const isEnabled = shift_pattern.enabled;

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body shift-pattern-edit">
					<Row>
						<Col sm={6} md={4}>
							<FormGroup controlId="name">
								<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
								<FormControl
									type="text"
									value={shift_pattern.name}
									onChange={this.handleChange}
									placeholder="Nombre"
									isInvalid={this.checkValidField('name')}
								/>

								<FormControl.Feedback type="invalid">
									{errors.name}
								</FormControl.Feedback>
							</FormGroup>
						</Col>

						<Col sm={6} md={6}>
							<FormGroup controlId="description">
								<FormLabel>Descripción</FormLabel>
								<FormControl
									value={shift_pattern.description}
									onChange={this.handleChange}
									placeholder="Descripción de la rueda de turno"
									isInvalid={this.checkValidField('description')}
								/>

								<FormControl.Feedback type="invalid">
									{errors.description}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
						<Col sm={6} md={2}>
							<FormGroup controlId="length">
								<FormLabel>Longitud</FormLabel>
								<FormControl
									value={shift_pattern.length}
									onChange={this.handleChange}
									placeholder="Longitud"
									as="select">
									{optionShiftPatternLength}
								</FormControl>
							</FormGroup>
						</Col>

						<Col sm={6} md={4}>

							<FormGroup
								className="form-group-flex form-enabled"
								controlId="enabled"
							>
								<FormLabel>Activo</FormLabel>

								<Switch
									controlId="enabled"
									isOn={isEnabled}
									handleToggle={this.handleSwitch}
								/>

							</FormGroup>
						</Col>

						<Col sm={6} md={6}>
							<FormGroup
								className="form-group-flex form-ubicaciones"
								controlId="has_ubications"
							>
								<FormLabel>Añadir ubicaciones a la rueda de turnos</FormLabel>

								<Switch
									controlId="has_ubications"
									isOn={hasUbications}
									handleToggle={this.handleSwitch}
								/>

							</FormGroup>
						</Col>
					</Row>

					{hasUbications ? this.getTabsContent() : this.getShiftContent()}

					<div className="form-error">
						{this.getItemError()}
					</div>

					{this.state.errors && this.state.errors.general && (
						<div
							className="alert alert-danger alert-general-error fade show"
							role="alert"
						>
							{this.state.errors.general}
						</div>
					)}
				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>

				<CustomContextMenu
					isShiftSelected={isShiftSelected}
					isUbicationSelected={isUbicationSelected}
				/>

			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.calendarsReducer,
		...reducers.timesReducer,
		...reducers.shiftsReducer,
		...reducers.ubicationsReducer,
		loading: reducers.shiftsReducer.loading
	}
};

const mapDispatchToProps = (dispatch) => ({
	getAllShifts: (options) => dispatch(shiftsActions.getAllShifts(options)),
	getAllUbications: (options) => dispatch(ubicationsActions.getAllUbications(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftPatternEdit)

function collect(props) {
	return props;
}

// Context menu:
const ShiftPatternContextMenu = (props) => {
	// console.log(props);
	const { trigger, isUbicationSelected, isShiftSelected } = props;
	const handleItemClick = trigger ? trigger.onItemClick : null;
	const cellType = trigger ? trigger.cellType : "shift";

	// console.log(isUbicationSelected);
	// console.log(isShiftSelected);

	if (cellType === "ubication") {
		return (
			<ContextMenu id={MENU_ID}>
				{trigger && isUbicationSelected && <MenuItem onClick={handleItemClick} data={{ action: 'paste-ubication' }}>Pegar ubicaciones</MenuItem>}
				{trigger && !isUbicationSelected && <MenuItem onClick={handleItemClick} data={{ action: 'remove-ubications' }}>Borrar ubicaciones</MenuItem>}
			</ContextMenu>
		)
	}

	return (
		<ContextMenu id={MENU_ID}>
			{trigger && isShiftSelected && <MenuItem onClick={handleItemClick} data={{ action: 'paste-shifts' }}>Pegar turnos</MenuItem>}
			{trigger && !isShiftSelected && <MenuItem onClick={handleItemClick} data={{ action: 'remove-shifts' }}>Borrar Turnos</MenuItem>}
		</ContextMenu>
	)
};


const CustomContextMenu = connectMenu(MENU_ID)(ShiftPatternContextMenu);