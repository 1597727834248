import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import * as timesActions from 'actions/timesActions';
import { timesService } from "services";
import { getWorkedTimeCounter, getTimeCounterByType, formatMinutesToTime } from "utils/datetime";
import { getEmployeeTimeTypes } from "components/TimeTypes/utils";

import { errorFormating } from "utils/utils";
import Counter from "components/Counter";
import CounterStatic from "components/CounterStatic";

class Dashboard_Clockin extends Component {

	constructor(props) {
		super(props);
		// console.log(props);
		this.state = {
			isRecording: false,
			today_times: [],
			ongoing_time: {},
			clockin_error: '',
			comments: '',
			total_counter: 0,
		};
	}

	async componentDidMount() {
		this.props.getSelectableTimeTypes();
		this.getTodayTimes();
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	async getTodayTimes() {
		const userId = this.props.user.id;
		const ongoing_time = await timesService.getToday(userId);
		// console.log(ongoing_time);

		// check if last time is "work" time && not ended
		// if (ongoing_time.ok && ongoing_time.data.length > 0 && ongoing_time.data[0].type !== undefined && (ongoing_time.data[0].type.name === "Trabajo" || ongoing_time.data[0].type.name === "Work") && ongoing_time.data[0].end === undefined) {
		if (ongoing_time.ok && ongoing_time.data.length > 0 && ongoing_time.data[0].end === undefined) {
			this.setState({
				ongoing_time: ongoing_time.data[0],
				today_times: ongoing_time.data,
				isRecording: true,
				type_id: ongoing_time.data[0].type.id,
			});
		}
		else if (ongoing_time.ok && ongoing_time.data.length > 0) {
			this.setState({
				total_counter: getWorkedTimeCounter(ongoing_time.data), // get today's working time
				today_times: ongoing_time.data,
				type_id: this.props.default_time_type.id,
			});
		}
		else {
			this.setState({
				type_id: this.props.default_time_type.id,
			});
		}
	}

	async handleClockIn() {
		const { ongoing_time, type_id, comments } = this.state;
		const userId = this.props.user.id;

		// console.log(type_id);

		const time = {
			approved: false,
			employee_id: userId,
			type_id,
		};

		// update time (fichaje salida)
		if (ongoing_time.id !== undefined) {
			time.id = ongoing_time.id;
			time.start = moment.utc(ongoing_time.start).format();
			time.end = moment().utc().format();
			time.type_id = ongoing_time.type.id;
			time.observations = ongoing_time.observations;

			const response = await timesService.update(time.id, time);

			this.setState({
				ongoing_time: {},
				isRecording: false,
				clockin_error: errorFormating(response)
			});
		}
		// new time (fichaje entrada)
		else {
			time.start = moment().utc().format();
			time.observations = comments;
			const response = await timesService.add(time);

			if (response.ok) {
				this.setState({
					ongoing_time: response,
					isRecording: true
				});
			}
			else {
				this.setState({
					clockin_error: errorFormating(response)
				});
			}
		}
	}

	getClockIn = () => {
		let time = this.state.total_counter;
		let hours = 0;
		let minutes = 0;
		if (time !== 0 && time.lastIndexOf(":") > 0) {
			time = time.split(":");
			hours = time[0];
			minutes = time[1];
		}

		const { select_time_types, default_time_type, user } = this.props;
		const { comments } = this.state;

		const time_types_list = user.employee_with_time_types && user.employee_with_time_types.length > 0 ? getEmployeeTimeTypes(user.employee_with_time_types) : select_time_types;

		let typesList =
			time_types_list.length > 0 &&
			time_types_list.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);


		let hasOthers = false;

		// mostra altres tipus de temps si n'hi ha i si no són el default de treball
		let typesCounterList =
			time_types_list.length > 0 && time_types_list.map((time_type, i) => {

				if (default_time_type.id !== time_type.id) {
					let count = getTimeCounterByType(this.state.today_times, time_type);
					const formatedTime = formatMinutesToTime(count);
					if (count > 0) {
						hasOthers = true;

						return (
							<li key={i} value={time_type.id}>
								{time_type.name} <strong>{formatedTime}</strong>
							</li>
						);
					}
				}
			}, this);

		return (
			<article className="card clockin">
				<div className="card-top">
					<h2>Fichaje</h2>
				</div>
				<div className="card-content">
					<CounterStatic hours={hours} minutes={minutes} />
					<span className="label-day">Jornada de trabajo hoy</span>
				</div>

				{(this.state.today_times.length > 0 && hasOthers) &&
					<>
						<h3 className="resumen-title">Otros</h3>
						<ul className="resumen-list">
							{typesCounterList}
						</ul>
					</>
				}

				{this.state.clockin_error.start !== undefined &&
					<div className="alert alert-danger alert-dismissible fade show" role="alert">
						{this.state.clockin_error.start}
					</div>
				}

				<FormGroup className="mb10" controlId="comments">
					{/* <FormLabel>Comentarios</FormLabel> */}
					<FormControl
						as="textarea"
						value={comments}
						className="comments"
						onChange={this.handleChange}
						placeholder="Comentarios se guardarán cuando hagas clic en fichar entrada"
					/>
				</FormGroup>

				<div className="card-footer">
					<button type="button" onClick={() => this.handleClockIn()} className="btn btn-primary btn-inline">Fichar entrada</button>

					<FormControl
						id="type_id"
						className="form-lg select-type"
						as="select"
						value={this.state.type_id}
						onChange={this.handleChange}
					>
						<option value="-1">Seleccionar tipo</option>
						{typesList}
					</FormControl>
				</div>

			</article>
		);
	}

	getOnGoingClockIn = () => {
		const { ongoing_time } = this.state;
		const hora = moment.utc(ongoing_time.start).local().format("HH:mm");

		return (
			<article className="card clockin">
				<div className="card-top">
					<h2>Fichaje en curso</h2>
				</div>
				<div className="card-content">
					<Counter start={ongoing_time.start} bStatic={false} />
					<span className="label-day">En curso ({ongoing_time.type.name})</span>
					<span className="label-start">Hora entrada <strong>{hora}</strong></span>

				</div>

				{this.state.clockin_error.start !== undefined &&
					<div className="alert alert-danger alert-dismissible fade show" role="alert">
						{this.state.clockin_error.start}
					</div>
				}
				<button
					type="button"
					onClick={() => this.handleClockIn()}
					className="btn btn-outline-primary btn-inline">
					Detener
				</button>
			</article>
		);
	}

	render() {
		return (
			this.state.ongoing_time.id !== undefined
				? this.getOnGoingClockIn()
				: this.getClockIn()
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.timesReducer,
		user: reducers.authReducer.user,
	}
};

const mapDispatchToProps = dispatch => ({
	getDefaultTimeType: () => dispatch(timesActions.getDefaultTimeType()),
	getSelectableTimeTypes: () => dispatch(timesActions.getSelectableTimeTypes()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard_Clockin);