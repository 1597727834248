import React from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import Avatar from 'components/Avatar';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from "react-bootstrap";
import EmployeeListItemHeader from "components/Employees/EmployeeListItemHeader";
import AbsenceListItemPeriod from "components/Absences/AbsenceListItemPeriod";
import * as absencesActions from "actions/absencesActions";

class MyAbsences extends React.Component {
	getEmptyRow = () => {
		return <p className="no-results">Sin resultados.</p>;
	};

	getRows = () =>
		this.props.employee_absences.map((absence) => (
			<div className="col-sm-6 col-lg-4 col-xl-3" key={absence.id}>
				<article className="card card-ausencia" key={absence.id}>
					<EmployeeListItemHeader employee={absence.requester} />

					<div className="card-content">
						{/* <span className="absence-comments">{absence.comments}</span> */}

						<span
							className="card-shift"
							style={{ backgroundColor: absence.shift.background_color && absence.shift.background_color, borderColor: absence.shift.border_color && absence.shift.border_color }}>
							{absence.shift.name}
						</span>

						<AbsenceListItemPeriod absence={absence} />

						<p className="card-label card-label-solicitado">
							Solicitado el{" "}
							<Moment className="tag-dia" format="D/MM/YYYY">
								{absence.requested}
							</Moment>
						</p>

						<div className="form-group no-form">
							<span className={`estado ${absence.status}`}>
								{absence.status}
							</span>
						</div>

						{absence.answerer &&
							<div className="form-group no-form card-label-gestionado">
								<Avatar userId={absence.answerer.id} className="avatar-xxs" alt={absence.answerer.name} /> {absence.answerer.name} {absence.answerer.surname}
							</div>
						}


						{absence.answer_comments && (<span className="absence-answer-comments">{absence.answer_comments}</span>)}


						<div className="card-actions bottom-right">
							<Dropdown>
								<Dropdown.Toggle variant="action">
									<FiMoreHorizontal />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									{absence.status === "Pendiente" ||
										absence.status === "Pending" ? (
										<>
											<Dropdown.Item
												as="button"
												onClick={() => this.props.openEditAbsenceModal(absence)}
											>
												Editar
											</Dropdown.Item>
											<Dropdown.Item
												as="button"
												onClick={() => this.props.handleCancelRequest(absence)}
											>
												Cancelar solicitud
											</Dropdown.Item>
										</>
									) : (
										<Dropdown.Item
											as="button"
											onClick={() =>
												this.props.openEditAbsenceModal(absence, true)
											}
										>
											Ver
										</Dropdown.Item>
									)}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</article>
			</div>
		));

	render() {
		return (
			<>
				{this.props.employee_absences.length === 0 ? (
					this.getEmptyRow()
				) : (
					<div className="row list-absences">{this.getRows()}</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.shiftsReducer,
		...reducers.absencesReducer,
		...reducers.employeesReducer,
		user: reducers.authReducer.user,
	};
};

export default connect(mapStateToProps, absencesActions)(MyAbsences);
