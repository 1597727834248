import React from "react";
import { default as Birthdays } from "components/Dashboard/Dashboard_Birthdays"
import { WIDGET_NAMES } from "components/WidgetTemplates/utils";

const Card_Birthdays = (props) => {
	// console.log(props);

	return (
		<article className="card card-birthday">
			<div className="card-top">
				<h2>{WIDGET_NAMES[props.name]}</h2>
			</div>
			<div className="card-content">
				<Birthdays />
			</div>
		</article>
	);
};

export default Card_Birthdays;