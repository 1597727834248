import React from 'react';
import {toastr} from 'react-redux-toastr';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { FiTrash2 } from "react-icons/fi";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as employeesActions from 'actions/employeesActions';

class EmployeeDocumentsAdd extends React.Component {
	constructor(props) {
		super(props);
		//console.log(props);
		this.state = {
			isLoading: false,
			submitted: false,
			errors:{},
			files:[]
		};
	}

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = '';

		const props = this.props;
		const {files} = this.state;

		if (files.length > 0){
			var formData = new FormData();

			files.map(file => (
				formData.append('filename', file)
			));	

			response = await employeesService.addAttachments(props.employee.id, formData);
		}
		else{
			props.handleClose();
		}


		if (response.ok){
			this.setState({ 
			isLoading: false,
			errors: {},
			}, ()=>{

			toastr.success('¡Bien!', 'Cambios guardados correctamente');

			this.props.getEmployeeDocuments(props.employee.id);
			props.handleClose();

			});
		}
		else{
			this.setState({ 
			isLoading: false,
			submitted: true,
			errors: errorFormating(response)
			});
		}
	}  

	onDrop = (files) => {
		this.setState({files})
	}	

	removeFile = (file) => {
		const { files } = this.state;

		this.setState({
			files : files.filter(f => f.lastModified !== file.lastModified)  // remove the file from the array
		})
	}	

	getDropzone = () => {
		// temporary dropzone files
		const files = this.state.files.length > 0 && this.state.files.map((file, i) => {
			var size = file.size/1000000;
			var sizeLetters = "Mb";

			if (size < 1){
				size = file.size/1000;
				sizeLetters = "Kb";
			}	

			return (
			<li className="file-item-temp" key={i}>
				<span className="file-info" title={file.name}>
					<span className="file-name">{file.name}</span>
					<span className="file-size">({size.toFixed(2)}{sizeLetters})</span>
				</span>
				<div className="file-action">	
					<button type="button" className="btn btn-icon" title="Eliminar" onClick={()=> this.removeFile(file)}><FiTrash2/></button>
				</div>
			</li>
		)});	

		return(
			<Dropzone onDrop={this.onDrop}>
				{({getRootProps, getInputProps}) => (
					<section className="dropzone-container">
						<div {...getRootProps({className: 'dropzone'})}>
							<input {...getInputProps()} />
							<p>Arrastra tus documentos o haz clic para seleccionar</p>
						</div>
						<ul className="dropzone-files">
							{files}
						</ul>
					</section>
				)}
			</Dropzone>
		);
	}

	render() {
		return(
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">
					{this.getDropzone()}
				</div>     

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
						/>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (state, myProps) => {
  return {
    ...state.employeesReducer,
    documents: state.rulesReducer.documents,
    employee: myProps.employee,
  }
};

export default connect(mapStateToProps, employeesActions)(EmployeeDocumentsAdd);