import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { toastr } from "react-redux-toastr";
import { Row, Col } from "react-bootstrap";
import CustomField from 'components/CustomFields/CustomField';
import { showModal, hideModal } from "actions/modalActions";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";

class EmployeeCustomFields extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);
		// console.log(this.props.employee);

		const employee = this.props.employee;
		this.state = {
			employee,
			hasChanged: false,
			isMe: this.props.employee.id === this.props.user.id,
			errors: {},
			error_footer: "",
			custom_fields_list: this.getCustomFieldsList() || []
		};

		this.closeModal = this.closeModal.bind(this);
	}

	closeModal() {
		this.props.hideModal();
	}

	getCustomFieldsList = () => {
		const { custom_fields } = this.props;
		const custom_fields_list = [];
		const existing_custom_fields = this.props.employee.custom_fields_response || [];

		custom_fields && custom_fields.map((custom_field) => {
			const cfield_employee = {
				value: "",
				custom_field,
			}

			if (existing_custom_fields.length > 0) {
				const existing_field = existing_custom_fields.find(cf => cf.custom_field.id === custom_field.id);

				if (existing_field) {
					cfield_employee.value = existing_field.value;
				}
			}

			custom_fields_list.push(cfield_employee);
			return true;
		});

		return custom_fields_list;
	}

	handleChange = (event) => {
		const { employee } = this.state;

		const key = event.target.id;
		const value = event.target.value;

		this.setState({
			prevEmployee: employee,
			employee: {
				...employee,
				[key]: value,
			},
		});
	};

	handleChangeCustomField = (event, cfield_employee) => {
		const { custom_fields_list } = this.state;

		const value = event.target.value;
		var hasChanged = false;

		custom_fields_list.map((cf) => {
			if (cf.custom_field.id === cfield_employee.custom_field.id) {
				cf.value = value;
				hasChanged = true;
			}
			return true;
		});

		this.setState({
			hasChanged,
			custom_fields_list
		});
	};

	handleBlur = async () => {
		if (this.state.hasChanged) {
			this.saveEmployee();
		}
	};

	prepareCustomFieldsForSubmit = () => {
		const { custom_fields_list } = this.state;
		const custom_fields_submit_list = [];
		custom_fields_list.map((cf) => {
			if (cf.value) {
				const new_cf = {
					custom_field_id: cf.custom_field.id,
					value: cf.value,
				}

				custom_fields_submit_list.push(new_cf);
			}
			return true
		});
		return custom_fields_submit_list;
	};


	saveEmployee = async () => {
		const { employee } = this.state;

		// preparar custom field ids
		employee.custom_fields = this.prepareCustomFieldsForSubmit();

		const response = await employeesService.update(employee);

		if (response.ok) {
			toastr.success("¡Bien!", "Cambios realizados correctamente");
		}

		this.setState({
			hasChanged: false,
			errors: errorFormating(response),
		});
	};

	checkValidField = (name) => {
		return (
			this.state.errors[name] !== undefined && this.state.errors[name] !== ""
		);
	};

	getContent = () => this.state.custom_fields_list.map((employee_custom_field, i) => {
		return (
			<Col key={i} sm={6} md={4} lg={3}>
				<CustomField
					employee_custom_field={employee_custom_field}
					handleBlur={this.handleBlur}
					handleChangeCustomField={this.handleChangeCustomField}
					checkValidField={this.checkValidField}
					errors={this.state.errors}
				/>
			</Col>
		);
	});

	render() {
		const { custom_fields_list } = this.state;

		return (
			<>
				{custom_fields_list.length === 0 ? "Todavía no hay campos" :

					(<Row>
						{this.getContent()}
					</Row>)}


				{this.state.error_footer && (
					<div
						className="alert alert-danger alert-dismissible fade show mt15"
						role="alert"
					>
						{this.state.error_footer}
					</div>
				)}

				{this.state.errors && this.state.errors.general && (
					<div
						className="alert alert-danger alert-dismissible fade show mt15"
						role="alert"
					>
						{this.state.errors.general}
					</div>
				)}
			</>);
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.customFieldsReducer,
		user: reducers.authReducer.user,
	}
};


const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
});

// export default connect(mapStateToProps, mapDispatchToProps)(EmployeeInfo);
export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(EmployeeCustomFields);
