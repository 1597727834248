import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
// import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
// import * as employeesActions from "actions/employeesActions";

class DocumentsAdd extends React.Component {
	constructor(props) {
		super(props);
		//console.log(props);
		this.state = {
			isLoading: false,
			submitted: false,
			category: this.props.category ?? -1,
			errors: {},
			files: [],
		};
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });
		// let response = '';

		const props = this.props;
		const { files } = this.state;

		if (files.length > 0) {
			var formData = new FormData();

			files.map((file) => formData.append("filename", file));

			// TODO:
			// response = await employeesService.addAttachments(props.employee.id, formData);
		} else {
			props.handleClose();
		}

		if (true === true) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", "Cambios guardados correctamente");

					// this.props.getEmployeeDocuments(props.employee.id);
					props.handleClose();
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	onDrop = (files) => {
		this.setState({ files });
	};

	removeFile = (file) => {
		const { files } = this.state;

		this.setState({
			files: files.filter((f) => f.lastModified !== file.lastModified), // remove the file from the array
		});
	};

	handleChange = (event) => {
		this.setState({
			category: event.target.value,
		});
	};

	checkValidField = (name) => {
		// return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
		return this.state.submitted && this.state.category == -1;
	};

	getDropzone = () => {
		// temporary dropzone files
		const files =
			this.state.files.length > 0 &&
			this.state.files.map((file, i) => {
				var size = file.size / 1000000;
				var sizeLetters = "Mb";

				if (size < 1) {
					size = file.size / 1000;
					sizeLetters = "Kb";
				}

				return (
					<li className="file-item-temp" key={i}>
						<span className="file-info" title={file.name}>
							<span className="file-name">{file.name}</span>
							<span className="file-size">
								({size.toFixed(2)}
								{sizeLetters})
							</span>
						</span>
						<div className="file-action">
							<button
								type="button"
								className="btn btn-icon"
								title="Eliminar"
								onClick={() => this.removeFile(file)}
							>
								<FiTrash2 />
							</button>
						</div>
					</li>
				);
			});

		return (
			<Dropzone onDrop={this.onDrop}>
				{({ getRootProps, getInputProps }) => (
					<section className="dropzone-container">
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<p>Arrastra tus documentos o haz clic para seleccionar</p>
						</div>
						<ul className="dropzone-files">{files}</ul>
					</section>
				)}
			</Dropzone>
		);
	};

	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body modal-documentos">
					{this.props.selectedTab === "bulk" ? (
						<p className="intro-text">
							Arrastra o selecciona todos los archivos que quieras enviar
						</p>
					) : (
						<p className="intro-text">
							Arrastra o selecciona los archivos que quieres subir a plain
						</p>
					)}

					<FormGroup controlId="category">
						<FormLabel>Tipo</FormLabel>
						<FormControl
							onChange={this.handleChange}
							onBlur={this.handleBlur}
							required
							value={this.state.category}
							isInvalid={this.checkValidField("category")}
							as="select"
						>
							<option value="-1">Selecciona un tipo</option>
							<option value="paysheets">Nóminas</option>
							<option value="contracts">Contratos</option>
							<option value="personal">Documentos personales</option>
							<option value="absences">Ausencias</option>
						</FormControl>

						<FormControl.Feedback type="invalid">
							Selecciona un tipo de documento
						</FormControl.Feedback>
					</FormGroup>

					<label className="form-label">Documentos</label>
					{this.getDropzone()}
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state, myProps) => {
	return {
		// ...state.employeesReducer,
		documents: state.rulesReducer.documents,
		// employee: myProps.employee,
	};
};

// export default connect(mapStateToProps, employeesActions)(DocumentsAdd);
export default connect(mapStateToProps, null)(DocumentsAdd);
