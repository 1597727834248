import { handleResponse, handleBlobResponse } from "utils/utils";
import { authService } from "services";

export const functionsService = {
    getAll,
    getSelectable,
    getById,
    add,
    update,
    remove,
    downloadImportTemplate,
    prepareImport,
    runImport,
};

// get all functions
function getAll(q, enabled) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name';

    if (q !== undefined)
        query += "&q=" + q;

    if (enabled !== undefined)
        query += "&enabled=" + enabled;

    return fetch(`${global.BASE_API}functions${query}`, requestOptions).then(handleResponse);
}

// gets selectable functions
function getSelectable() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name&variant=select&enabled=true';

    return fetch(`${global.BASE_API}functions${query}`, requestOptions).then(handleResponse);
}

// get one function by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}functions/${id}`, requestOptions).then(handleResponse);
}

// add new function
function add(func) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(func)
    };

    return fetch(`${global.BASE_API}functions/`, requestOptions).then(handleResponse);
}

// update function
function update(func) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(func)
    };

    return fetch(`${global.BASE_API}functions/${func.id}`, requestOptions).then(handleResponse);
}

// remove function
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}functions/${id}`, requestOptions).then(handleResponse);
}


/**********************************************************************
 ****** IMPORT FUNCTIONS
 ***********************************************************************/

function downloadImportTemplate() {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
            "Content-Type": "application/json",
        },
    };

    return fetch(
        `${global.BASE_API}functions/imports/template`,
        requestOptions
    ).then(handleBlobResponse);
}

function prepareImport(formData) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
        body: formData,
    };

    return fetch(`${global.BASE_API}functions/imports`, requestOptions).then(
        handleResponse
    );
}

function runImport(token) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
    };

    return fetch(
        `${global.BASE_API}functions/imports/${token}`,
        requestOptions
    ).then(handleResponse);
}