import React from 'react';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { FiMenu } from "react-icons/fi";

const DragHandle = sortableHandle(() => <span className="column-move"><FiMenu className="column-move-icon" /></span>);

const ColumnItem = SortableElement(({ item, index, handleColumn }) => {
	return (
		<li key={index} aria-label={item.name} title={item.name} className="column-item column-employees-order">
			<div className="column-left">
				<DragHandle />
			</div>
			<div className="column-right">
				{item.name} {item.surname}
			</div>
		</li>
	)
});

const ColumnList = SortableContainer(({ items, handleColumn }) => {
	return (
		<ul className="list-columns">
			{items.map((item, index) => (
				<ColumnItem key={`item-${index}`} handleColumn={handleColumn} sortIndex={index} index={index} item={item} />
			))}
		</ul>
	);
});

export default ColumnList;