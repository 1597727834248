import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as employeesActions from 'actions/employeesActions';

class EmployeeSeparatedRestEdit extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);

    // NOTA: En este caso no hay edit

    this.state = {
      isLoading: false,
      submitted: false,
      errors: {},
    };
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const props = this.props;

    const response = await employeesService.addSeparatedRestsEmployee(props.employee.id, this.state.employee_id);

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        props.getSeparatedRestEmployees();
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  render() {
    const { select_employees } = this.props;
    const { employee_id } = this.state;


    const optionList = select_employees.length > 0 && select_employees.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name} {item.surname}</option>
      )
    }, this);

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <FormGroup controlId="employee_id">
            <FormLabel>Empleado</FormLabel>
            <FormControl
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={employee_id}
              isInvalid={this.checkValidField('employee_id')}
              as="select">
              <option value="-1">Seleccionar empleado</option>
              {optionList}
            </FormControl>

            <FormControl.Feedback type="invalid">
              {this.state.errors.employee_id}
            </FormControl.Feedback>
          </FormGroup>
        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, myProps) => {
  return {
    ...state.employeesReducer,
    employee: myProps.employee,
  }
};

export default connect(mapStateToProps, employeesActions)(EmployeeSeparatedRestEdit);