import React from 'react'
import SubmitButton from "components/SubmitButton"

const ConfirmModal = ({ closeModal, confirmAction, title, message, style, confirmText, cancelText, isLoading, loadingText }) => {
  return (
    <div className="modal-content" style={style}>
      <div className="modal-header">
        <h5
          className="modal-title"
        >{title}</h5>
        <button type="button" className="close" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{message}</p>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={closeModal} className="btn btn-outline-primary">{cancelText || 'Cancelar'}</button>
        <SubmitButton type="button" onClick={confirmAction} isLoading={isLoading} text={confirmText || 'Confirmar'} loadingText={loadingText || 'Guardando...'} />
        {/* <button type="button" onClick={confirmAction} className="btn btn-primary">{confirmText || 'Confirmar'}</button>         */}
      </div>
    </div>
  )
}

export default ConfirmModal
