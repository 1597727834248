import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Layout from "components/Layout";
import moment from "moment";
import { FormGroup, FormControl, Dropdown } from "react-bootstrap";
import { FiPlus, FiExternalLink, FiMoreHorizontal, FiDownload, FiUpload, } from "react-icons/fi";
import Loading from "components/Loading";
import PendingAbsences from "components/Absences/PendingAbsences";
import AbsenceEditModal from "components/Absences/AbsenceEditModal";
import { showModal, hideModal, updateModalLoading } from "actions/modalActions";
import * as absencesActions from "actions/absencesActions";
import * as employeesActions from "actions/employeesActions";
import * as shiftsActions from "actions/shiftsActions";
import { workflowsService, appService } from "services";
import Modal from "components/Modal";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { helpUrls } from "utils/helpUrls";

class AbsencesPending extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewAbsenceModal = this.openNewAbsenceModal.bind(this);
		this.openEditAbsenceModal = this.openEditAbsenceModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.handleCancelRequest = this.handleCancelRequest.bind(this);


		this.state = {
			focusedInput: null,
			employee_id: -1,
			isSubmit: false,
			is_list_view: true,
			isLoading: false,
		};
	}

	async componentDidMount() {
		// TODO revisar
		var contract_absence_shifts = true;
		contract_absence_shifts = false;
		this.props.getSelectableEmployees();
		await this.getPendingAbsences();

		await this.props.getAbsenceShifts({ shift_type: "Ausencia,Vacaciones", contract_only: contract_absence_shifts });
	}

	handleChangeView = (is_list_view) => {
		this.setState({
			is_list_view,
		});
	};

	async getPendingAbsences() {
		this.setState({
			isLoading: true,
		});
		const { start_from, start_to, employee_id } = this.state;
		await this.props.getPendingAbsences({ start_from, start_to, employee_id });
		this.setState({
			isLoading: false,
		});
	}

	onDatesChange = (dates) => {
		var { startDate, endDate } = dates;

		if (startDate)
			startDate = startDate.format("YYYY-MM-DD");

		if (endDate) {
			endDate = endDate.format("YYYY-MM-DD");
		}

		this.setState(
			{
				page: 0, // reset pagination
				start_from: startDate,
				start_to: endDate,
			},
			async () => {
				await this.getPendingAbsences();
			}
		);
	};

	handleChangeSearch = event => {
		const target_id = event.target.id;

		this.setState({
			[target_id]: event.target.value,
		}, async () => {
			await this.getPendingAbsences();
		});
	}

	closeModal() {
		this.props.hideModal();
	}

	openNewAbsenceModal() {
		this.props.showModal(
			{
				open: true,
				title: "Nueva ausencia",
				style: { width: "430px" },
				content: <AbsenceEditModal handleClose={this.closeModal} />,
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	openEditAbsenceModal(absence, disabled) {
		this.props.showModal(
			{
				open: true,
				title: disabled ? "Ver ausencia" : "Editar ausencia",
				style: { width: "430px" },
				content: (
					<AbsenceEditModal
						absence={absence}
						disabled={disabled}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: title || "Error",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	handleCancelRequest(absence) {
		this.props.showModal({
			open: true,
			title: 'Cancelar ausencia',
			style: { width: '350px' },
			message: '¿Desea cancelar la ausencia? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmCancelRequest(absence),
			closeModal: this.closeModal
		}, 'delete')
	}

	confirmCancelRequest = async (absence) => {
		absence.status = "Anulado";
		absence.shift_id = absence.shift.id;

		if (!this.state.isSubmit) {
			this.setState({ isSubmit: true });
			const response = await workflowsService.update(absence);
			this.setState({ isSubmit: false });

			// console.log(response);
			if (response.ok) {
				toastr.success("¡Bien!", "Cambios guardados correctamente");
				this.getPendingAbsences();
				this.closeModal();
			} else {

				if (
					response.errors !== undefined &&
					Object.keys(response.errors).length > 0
				) {
					let textError = response.errors[0].description;
					this.openAlertModal(textError, "Error al cancelar");
				}
				else {
					this.openAlertModal("Ha ocurrido un error", "Error al cancelar");
				}

			}
		}
	}


	// exportar

	openExportAbsencesModal(token) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar ausencias",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"Las ausencias se han exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, "ausencias.xlsx")
				},
			},
			"confirm"
		);
	}

	async handleExportAbsences(workflow_status) {
		const { start_to, start_from, employee_id } = this.state;

		// Aprobado,Denegado (Gestionadas)  / Pendiente (Pendientes)
		const token = await workflowsService.exportWorkflows({
			workflow_status,
			start_to,
			start_from,
			employee_id
		});

		if (token.ok) {
			this.openExportAbsencesModal(token.access_token);
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación",
				"Error al exportar"
			);
		}
	}

	async downloadExport(token, filename) {
		const response = await appService.getExported(token);
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = filename;
		a.click();

		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación
	}

	getContent = () => {
		if (this.state.isLoading) {
			return <Loading />;
		}

		if (!this.props.employee_absences) {
			return "Error cargando las ausencias";
		}

		let first_steps = "";

		if (
			this.props.pending_absences.length === 0
		) {
			const step_text = "Desde aquí podrás aprobar o denegar las peticiones de ausencia de los empleados";

			first_steps = (
				<div className="first-steps">
					<FiExternalLink className="icono-pasos" />
					<h3 className="title2">Primeros pasos</h3>

					<p>
						{step_text}. Aquí te explicamos cómo funcionan:{" "}
						<a
							className="alink"
							target="_blank"
							href={helpUrls.absence}
						>
							{helpUrls.absence}
						</a>
					</p>
				</div>
			);
		}

		return (
			<>
				{first_steps}
				<PendingAbsences
					openEditAbsenceModal={this.openEditAbsenceModal}
					openAlertModal={this.openAlertModal}
				/>
			</>
		);
	};

	render() {
		const { select_employees } = this.props;

		const employee_in_list = select_employees.some((e) => e.id === this.props.user.id);
		if (!employee_in_list)
			select_employees.push(this.props.user)

		let employeeList = select_employees.length > 0 &&
			select_employees.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name} {item.surname}
					</option>
				);
			}, this);

		return (
			<>
				<Layout className="page-absences" context="absences">
					<div className="heading">
						<h1 className="title">
							Ausencias pendientes
						</h1>

						<div className="heading-actions">
							<FormGroup
								className="form-select-user mr10"
								controlId="employee_id"
							>
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.employee_id}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="-1">Buscar empleado</option>
									{employeeList}
								</FormControl>
							</FormGroup>


							<DateRangePicker
								startDateId="startDateId"
								endDateId="endDateId"
								startDate={
									this.state.start_from ? moment(this.state.start_from) : null
								}
								endDate={this.state.start_to ? moment(this.state.start_to) : null}
								startDatePlaceholderText="Inicio"
								endDatePlaceholderText="Fin"
								onDatesChange={this.onDatesChange}
								focusedInput={this.state.focusedInput}
								hideKeyboardShortcutsPanel={true}
								isOutsideRange={() => false}
								minimumNights={0}
								onFocusChange={(focusedInput) =>
									this.setState({ focusedInput })
								}
							/>

							<button
								type="button"
								title="Crear ausencia"
								onClick={this.openNewAbsenceModal}
								className="btn btn-new"
							>
								<FiPlus />
							</button>

							<Dropdown>
								<Dropdown.Toggle variant="action">
									<FiMoreHorizontal />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item
										as="button"
										onClick={() =>
											this.props.history.push("/absences/import")
										}
									>
										<FiDownload /> Importar ausencias
									</Dropdown.Item>

									<Dropdown.Item
										as="button"
										onClick={() => this.handleExportAbsences("Pendiente")}
									>
										<FiUpload className="dropdown-icon" /> Exportar ausencias
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.shiftsReducer,
		...reducers.absencesReducer,
		...reducers.employeesReducer,
		user: reducers.authReducer.user,
		loading: reducers.absencesReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	updateModalLoading: (isLoading) => dispatch(updateModalLoading(isLoading)),
	getEmployeeAbsences: (employee_id) => dispatch(employeesActions.getEmployeeAbsences(employee_id)),
	getEmployeeAbsenceCalendar: (employee_id, year) => dispatch(employeesActions.getEmployeeAbsenceCalendar(employee_id, year)),
	getPendingAbsences: (options) => dispatch(absencesActions.getPendingAbsences(options)),
	getManagedAbsences: (options) => dispatch(absencesActions.getManagedAbsences(options)),
	getAbsenceShifts: (options) => dispatch(shiftsActions.getAllShifts(options)),
	getSelectableEmployees: (options) => dispatch(employeesActions.getSelectableEmployees(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbsencesPending);
