import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import Moment from "react-moment";
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown, Row, Col, FormGroup, FormControl } from "react-bootstrap";
import EmployeeListItemHeader from "components/Employees/EmployeeListItemHeader";
import AbsenceListItemPeriod from "components/Absences/AbsenceListItemPeriod";
import * as employeesActions from "actions/employeesActions";
import * as absencesActions from "actions/absencesActions";
import { workflowsService } from "services";
import { errorFormating } from "utils/utils";

class PendingAbsences extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);
		this.state = {
			pending_absences: []
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps)
			this.setState({
				pending_absences: this.props.pending_absences
			});
	}

	async handleAbsenceStatus(absence, isApproved) {
		let status = "Aprobado";

		if (!isApproved) status = "Denegado";

		absence.shift_id = absence.shift.id;
		absence.status = status;

		// console.log(absence)

		const response = await workflowsService.update(absence);
		// console.log(response);

		if (response.ok) {
			toastr.success("¡Bien!", "Cambios guardados correctamente");

			await this.props.getPendingAbsences();
			this.setState({
				pending_absences: this.props.pending_absences
			});
		} else {
			let textError = errorFormating(response);
			this.props.openAlertModal(
				textError.start,
				"Error al gestionar la ausencia"
			);
		}
	}

	handleChange = (event, absence) => {
		let key = event.target.id;
		let value = event.target.value;

		this.setState((prevState, props) => {
			return {
				pending_absences: prevState.pending_absences,
				pending_absences: prevState.pending_absences.map((a) =>
					a.id === absence.id ? { ...a, [key]: value } : a
				),
			};
		});
	};

	getEmptyRow = () => {
		return (
			<p className="no-results">No hay ausencias pendientes de supervisión.</p>
		);
	};

	getRows = () => this.state.pending_absences.map((absence) => (
		<div className="col-sm-6 col-lg-4 col-xl-3" key={absence.id}>
			<article className="card card-ausencia" key={absence.id}>
				<EmployeeListItemHeader employee={absence.requester} />

				<div className="card-content">
					<span className="absence-comments">{absence.comments}</span>

					<span
						className="card-shift"
						style={{ backgroundColor: absence.shift.background_color && absence.shift.background_color, borderColor: absence.shift.border_color && absence.shift.border_color }}>
						{absence.shift.name}
					</span>

					<AbsenceListItemPeriod absence={absence} />

					<FormGroup controlId="answer_comments" className="mt25">
						{/* <FormLabel>Comentarios</FormLabel> */}
						<FormControl
							as="textarea"
							value={absence.answer_comments}
							onChange={(e) => this.handleChange(e, absence)}
							className="answer-comments"
							placeholder="Puedes indicar el motivo de aprobar/denegar la ausencia"
						/>
					</FormGroup>

					<Row className="row-buttons">
						<Col sm={6}>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => this.handleAbsenceStatus(absence, true)}
							>
								Aprobar
							</button>
						</Col>
						<Col sm={6}>
							<button
								type="button"
								className="btn btn-secondary"
								onClick={() => this.handleAbsenceStatus(absence, false)}
							>
								Denegar
							</button>
						</Col>
					</Row>



					<p className="card-label card-label-solicitado">
						Solicitado el{" "}
						<Moment className="tag-dia" format="D/MM/YYYY">
							{absence.requested}
						</Moment>
					</p>
				</div>

				{(absence.status === "Pendiente" || absence.status === "Pending") && (
					<div className="card-actions bottom-right">
						<Dropdown>
							<Dropdown.Toggle variant="action">
								<FiMoreHorizontal />
							</Dropdown.Toggle>
							<Dropdown.Menu alignRight>
								<Dropdown.Item
									as="button"
									onClick={() => this.props.openEditAbsenceModal(absence)}
								>
									Editar
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				)}

			</article>
		</div>
	));

	render() {
		// console.log(this.props.pending_absences.length);
		return (
			<>
				{this.state.pending_absences.length === 0 ? (
					this.getEmptyRow()
				) : (
					<div className="row list-absences">{this.getRows()}</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.shiftsReducer,
		...reducers.absencesReducer,
		user: reducers.authReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getEmployeeAbsences: (employee_id) =>
		dispatch(employeesActions.getEmployeeAbsences(employee_id)),
	getPendingAbsences: (options) => dispatch(absencesActions.getPendingAbsences(options)),
	getManagedAbsences: (options) => dispatch(absencesActions.getManagedAbsences(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingAbsences);
