import { handleResponse } from "utils/utils";
import { authService } from "services";

export const dashboardsService = {
	getEmployeeHourSummary,
	getEmployeeVacationSummary,
	getEmployeeAbsenceCalendar,
};

// get employee's objective hours
function getEmployeeHourSummary(employeeId) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}dashboards/employees/${employeeId}/hour-summary`, requestOptions).then(
		handleResponse
	);
}

// get employee's vacation
function getEmployeeVacationSummary(employeeId, year) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}dashboards/employees/${employeeId}/vacation-summary?year=${year}`, requestOptions).then(
		handleResponse
	);
}

// get employee's vacation calendar
function getEmployeeAbsenceCalendar(employeeId, year) {
	const requestOptions = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}dashboards/employees/${employeeId}/absence-calendar-summary?year=${year}`, requestOptions).then(
		handleResponse
	);
}
