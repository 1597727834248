
import React from "react";
import { default as Card_Clockin } from "components/Dashboard/Cards/Card_Clockin"
import { default as Card_TotalWorkingHours } from "components/Dashboard/Cards/Card_TotalWorkingHours"
import { default as Card_PendingRequests } from "components/Dashboard/Cards/Card_PendingRequests"
import { default as Card_NextAbsences } from "components/Dashboard/Cards/Card_NextAbsences"
import { default as Card_WeekTimeRegistrations } from "components/Dashboard/Cards/Card_WeekTimeRegistrations"
import { default as Card_Birthdays } from "components/Dashboard/Cards/Card_Birthdays"
import { default as Card_WeekShifts } from "components/Dashboard/Cards/Card_WeekShifts"
import { default as Card_NextHolidays } from "components/Dashboard/Cards/Card_NextHolidays"
import { default as Card_OpenTimeRegistrations } from "components/Dashboard/Cards/Card_OpenTimeRegistrations"
import { default as Card_WorkingToday } from "components/Dashboard/Cards/Card_WorkingToday"


export const WIDGET_NAMES = {
	"DAY_TIME_REGISTRATIONS": "Fichaje",
	"TOTAL_WORKING_HOURS": "Total de horas trabajadas",
	"PENDING_REQUESTS": "Peticiones pendientes",
	"NEXT_ABSENCES": "Próximas ausencias",
	"WEEK_TIME_REGISTRATION": "Fichajes de esta semana",
	"BIRTHDAYS": "Cumpleaños",
	"WEEK_SHIFTS": "Turnos de la semana",
	"NEXT_HOLIDAYS": "Próximos festivos",
	"OPEN_TIME_REGISTRATIONS": "Fichajes abiertos",
	"WHO_IS_WORKING_TODAY": "¿Quién está trabajando hoy?",
};


export const WIDGETS = {
	"DAY_TIME_REGISTRATIONS": Card_Clockin,
	"TOTAL_WORKING_HOURS": Card_TotalWorkingHours,
	"PENDING_REQUESTS": Card_PendingRequests,
	"NEXT_ABSENCES": Card_NextAbsences,
	"WEEK_TIME_REGISTRATION": Card_WeekTimeRegistrations,
	"BIRTHDAYS": Card_Birthdays,
	"WEEK_SHIFTS": Card_WeekShifts,
	"NEXT_HOLIDAYS": Card_NextHolidays,
	"OPEN_TIME_REGISTRATIONS": Card_OpenTimeRegistrations,
	"WHO_IS_WORKING_TODAY": Card_WorkingToday,
};


export function getWidgetComponent(jsonObj) {
	if (jsonObj && jsonObj.name && WIDGETS[jsonObj.name]) {
		const WidgetComponent = WIDGETS[jsonObj.name];
		const widgetClass = `widget_${jsonObj.width}x${jsonObj.height}`;

		return (
			<div className={widgetClass} key={jsonObj.id}>
				<WidgetComponent {...jsonObj} />
			</div>
		);
	}
	return null;
}

export function sortByPosition(a, b) {
	if (!a.position) a.position = 99999;
	if (!b.position) b.position = 99999;

	if (a.position < b.position) {
		return -1;
	}
	if (a.position > b.position) {
		return 1;
	}
	return 0;
}