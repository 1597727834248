import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as plansActions from 'actions/plansActions';
import { planViewsService } from "services";

class CalendarViewEdit extends React.Component {
	constructor(props) {
		super(props);

		// console.log(props);

		// buscar en plan_views el que tenga my_calendar = true
		let plan_view_selected = props.plan_views.find(plan_view => plan_view.my_calendar === true);
		if (!plan_view_selected) {
			plan_view_selected = props.plan_views.find(plan_view => plan_view.system === true);
		}

		this.state = {
			isLoading: false,
			submitted: false,
			plan_views: props.plan_views,
			plan_view_current: plan_view_selected,
			plan_view_selected_id: plan_view_selected ? plan_view_selected.id : -1,
			errors: {},
		};
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value * 1
		});
	}

	prepareDetailsSubmit = (details) => {
		details.map(function (item) {
			if (item.account) {
				item.account_id = item.account.id;
			}
			else if (item.transaction_rule) {
				item.transaction_rule_id = item.transaction_rule.id;
			}
			return true;
		});

		return details;
	};

	handleSubmit = async event => {
		event.preventDefault();
		const props = this.props;

		this.setState({ isLoading: true });
		const { plan_views } = this.props;
		const { plan_view_current, plan_view_selected_id } = this.state;
		const plan_view_selected = plan_views.find(plan_view => plan_view.id === plan_view_selected_id);
		// console.log({ plan_view_current });
		// console.log({ plan_view_selected });

		// Comparar si el plan view es el mismo que el default. Si NO es el mismo, guardar cambios
		if (!plan_view_current || (plan_view_selected_id !== -1 && plan_view_current.id !== plan_view_selected_id)) {
			let response_current = { ok: true };

			if (plan_view_current) {
				plan_view_current.my_calendar = false;
				plan_view_current.details = this.prepareDetailsSubmit(plan_view_current.details);

				response_current = await planViewsService.update(plan_view_current);
				// console.log(response_current);
			}

			plan_view_selected.my_calendar = true;
			plan_view_selected.details = this.prepareDetailsSubmit(plan_view_selected.details);

			let response_selected = await planViewsService.update(plan_view_selected);
			// console.log(response_selected);


			if (response_selected.ok && response_current.ok) {
				this.setState({
					isLoading: false,
					errors: {},
				}, () => {

					toastr.success('¡Bien!', 'Cambios guardados correctamente');

					// refrescar CalendarHeader
					props.updateCalendarHeader();
					// cerrar ventana
					props.handleClose();

				});
			}
			else {
				this.setState({
					isLoading: false,
					submitted: true,
					errors: errorFormating(response_selected)
				});
			}
		}
		else {
			// console.log("sin cambios");
			props.handleClose();
		}

	}

	render() {
		const { plan_views } = this.props;
		const { plan_view_selected_id } = this.state;

		const optionList = plan_views.length > 0 && plan_views.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">
					<p>La vista que configures aquí se aplicará al calendario de todos los empleados de la empresa</p>
					<FormGroup controlId="plan_view_selected_id">
						<FormLabel>Vista calendario</FormLabel>
						<FormControl
							onChange={this.handleChange}
							onBlur={this.handleBlur}
							value={plan_view_selected_id}
							as="select">
							<option value="-1">Seleccionar vista</option>
							{optionList}
						</FormControl>
					</FormGroup>
				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.plansReducer,
	};
};

export default connect(mapStateToProps, plansActions)(CalendarViewEdit);