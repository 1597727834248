import React from 'react';
import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";

const CustomField = (props) => {
  // console.log(props);

  const employee_custom_field = props.employee_custom_field;
  const custom_field = employee_custom_field.custom_field;
  const value = employee_custom_field.value;
  const type = custom_field.type === "TEXT_AREA" ? "textarea" : custom_field.type.toLowerCase();
  // que passa amb number
  return (
    <FormGroup
      controlId={custom_field.name}
      className={`${type === "date" && "form-date"} ${value === "" && "empty"}`}
    >
      <FormLabel>
        {custom_field.name}
      </FormLabel>
      <FormControl
        className="custom-field-control"
        type={(type === "number") ? "text" : type}
        as={(type === "textarea") ? type : "input"}
        value={value}
        onChange={(e) => props.handleChangeCustomField(e, employee_custom_field)}
        onBlur={props.handleBlur}
        placeholder={custom_field.name}
        isInvalid={props.checkValidField(custom_field.name)}
      //inputMode="numeric" pattern="\d*"
      />
      {type === "date" && <FiCalendar />}

      <FormControl.Feedback type="invalid">
        {props.errors.name}
      </FormControl.Feedback>
    </FormGroup>
  );
};

export default CustomField;