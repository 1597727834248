import React, {Component} from 'react';
import moment from 'moment';
import Loading from "components/Loading";
import CounterStatic from "components/CounterStatic";

class Counter extends Component {
	state = {
		loading: true,
		hours: undefined,
		minutes: undefined,
		seconds: undefined
	}

	componentDidMount() {
		this.interval = setInterval(() => {
			const { start } = this.props;
			const start_time = moment.utc(start);
			const now = moment();

			const counter = moment.utc(now - start_time);
			const hours = counter.format('HH');
			const minutes = counter.format('mm');
			const seconds = counter.format('ss');

			this.setState({ hours, minutes, seconds, loading: false });
		}, 1000);
	}	

	componentWillUnmount() {
		if(this.interval) {
			clearInterval(this.interval);
		}
	}
	
	render() {
		const { hours, minutes, seconds } = this.state;

		if (this.state.loading){
		  return <Loading />;
		}

		return (
			<CounterStatic hours={hours} minutes={minutes} seconds={seconds} />
		);
	}
}

export default Counter;
