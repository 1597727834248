import { workflowsService } from "services";

// export const getAllAbsences = (employee_id) => async (dispatch) => {

// 	dispatch({
// 		type: 'LOADING'
// 	});

// 	try{
// 		const list = await workflowsService.getAllAbsences(employee_id);

// 		dispatch({
// 			type: 'GET_ABSENCES',
// 			payload: list.data
// 		});
// 	}
// 	catch (error){
// 		console.log('Error: ' + error.message);
// 			dispatch({
// 				type: 'ERROR',
// 				payload: error.message
// 			});
// 	}
// }

export const getPendingAbsences = (options) => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await workflowsService.getPendingAbsences(options);
		// console.log(list.data);
		dispatch({
			type: "GET_PENDING_ABSENCES",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getManagedAbsences = (options) => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await workflowsService.getManagedAbsences(options);
		// console.log(list);
		dispatch({
			type: "GET_MANAGED_ABSENCES",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};
