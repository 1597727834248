import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { isTrial, getTrialDays } from "utils/utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {
	// console.log(props);
	const [isMobileNavVisible, setMobileNavVisible] = useState(false);
	const [isDesktopMini, setDesktopMini] = useState(false);
	const [width, setWidth] = React.useState(window.innerWidth);
	const breakpoint = 988; //768

	useEffect(() => {
		window.addEventListener("resize", () => setWidth(window.innerWidth));
		checkSidebarStatus();
	}, []);

	// mira a local storage si sidebar minimizado o no
	const checkSidebarStatus = () => {
		const storedSidebarMini = localStorage.getItem("sidebar_mini");
		if (storedSidebarMini === "true" || storedSidebarMini === true) {
			setDesktopMini(true);
		} else {
			setDesktopMini(false);
		}
	}

	const toggleNav = () => {
		if (width < breakpoint) {
			setMobileNavVisible(!isMobileNavVisible);
		}
		else {
			var sidebar = document.querySelector('.sidebar');
			var mainContent = document.querySelector('.main-content');
			var testClass = sidebar.classList.contains("mini");

			setMobileNavVisible(false);

			// fix por si se ha forzado el maximizado desde planificación via classList:
			if (testClass) {
				sidebar.classList.remove('mini');
				mainContent.classList.remove('mini');
				setDesktopMini(false);
				localStorage.setItem("sidebar_mini", false);
			} else {
				setDesktopMini(true);
				localStorage.setItem("sidebar_mini", true);
			}
		}
	};

	const closeNav = () => {
		setMobileNavVisible(false);
	};

	var extraClasses = "";
	var isTrialEnded = false;

	if (isTrial()) {
		extraClasses = "is_trial";

		const totalDays = getTrialDays();
		if (totalDays < 0) {
			extraClasses += " is_trial_ended";
			isTrialEnded = true;
		}
	}

	return (
		<div className={`app ${extraClasses}`}>
			<Header toggleNav={toggleNav} isMobileNavVisible={isMobileNavVisible} closeNav={closeNav} />

			<div
				className={`app_container ${props.className ? props.className : ""}`}
			>
				<Sidebar toggleNav={toggleNav} isTrialEnded={isTrialEnded} isMobileNavVisible={isMobileNavVisible} className={`desktop-sidebar ${isDesktopMini ? "mini" : ""}`} context={props.context || ""} />
				<section className={`main-content ${isDesktopMini ? "mini" : ""}`}>
					{props.children}
				</section>
			</div>

			<ToastContainer />
		</div>
	);
};

export default Layout;
