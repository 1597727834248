import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FiMoreHorizontal, FiCalendar } from "react-icons/fi";
import { withRouter } from 'react-router-dom';
import { TabletOrDesktop, Mobile } from 'utils/responsive';
import { Dropdown } from 'react-bootstrap';
import GroupEdit from "components/Groups/GroupEditModal";
import Avatars from 'components/Avatar/Avatars';
import { showModal, hideModal } from "actions/modalActions";
import * as groupsActions from 'actions/groupsActions';
import { groupsService } from "services";
import { errorFormating } from "utils/utils";

class GroupList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.getGroups = this.getGroups.bind(this);
	}

	closeModal() {
		this.props.hideModal()
	}

	getGroups() {
		this.props.getAllGroups({ variant: 'detailed' });
	}

	openEditGroupModal(group) {
		this.props.showModal({
			open: true,
			title: 'Editar grupo',
			style: { width: '400px' },
			content: <GroupEdit group={group} getGroups={this.getGroups} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openViewGroupPage(group) {
		this.props.history.push("/groups/" + group.id);
	}

	openDeleteGroupModal(idGroup) {
		this.props.showModal({
			open: true,
			title: 'Eliminar grupo',
			style: { width: '400px' },
			message: '¿Desea eliminar el grupo? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idGroup),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}


	confirmDelete = async (idGroup) => {
		const response = await groupsService.remove(idGroup);

		if (!response.ok) {
			let textError = errorFormating(response);
			console.log(textError);
			this.openAlertModal(textError.id);
		}
		else {
			this.getGroups();
			this.closeModal();
		}
	}

	getGroupList = (group) => group.groups.map((item) => {
		return (
			<li key={item.id} className="tag-default">
				{item.name}
			</li>
		)
	});

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="6" className="no-result">
					Todavía no hay ningún grupo creado.
				</td>
			</tr>
		);
	}

	getRows = (isMobile) => this.props.detailed_groups.map((group) => {
		let empleados_count = "Ningún empleado asignado";
		let total_employees = group.total_employees;
		let employees_avatars = group.employees;

		// console.log(group);

		if (group.is_multigroup) {
			employees_avatars = [];

			group.groups.map((g, i) => {
				total_employees += g.total_employees;
				employees_avatars.push(...g.employees);
			});

			if (employees_avatars.length > 2) {
				employees_avatars = employees_avatars.slice(0, 3);
			}
		}

		if (total_employees === 1)
			empleados_count = "1 empleado";
		else if (total_employees > 1)
			empleados_count = total_employees + " empleados";


		if (!isMobile) {
			return (
				<tr key={group.id}>
					<td className="td-name">
						<button className="btn-transparent btn-editable group-name" onClick={() => this.openViewGroupPage(group)}>{group.name}</button>
					</td>
					<td className="td-employees">
						<div className="avatar-container">
							{total_employees > 0 &&
								<Avatars employees={employees_avatars} />
							}
							{empleados_count}
						</div>
					</td>
					<td className="td-calendar">
						{group.calendar ? group.calendar.name : '-'}
					</td>
					<td className="td-groups">
						{group.is_multigroup && (
							<ul className="list-tags">
								{this.getGroupList(group)}
							</ul>
						)}
					</td>
					<td className="td-icon">
						<span
							className="bolita"
							style={{ backgroundColor: group.color && group.color }}>
						</span>
					</td>
					<td className="td-actions">
						{this.getActions(group)}
					</td>
				</tr>
			)
		}
		else {
			return (
				<tr key={group.id}>
					<td>
						<div className="group-head">
							<button className="btn-transparent btn-editable group-name" onClick={() => this.openViewGroupPage(group)}>{group.name}</button>
							{group.calendar &&
								(
									<div className="calendar-container">
										<FiCalendar /> <span>{group.calendar.name}</span>
									</div>
								)}
						</div>
						<div className="flex-between">

							<div className="avatar-container">
								{total_employees > 0 &&
									<Avatars employees={employees_avatars} />
								}
								{empleados_count}
							</div>
							<span
								className="bolita m0"
								style={{ backgroundColor: group.color && group.color }}>
							</span>
						</div>

						{group.is_multigroup && (
							<ul className="list-tags mt10">
								{this.getGroupList(group)}
							</ul>
						)
						}
					</td>
					<td className="td-actions">
						{this.getActions(group)}
					</td>
				</tr>
			)
		}

	});

	getActions = (group) => {
		return (
			<Dropdown>
				<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
				<Dropdown.Menu alignRight>
					<Dropdown.Item as="button" onClick={() => this.openViewGroupPage(group)}>Detalle</Dropdown.Item>
					<Dropdown.Item as="button" onClick={() => this.openEditGroupModal(group)}>Editar</Dropdown.Item>
					<Dropdown.Item as="button" onClick={() => this.openDeleteGroupModal(group.id)}>Eliminar</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		)
	};

	render() {
		return (
			<>
				<Mobile>
					<table className="table table-groups table-mobile">
						<tbody>
							{this.props.detailed_groups.length === 0 ?
								this.getEmptyRow()
								:
								this.getRows(true)
							}
						</tbody>
					</table>
				</Mobile>
				<TabletOrDesktop>
					<table className="table table-groups table-zebra">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Empleados</th>
								<th>Calendario</th>
								<th>Grupos</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.detailed_groups.length === 0 ?
								this.getEmptyRow()
								:
								this.getRows(false)
							}
						</tbody>
					</table>
				</TabletOrDesktop>
			</>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.groupsReducer;
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getAllGroups: (options) => dispatch(groupsActions.getAllGroups(options))
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GroupList);