import { handleResponse } from "utils/utils";
import { authService } from "services";

export const planViewsService = {
    getAll,
    getById,
    getDefault,
    add,
    update,
    remove,
    getScopes,
};

// gets all views
function getAll() {
    const requestOptions = {
        method: 'GET',
	    headers: {
	      'Authorization' : `Bearer ${authService.token.access_token}`
	    },
    };

    let query = '?sort=+name';

    return fetch(`${global.BASE_API}plan-views${query}`, requestOptions).then(handleResponse);
}


// get one view by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization' : `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}plan-views/${id}`, requestOptions).then(handleResponse);
}


// get default plan view
function getDefault() {
    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization' : `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}plan-views/default`, requestOptions).then(handleResponse);
}


// add new view
function add(view) {
    const requestOptions = {
        method: 'POST',
	    headers: {
	      'Authorization' : `Bearer ${authService.token.access_token}`,
		  'Content-Type': 'application/json'
	    },
	    body: JSON.stringify(view)
    };

    return fetch(`${global.BASE_API}plan-views/`, requestOptions).then(handleResponse);
}

// update view
function update(view) {
    const requestOptions = {
        method: 'PUT',
	    headers: {
	      'Authorization' : `Bearer ${authService.token.access_token}`,
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify(view)
    };

    return fetch(`${global.BASE_API}plan-views/${view.id}`, requestOptions).then(handleResponse);
}

// remove view
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
	    headers: {
	      'Authorization' : `Bearer ${authService.token.access_token}`
	    },
    };

    return fetch(`${global.BASE_API}plan-views/${id}`, requestOptions).then(handleResponse);
}


// get scopes
function getScopes() {
    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization' : `Bearer ${authService.token.access_token}`
        },
    };
    return fetch(`${global.BASE_API}plan-views/scopes`, requestOptions).then(handleResponse);
}
