import { authService } from "services";
import "utils/lib/websocket/sockjs-0.3.4";
import "utils/lib/websocket/stomp";
import moment from "moment";
import { toast } from "react-toastify";

export const plansSocketService = {
	connect,
	disconnect,
	sendMessage,
	isConnected,
};

var stompClient, currentStart, currentEnd, currentUser = null

function isConnected() {
	// Verificar si stompClient existe y está conectado
	return stompClient && stompClient.connected;
}

function connect(options) {
	const { group_id, hasPrivileges, start, end, user } = options;

	currentStart = start;
	currentEnd = end;
	currentUser = user;

	var socket = new SockJS(global.BASE_WS);
	stompClient = Stomp.over(socket);

	stompClient.connect(
		{
			'jwt_token': authService.token.access_token,
		},
		function (frame) {
			// console.log('Connected: ' + frame);

			stompClient.subscribe('/topic/plans/' + group_id, function (messageOutput) {
				// solo mostrar si hay alguien conectado cuando es admin, sino es irrelevante
				if (hasPrivileges) {
					_showMessageOutput(JSON.parse(messageOutput.body));
				}
			});
			stompClient.subscribe('/topic/plan-edited/' + group_id, function (messageOutput) {
				// si se ha editado interesa que lo vea cualquiera que lo tenga abierto (sea admin o no)
				_showMessageEdited(JSON.parse(messageOutput.body));
			});
		});
}

function disconnect() {
	if (stompClient != null) {
		stompClient.disconnect();
	}

	// console.log("Disconnected");
}


function sendMessage(options) {
	const { group_id, start, end } = options;

	stompClient.send("/plain/openplan/" + group_id, {}, JSON.stringify({ 'start': start, 'end': end, 'group_id': group_id }));
}


function _renderEmployeeList(openPlans) {
	const list = openPlans.map((plan, i) => {
		return <li key={i}><strong>{plan.employee_full_name}</strong> del {moment(plan.start).format("DD/MM/YY")} al {moment(plan.end).format("DD/MM/YY")}</li>
	});

	return list;
}

// filtra que sea un empleado distinto al loggeado
// filtra que las fechas se solapen con las del plan abierto
function _checkValidDate(plan) {
	const user_full_name = currentUser.name + " " + currentUser.surname;
	const format = 'YYYY-MM-DD';

	const planStart = moment(plan.start, format);
	const planEnd = moment(plan.end, format);
	const beforeTime = moment(currentStart, format);
	const afterTime = moment(currentEnd, format);

	return user_full_name !== plan.employee_full_name && (planStart.isBetween(beforeTime, afterTime) || planStart.isSame(beforeTime) || planStart.isSame(afterTime) || planEnd.isBetween(beforeTime, afterTime) || planEnd.isSame(beforeTime) || planEnd.isSame(afterTime));
}

function _filterPlans(openPlans) {
	return openPlans.filter((plan) => _checkValidDate(plan));
}

function _showMessageOutput(openPlans) {
	// console.log("MessageOutput");
	// console.log(openPlans);

	const filteredPlans = _filterPlans(openPlans);
	toast.dismiss("userAlert");

	if (filteredPlans.length > 0) {
		var messageIntro = (filteredPlans.length === 1) ? "El siguiente empleado también está viendo este plan:" : "Los siguientes empleados también están viendo este plan:";
		var message = <div>
			<p>{messageIntro}</p>
			<ul className="lista-empleados">
				{_renderEmployeeList(filteredPlans)}
			</ul>
		</div>

		// TODO si hay empleados repetidos ¿?

		toast.info(message, {
			position: "top-right",
			autoClose: false,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			toastId: "userAlert"
		});


	}
}

function _showMessageEdited(openPlan) {
	// console.log("MessageEdited");
	// console.log(openPlan);

	// filtrar por fecha (solo mostrar alerta si se solapa el periodo)
	const validDate = _checkValidDate(openPlan);

	if (validDate) {
		var message = openPlan.employee_full_name + " ha actualizado la planificación entre las fechas " + moment(openPlan.start).format("DD/MM/YY") + " y " + moment(openPlan.end).format("DD/MM/YY") + ". Refresca la página para ver los cambios.";
		//toast.dismiss("editAlert");
		toast.info(message, {
			position: "top-right",
			autoClose: false,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			toastId: "editAlert"
		});
	}
}



