import { shiftsService } from "services";
import { shiftPatternsService } from "services";
import moment from "moment";

export const getAllShifts = (options) => async (dispatch) => {

	// dispatch({
	// 	type: 'LOADING'
	// });

	try {
		const list = await shiftsService.getAll(options);
		// console.log(list);
		dispatch({
			type: 'GET_SHIFTS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getDefaultShift = () => async (dispatch) => {

	try {
		const shift = await shiftsService.getDefault();
		// console.log(shift);
		dispatch({
			type: 'GET_DEFAULT_SHIFT',
			payload: shift
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getSelectableShifts = (options) => async (dispatch) => {

	try {
		const list = await shiftsService.getSelectable(options);

		dispatch({
			type: 'GET_SELECT_SHIFTS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getAttendanceShifts = () => async (dispatch) => {

	try {
		const list = await shiftsService.getByAttendance(true);

		dispatch({
			type: 'GET_ATTENDANCE_SHIFTS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getNonAttendanceShifts = () => async (dispatch) => {

	try {
		const list = await shiftsService.getByAttendance(false);

		dispatch({
			type: 'GET_NON_ATTENDANCE_SHIFTS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getShiftTypes = () => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try {
		const list = await shiftsService.getTypes();

		dispatch({
			type: 'GET_SHIFT_TYPES',
			payload: list
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}



// shift patterns

export const getAllShiftPatterns = (options) => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try {
		const list = await shiftPatternsService.getAll(options);
		// console.log(list);
		dispatch({
			type: 'GET_SHIFT_PATTERNS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}

export const getSelectableShiftPatterns = () => async (dispatch) => {

	try {
		const list = await shiftPatternsService.getSelectable();

		dispatch({
			type: 'GET_SELECT_SHIFT_PATTERNS',
			payload: list.data
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}


export const getWorkingHours = () => async (dispatch) => {

	try {

		// if (response.ok) {
		// 	const format = 'hh:mm';
		// 	var start_time = moment("00:00", format);
		// 	var end_time = moment("23:59", format);
		// 	var hasHours = false;

		// 	response.data.length > 0 && response.data.map((shift, i) => {
		// 		shift.time_intervals.map((interval, j) => {
		// 			if (interval.end === "00:00") {
		// 				interval.end = "23:59";
		// 			}

		// 			var this_start_time = moment(interval.start, format),
		// 				this_end_time = moment(interval.end, format);

		// 			if (!hasHours) {
		// 				start_time = this_start_time;
		// 				end_time = this_end_time;
		// 			} else {
		// 				if (this_start_time.isBefore(start_time)) {
		// 					start_time = this_start_time;
		// 				}
		// 				if (this_end_time.isAfter(end_time)) {
		// 					end_time = this_end_time;
		// 				}
		// 			}
		// 			hasHours = true;
		// 			return interval;
		// 		});
		// 		return true;
		// 	});
		// 	working_hours = {start: start_time,	end: end_time}
		// }

		const response = await shiftsService.getAll({ q: "", shift_type: "Trabajo" });
		var working_hours = {
			start: "00:00",
			end: "23:59",
		};

		if (response.ok && response.data.length > 0) {
			const format = 'HH:mm';
			var start_time = moment("23:59", format); // Inicializar con el máximo
			var end_time = moment("00:00", format); // Inicializar con el mínimo
			var crossesMidnight = false;

			response.data.forEach((shift) => {
				shift.time_intervals.forEach((interval) => {
					var this_start_time = moment(interval.start, format),
						this_end_time = moment(interval.end, format);

					// Verificar si el turno cruza la medianoche
					if (interval.end === "00:00" || this_end_time.isBefore(this_start_time)) {
						crossesMidnight = true;
					} else {
						if (this_start_time.isBefore(start_time)) {
							start_time = this_start_time;
						}
						if (this_end_time.isAfter(end_time)) {
							end_time = this_end_time;
						}
					}
				});
			});

			if (!crossesMidnight) {
				// Ajustar la representación de tiempos para la salida
				working_hours = {
					start: start_time.format(format),
					end: end_time.format(format),
				};
			}
		}
		// console.log({ working_hours });
		dispatch({
			type: 'GET_WORKING_HOURS',
			payload: working_hours
		});
	}
	catch (error) {
		console.log('Error: ' + error.message);
		dispatch({
			type: 'ERROR',
			payload: error.message
		});
	}
}