const initialState = {
	company: {},
	subscription_types: [],
	payment_models: [],
	employees_count: 0,
	countries: [],
	loading: false,
	error: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "GET_COMPANY":
			return {
				...state,
				company: action.payload,
				loading: false,
				error: "",
			};
		case "GET_SUBSCRIPTION_TYPES":
			return {
				...state,
				subscription_types: action.payload,
				loading: false,
				error: "",
			};
		case "GET_PAYMENT_MODELS":
			return {
				...state,
				payment_models: action.payload,
				loading: false,
				error: "",
			};
		case "GET_COUNTRIES":
			return {
				...state,
				countries: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEES_COUNT":
			return {
				...state,
				employees_count: action.payload,
				loading: false,
				error: "",
			};
		case "LOADING":
			return { ...state, loading: true };

		case "ERROR":
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			return state;
	}
};
