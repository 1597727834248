import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { IoMdColorFill } from "react-icons/io";
import { TwitterPicker } from 'react-color';
import { FiDelete, FiX, FiClock, FiTrash2 } from "react-icons/fi";
import { ubicationsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as ubicationsActions from 'actions/ubicationsActions';

class UbicationEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let ubication = {
			name: '',
			description: '',
			enabled: true,
			background_color: '',
			border_color: '',
		}

		let isEdit = false;

		if (props.ubication) {
			ubication = props.ubication;
			isEdit = true;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			ubication,
			isBorderPickerVisible: false,
			isBackgroundPickerVisible: false,
		};
	}

	handleChange = event => {
		const { ubication } = this.state;

		this.setState({
			ubication: {
				...ubication,
				[event.target.id]: event.target.value
			},
		});
	}

	handleSwitch = event => {
		const { ubication } = this.state;

		this.setState({
			ubication: {
				...ubication,
				[event.target.id]: event.target.checked
			},
		});
	}

	handleColorChange = ({ hex }, type) => {
		const { ubication } = this.state;

		if (hex === undefined)
			hex = '';

		this.setState({
			ubication: {
				...ubication,
				[type]: hex
			},
			isBackgroundPickerVisible: false,
			isBorderPickerVisible: false,
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();
		const props = this.props;
		this.setState({ isLoading: true });
		let response = '';

		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await ubicationsService.update(this.state.ubication);
		}
		else {
			response = await ubicationsService.add(this.state.ubication);
		}

		// console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getUbications({});
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	render() {
		const { ubication, errors } = this.state;
		var isActive = ubication.enabled;

		const onToggleBackgroundPicker = () => this.setState({ isBackgroundPickerVisible: !this.state.isBackgroundPickerVisible })
		const onToggleBorderPicker = () => this.setState({ isBorderPickerVisible: !this.state.isBorderPickerVisible })

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">
					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={ubication.name}
							onChange={this.handleChange}
							placeholder="Nombre de la ubicación"
							isInvalid={this.checkValidField('name')}
						/>

						<FormControl.Feedback type="invalid">
							{errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="description">
						<FormLabel>Descripción</FormLabel>
						<FormControl
							type="text"
							value={ubication.description}
							onChange={this.handleChange}
							placeholder="Dirección"
							isInvalid={this.checkValidField('description')}
						/>

						<FormControl.Feedback type="invalid">
							{errors.description}
						</FormControl.Feedback>
					</FormGroup>



					<Row>
						<Col sm={6} >
							<FormGroup controlId="background_color" className="form-color">
								<div className="flex-between">
									<FormLabel>Color fondo</FormLabel>
									<FiDelete className={`icon-remove-color ${!ubication.background_color && 'hidden'} `} onClick={() => this.handleColorChange("", "background_color")} />
								</div>
								<FormControl
									type="text"
									value={ubication.background_color}
									placeholder="#FFFFFF"
									readOnly
									isInvalid={this.checkValidField('background_color')}
									style={{ backgroundColor: ubication.background_color && ubication.background_color }}
									onClick={onToggleBackgroundPicker}
								/>
								<FormControl.Feedback type="invalid">
									{errors.background_color}
								</FormControl.Feedback>

								<IoMdColorFill onClick={onToggleBackgroundPicker} />

								{this.state.isBackgroundPickerVisible && (
									<div style={{ position: 'absolute' }}>
										<TwitterPicker
											color={ubication.background_color ? ubication.background_color : '#ffffff'}
											onChangeComplete={(hex) => this.handleColorChange(hex, "background_color")}
										/>
									</div>
								)}
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup controlId="border_color" className="form-color">

								<div className="flex-between">
									<FormLabel>Color borde</FormLabel>
									<FiDelete className={`icon-remove-color ${!ubication.border_color && 'hidden'} `} onClick={() => this.handleColorChange("", "border_color")} />
								</div>

								<FormControl
									type="text"
									value={ubication.border_color}
									placeholder="#FFFFFF"
									readOnly
									isInvalid={this.checkValidField('border_color')}
									style={{ backgroundColor: ubication.border_color && ubication.border_color }}
									onClick={onToggleBorderPicker}
								/>
								<FormControl.Feedback type="invalid">
									{errors.border_color}
								</FormControl.Feedback>

								<IoMdColorFill onClick={onToggleBorderPicker} />

								{this.state.isBorderPickerVisible && (
									<div style={{ position: 'absolute' }}>
										<TwitterPicker
											color={ubication.border_color ? ubication.border_color : '#ffffff'}
											onChangeComplete={(hex) => this.handleColorChange(hex, "border_color")}
										/>
									</div>
								)}
							</FormGroup>
						</Col>
					</Row>

					<FormGroup className="form-group-flex mb0" controlId="enabled">
						<FormLabel>Activo</FormLabel>
						<Switch
							controlId="enabled"
							isOn={isActive}
							handleToggle={this.handleSwitch}
						/>

						<FormControl.Feedback type="invalid">
							{errors.enabled}
						</FormControl.Feedback>
					</FormGroup>

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.ubicationsReducer;
};


export default connect(mapStateToProps, ubicationsActions)(UbicationEdit)
// export default connect(mapStateToProps)(FunctionEdit)
