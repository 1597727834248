import { calendarsService, dayTypesService } from "services";

export const getAllCalendars = (query) => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try{
		const list = await calendarsService.getAll(query);

		dispatch({
			type: 'GET_CALENDARS',
			payload: list.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}

export const getSelectableCalendars = () => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try{
		const list = await calendarsService.getSelectable();
		dispatch({
			type: 'GET_SELECT_CALENDARS',
			payload: list.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}

export const getWeekdaysDefault = () => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try{
		const list = await calendarsService.getWeekdaysDefault();
		dispatch({
			type: 'GET_WEEKDAYS_DEFAULT',
			payload: list.weekdays
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}


export const getAllDayTypes = (query) => async (dispatch) => {

	dispatch({
		type: 'LOADING'
	});

	try{
		const list = await dayTypesService.getAll(query);

		dispatch({
			type: 'GET_DAY_TYPES',
			payload: list.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}


export const getSelectableDayTypes = () => async (dispatch) => {

	try{
		const list = await dayTypesService.getSelectable();

		dispatch({
			type: 'GET_SELECT_DAY_TYPES',
			payload: list.data
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
			dispatch({
				type: 'ERROR',
				payload: error.message
			});
	}
}