import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus } from "react-icons/fi";
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import TemplateList from 'components/WidgetTemplates/TemplateList';
import TemplateEditModal from 'components/WidgetTemplates/TemplateEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as dashboardActions from 'actions/dashboardActions';
import Modal from 'components/Modal';

class WidgetTemplates extends Component {
	constructor(props) {
		super(props);

		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewTemplateModal = this.openNewTemplateModal.bind(this);
	}

	componentDidMount() {
		this.getWidgetTemplates();
		this.props.getWidgets();
	}

	async getWidgetTemplates() {
		await this.props.getWidgetTemplates();
	}

	closeModal() {
		this.props.hideModal()
	}

	openNewTemplateModal() {
		this.props.showModal({
			open: true,
			title: 'Nueva plantilla',
			style: { width: '600px' },
			content: <TemplateEditModal handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	getContent = () => {
		if (this.props.loading) {
			return <Loading />;
		}

		if (!this.props.widget_templates) {
			return "Error cargando las plantillas";
		}

		return (<TemplateList widget_templates={this.props.widget_templates} />);
	}

	render() {
		return (
			<>
				<Layout className="page-widget-templates" context="widget-templates">
					<div className="heading">
						<h1 className="title">Plantillas de widgets</h1>
						<div className="heading-actions">
							<button type="button" title="Crear plantilla" onClick={this.openNewTemplateModal} className="btn btn-new"><FiPlus /></button>
						</div>
					</div>
					{this.getContent()}
				</Layout>
				<Modal hideModal={this.props.hideModal} />
			</>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.dashboardReducer,
		loading: reducers.dashboardReducer.loading
	}
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getWidgetTemplates: () => dispatch(dashboardActions.getWidgetTemplates()),
	getWidgets: () => dispatch(dashboardActions.getWidgets()),
})


export default connect(mapStateToProps, mapDispatchToProps)(WidgetTemplates)