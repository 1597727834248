import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import PlanViewEditModal  from 'components/PlanViews/PlanViewEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as plansActions from 'actions/plansActions';
import { planViewsService } from "services";
import { errorFormating } from "utils/utils";

class PlanViewsList extends Component {
  constructor(props) {
    super(props);
    // console.log(props);

    this.closeModal = this.closeModal.bind(this);
    this.openEditPlanViewModal = this.openEditPlanViewModal.bind(this);
    this.openDeletePlanViewModal = this.openDeletePlanViewModal.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
  }

	closeModal() {
		this.props.hideModal()
	}

	openEditPlanViewModal(planView) {
		this.props.showModal({
		  open: true,
		  title: 'Editar vista',
		  style: {width: '600px'},
		  content: <PlanViewEditModal planView={planView}  handleClose={this.closeModal}  />,
		  closeModal: this.closeModal
		}, 'edit')
	}

	openDeletePlanViewModal(idPlanview) {
		this.props.showModal({
		  open: true,
		  title: 'Eliminar vista',
		  style: {width: '400px'},
		  message:'¿Desea eliminar la vista de planificación? Esta acción no se puede deshacer',
		  deleteAction: () => this.confirmDelete(idPlanview),
		  closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
		 open: true,
		 title: "Error al eliminar",
		 style: {width: '400px'},
		 message: message,
		 closeModal: this.closeModal
		}, 'alert')
	} 	

	confirmDelete= async(idPlanview) =>  {
		const response = await planViewsService.remove(idPlanview);

		if (!response.ok){
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else{
			this.props.getPlanViews();
			this.closeModal();
		}
	}


	getEmptyRow = () => {
		return(
			<p>Sin resultados.</p>
		);
	} 	

	getViewDetails = (view_details) =>  view_details.map((item, index)  => {
		return (
			<li key={ index } aria-label={item.label} title={item.label}> 
				{item.label} ({item.scope})
			</li>
		)
	});  

	getRows = () => this.props.plan_views.map((planView) => {
		//console.log(planView);
		return (
			<div className="col-sm-6 col-lg-4 col-xl-3" key={ planView.id }>

				<article className="card" key={ planView.id }>
					<div className="card-top">
						<h2 className="card-title">{ planView.name }</h2>
					</div>
					<div className="card-content">
						<div className="form-group no-form">
							<label>Columnas</label>
							<ul className="list-columns">
								{this.getViewDetails(planView.details)}
							</ul>
						</div>	
					</div>	

					<div className="card-actions bottom-right">
	              <Dropdown>
	                <Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
	                <Dropdown.Menu alignRight>
	                  <Dropdown.Item as="button" onClick={()=> this.openEditPlanViewModal(planView)}>Editar</Dropdown.Item>
	                  { 
	                  	!planView.system && (<Dropdown.Item as="button" onClick={()=> this.openDeletePlanViewModal(planView.id)}>Eliminar</Dropdown.Item>) 
	                  }
	                </Dropdown.Menu>
	              </Dropdown>
					</div>
				</article>

			</div>
		)
	});	

	render() {
		return(
			<>
			  { this.props.plan_views.length === 0 ?
	             this.getEmptyRow() 
	            :
     			<div className="row list-plan-views">
					{this.getRows() }
				</div>
	           }	
	        </>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.plansReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getPlanViews: (q) => dispatch(plansActions.getPlanViews(q)),
})


export default connect(mapStateToProps, mapDispatchToProps)(PlanViewsList)