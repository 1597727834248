import React from "react";
import { default as WorkingToday } from "components/Dashboard/Dashboard_WorkingToday"
import { WIDGET_NAMES } from "components/WidgetTemplates/utils";

const Card_WorkingToday = (props) => {
	// console.log(props);

	return (
		<article className="card card-birthday">
			<div className="card-top">
				<h2>{WIDGET_NAMES[props.name]}</h2>
			</div>
			<div className="card-content">
				<WorkingToday />
			</div>
		</article>
	);
};

export default Card_WorkingToday;