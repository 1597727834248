import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { customFieldsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as customFieldsActions from 'actions/customFieldsActions';
import { CUSTOM_FIELDS_TYPES } from "./";


class CustomFieldEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let custom_field = {
			name: '',
			type: '',
		}

		let isEdit = false;

		if (props.custom_field) {
			custom_field = props.custom_field;
			isEdit = true;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			custom_field
		};
	}

	handleChange = event => {
		const { custom_field } = this.state;

		this.setState({
			custom_field: {
				...custom_field,
				[event.target.id]: event.target.value
			},
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;

		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await customFieldsService.update(this.state.custom_field);
		}
		else {
			response = await customFieldsService.add(this.state.custom_field);
		}

		//console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getAllCustomFields();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	render() {
		const { custom_field } = this.state;

		const optionList = CUSTOM_FIELDS_TYPES.map((item, i) => {
			return (
				<option key={i} value={item.type}>{item.label}</option>
			)
		}, this);

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">
					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={custom_field.name}
							onChange={this.handleChange}
							placeholder="Nombre del campo"
							isInvalid={this.checkValidField('name')}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="type">
						<FormLabel>Tipo</FormLabel>
						<FormControl
							onChange={this.handleChange}
							value={custom_field.type}
							isInvalid={this.checkValidField('type')}
							as="select">
							<option value="-1">Seleccionar tipo</option>
							{optionList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{this.state.errors.type}
						</FormControl.Feedback>
					</FormGroup>
				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.customFieldsReducer;
};


export default connect(mapStateToProps, customFieldsActions)(CustomFieldEdit)
