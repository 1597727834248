import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import arrayMove from "array-move";
import { planViewsService } from "services";
import { errorFormating } from "utils/utils";
import ColumnList from "./PlanViewColumnList";
import SubmitButton from "components/SubmitButton";
import * as plansActions from "actions/plansActions";

class PlanViewEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let planView = {
			name: "",
			details: [],
		};

		let isEdit = false;

		if (props.planView) {
			isEdit = true;
			planView = props.planView;
		}
		// prepare rule list
		const rules = [];
		const accounts = [];

		props.rules.forEach((item) => {
			const rule = {
				transaction_rule: item,
				transaction_rule_id: item.id,
				label: item.name + " periodo",
			};
			rules.push(rule);
		});

		// prepare account list
		props.accounts.forEach((item) => {
			const account = {
				account: item,
				account_id: item.id,
				label: item.name + " periodo",
			};
			accounts.push(account);
		});

		var columns = [...rules, ...accounts];

		if (planView.details.length > 0) {
			columns = columns.map((column) => {
				planView.details.forEach((item) => {
					if (
						(item.account &&
							column.account &&
							item.account.id === column.account.id) ||
						(item.transaction_rule &&
							column.transaction_rule &&
							item.transaction_rule.id === column.transaction_rule.id)
					) {
						//console.log(item);
						column.checked = true;
						column.scope = item.scope;
						column.order = item.order;
						column.label = item.label;
					}
				});

				return column;
			});
		}

		// console.log(planView.details);
		// console.log(columns);

		columns = columns.sort(function (a, b) {
			if (!a.order) a.order = 99999;

			if (!b.order) b.order = 99999;

			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});

		// console.log(columns);

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			planView,
			columns,
		};
	}

	handleChange = (event) => {
		const { planView } = this.state;

		this.setState({
			planView: {
				...planView,
				[event.target.id]: event.target.value,
			},
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	checkItem = (obj1, obj2) => {
		return (
			(obj1.transaction_rule_id &&
				obj2.transaction_rule_id === obj1.transaction_rule_id) ||
			(obj1.account_id && obj2.account_id === obj1.account_id)
		);
	};

	prepareColumnsSubmit = () => {
		const { planView, columns } = this.state;

		const details = [];
		var order = 1;
		columns.map(function (column) {
			if (column.checked) {
				column.scope = column.scope || "Periodo";
				column.order = order;

				details.push(column);
				order += 1;
			}
			return true;
		});

		planView.details = details;

		return planView;
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		const { isEdit } = this.state;

		this.setState({ isLoading: true });
		let response = "";
		const props = this.props;

		const planView = this.prepareColumnsSubmit();

		// if edit => update; else => new
		if (isEdit) {
			response = await planViewsService.update(planView);
		} else {
			response = await planViewsService.add(planView);
		}

		// console.log(response);

		if (response.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", "Cambios guardados correctamente");
					this.props.getPlanViews();
					props.handleClose();
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		// console.log(oldIndex);
		// console.log(newIndex);
		this.setState(({ columns }) => ({
			columns: arrayMove(columns, oldIndex, newIndex),
		}));
	};

	// Handle columns:

	handleColumn = (item, event, data) => {
		if (data.action === "scope") {
			this.handleColumnScope(item, event);
		}
		if (data.action === "select") {
			this.handleColumnSelection(item, event);
		}
		if (data.action === "edit-mode") {
			this.handleColumnEditMode(item, event, data);
		}
		if (data.action === "label-edit") {
			this.handleColumnLabelEdit(item, event);
		}
	};

	handleColumnSelection = (item, event) => {
		const { columns } = this.state;
		const countSelected = columns.filter((o) => o.checked === true).length;
		const value = event.target.checked;

		if (countSelected >= 8 && value) {
			toastr.error("", "Puedes seleccionar un máximo de 8 columnas");
		} else {
			this.setState({
				columns: this.state.columns.map((o) =>
					(item.transaction_rule_id &&
						o.transaction_rule_id === item.transaction_rule_id) ||
					(item.account_id && o.account_id === item.account_id)
						? { ...o, checked: value }
						: o
				),
			});
		}
	};

	handleColumnScope = (item, event) => {
		const value = event.target.value;
		var label = item.label;

		label =
			label.replaceAll("periodo", "").replaceAll("año", "") +
			" " +
			value.toLowerCase();

		this.setState({
			columns: this.state.columns.map((o) =>
				this.checkItem(item, o) ? { ...o, scope: value, label: label } : o
			),
		});
	};

	handleColumnEditMode = (item, event, data) => {
		const value = data.value;

		this.setState({
			columns: this.state.columns.map((o) =>
				this.checkItem(item, o) ? { ...o, edit: value } : o
			),
		});
	};

	handleColumnLabelEdit = (item, event) => {
		const value = event.target.value;

		this.setState({
			columns: this.state.columns.map((o) =>
				this.checkItem(item, o) ? { ...o, label: value } : o
			),
		});
	};

	render() {
		const { planView, errors, columns } = this.state;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body plan-view-edit">
					<FormGroup controlId="name">
						<FormLabel>
							Nombre <span className="label-required">*</span>
						</FormLabel>
						<FormControl
							type="text"
							value={planView.name}
							onChange={this.handleChange}
							placeholder="Nombre"
							isInvalid={this.checkValidField("name")}
						/>
						<FormControl.Feedback type="invalid">
							{errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<p className="text-info">Selecciona las columnas de la vista:</p>

					<div className="column-wrap">
						<ColumnList
							helperClass="column-item-active"
							onSortEnd={this.onSortEnd}
							handleColumn={this.handleColumn}
							items={columns}
							useDragHandle
						/>
					</div>

					{errors.details && (
						<p className="invalid-feedback d-block">{errors.details}</p>
					)}
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.plansReducer,
		...reducers.rulesReducer,
	};
};

export default connect(mapStateToProps, plansActions)(PlanViewEdit);
