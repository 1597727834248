import { combineReducers } from "redux";

import absencesReducer from "./absencesReducer";
import authReducer from "./authReducer";
import calendarsReducer from "./calendarsReducer";
import companyReducer from "./companyReducer";
import customFieldsReducer from "./customFieldsReducer";
import dashboardReducer from "./dashboardReducer";
import employeesReducer from "./employeesReducer";
import functionsReducer from "./functionsReducer";
import ubicationsReducer from "./ubicationsReducer";
import groupsReducer from "./groupsReducer";
import modalReducer from "./modalReducer";
import plansReducer from "./plansReducer";
import rulesReducer from "./rulesReducer";
import shiftsReducer from "./shiftsReducer";
import timesReducer from "./timesReducer";

import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers({
	absencesReducer,
	authReducer,
	calendarsReducer,
	companyReducer,
	customFieldsReducer,
	dashboardReducer,
	employeesReducer,
	functionsReducer,
	ubicationsReducer,
	groupsReducer,
	modalReducer,
	plansReducer,
	rulesReducer,
	shiftsReducer,
	timesReducer,
	toastr: toastrReducer, // <- Mounted at toastr.
});
