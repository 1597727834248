import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus, FiSearch } from "react-icons/fi";
import { FormGroup, FormControl } from "react-bootstrap";
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import AccountsList  from 'components/Accounts';
import AccountEditModal  from 'components/Accounts/AccountEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as rulesActions from 'actions/rulesActions';
import Modal  from 'components/Modal';

class Accounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchAccount: '',
    }    

    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openNewAccountModal = this.openNewAccountModal.bind(this);
  }

  componentDidMount() {
    this.getAccounts();
    this.getAccountTypes();
  }

  handleChangeSearch = event => {
    this.setState({
      [event.target.id]: event.target.value
    },() => {
      this.getAccounts()
    });
  }  
  
  async getAccounts () {
    await this.props.getAccounts(this.state.searchAccount);
  }

  async getAccountTypes () {
    await this.props.getAccountTypes();
  }  

  closeModal() {
    this.props.hideModal()
  }

  openNewAccountModal() {
   this.props.showModal({
     open: true,
     title: 'Nueva cuenta',
     style: {width: '400px'},
     content: <AccountEditModal handleClose={this.closeModal}  />,
     closeModal: this.closeModal
   }, 'edit')
  }

  getContent = () => {
    if (this.props.loading){
      return <Loading />;
    }

    if (!this.props.accounts){
      return "Error cargando las cuentas";
    }

    return (<AccountsList />);
  }   

  render() {

    return(
      <>
        <Layout className="page-accounts" context="rules">

          <div className="heading">
            <h1 className="title">Cuentas</h1>

            <div className="heading-filters">
                <FormGroup className="form-search" controlId="searchAccount">
                <FormControl
                  type="text"
                  placeholder="Buscar..."
                  value={this.state.searchAccount}
                  onChange={this.handleChangeSearch}
                  />
                <FiSearch />
                </FormGroup>
              </div>  
            <div className="heading-actions">  
                <button type="button" title="Crear cuenta" onClick={this.openNewAccountModal} className="btn btn-new"><FiPlus /></button>
            </div>          
          </div>

          { this.getContent() }

        </Layout>

        <Modal hideModal={this.props.hideModal} />
      </>      
    )
  }
}



const mapStateToProps = (reducers) => {
  return reducers.rulesReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAccounts: (q) => dispatch(rulesActions.getAccounts(q)),
  getAccountTypes: () => dispatch(rulesActions.getAccountTypes()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Accounts)