import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus, FiSearch } from "react-icons/fi";
import { FormGroup, FormControl } from "react-bootstrap";
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import TimeTypeList  from 'components/TimeTypes';
import TimeTypeEditModal  from 'components/TimeTypes/TimeTypeEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as timesActions from 'actions/timesActions';
import Modal  from 'components/Modal';

class TimeTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTimeType: '',
    }    

    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openNewTimeTypeModal = this.openNewTimeTypeModal.bind(this);
  }

  componentDidMount() {
    this.getTimeTypes();
  }

  handleChangeSearch = event => {
    this.setState({
      [event.target.id]: event.target.value
    },() => {
      this.getTimeTypes()
    });
  }  
  
  async getTimeTypes () {
    await this.props.getTimeTypes(this.state.searchTimeType);
  }

  closeModal() {
    this.props.hideModal()
  }

  openNewTimeTypeModal() {
   this.props.showModal({
     open: true,
     title: 'Nuevo tipo de tiempo',
     style: {width: '400px'},
     content: <TimeTypeEditModal handleClose={this.closeModal}  />,
     closeModal: this.closeModal
   }, 'edit')
  }

  getContent = () => {
    if (this.props.loading){
      return <Loading />;
    }

    if (!this.props.time_types){
      return "Error cargando los tipos de tiempo";
    }

    return (<TimeTypeList time_types={this.props.time_types} />);
  }   

  render() {

    return(
      <>
        <Layout className="page-timetypes" context="shifts">

          <div className="heading">
            <h1 className="title">Tipos de tiempo</h1>

            <div className="heading-filters">
                <FormGroup className="form-search" controlId="searchTimeType">
                <FormControl
                  type="text"
                  placeholder="Buscar..."
                  value={this.state.searchTimeType}
                  onChange={this.handleChangeSearch}
                  />
                <FiSearch />
                </FormGroup>
              </div>  
            <div className="heading-actions">  
                <button type="button" title="Crear tipo de tiempo" onClick={this.openNewTimeTypeModal} className="btn btn-new"><FiPlus /></button>
            </div>          
          </div>

          { this.getContent() }

        </Layout>

        <Modal hideModal={this.props.hideModal} />
      </>      
    )
  }
}



const mapStateToProps = (reducers) => {
  return reducers.timesReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getTimeTypes: (q) => dispatch(timesActions.getTimeTypes(q)),
})


export default connect(mapStateToProps, mapDispatchToProps)(TimeTypes)