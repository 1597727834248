import { widgetsService } from "services";


export const getWidgetTemplates = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const response = await widgetsService.getAll();
		// console.log(response);

		dispatch({
			type: "GET_WIDGET_TEMPLATES",
			payload: response.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};


export const getWidgets = () => async (dispatch) => {
	try {
		var response = await widgetsService.getWidgets();
		//console.log(response);
		dispatch({
			type: "GET_WIDGETS",
			payload: response,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};
