const initialState = {
  modalType: null,
  modalProps: {
    open: false,
    isLoading: false,
  }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        modalProps: action.modalProps,
        modalType: action.modalType,
        type: action.type
      }
    case 'HIDE_MODAL':
      return initialState
    case 'UPDATE_LOADING':
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          isLoading: action.payload.isLoading
        }
      };
    default:
      return state
  }
}
