import React from 'react';
import { toastr } from 'react-redux-toastr';
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import moment from 'moment';
import { staffingLevelsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";

class StaffingLevelEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		this.state = {
			isLoading: false,
			submitted: false,
			copy_values: false,
			errors: {},
			overwrite: props.options.needs_selection
		};
	}

	handleChange = (event, row, index, i) => {
		// console.log(this.state.overwrite);
		const { copy_values } = this.state;
		const copyAll = copy_values && i === 0;

		const overwrite = this.state.overwrite.map((item) => {
			if ((item.row === row && item.index === index) || copyAll) {
				item.needed = event.target.value * 1;

				if (item.hasOwnProperty('overwrite'))
					item.overwrite = event.target.value * 1;

			}
			return item;
		});

		// console.log(overwrite);

		this.setState({
			overwrite,
		});
	}

	handleSwitch = (event) => {
		const copy_values = event.target.checked;

		if (copy_values) {
			const firstValue = this.state.overwrite[0].needed;

			const overwrite = this.state.overwrite.map((item) => {
				item.needed = firstValue
				return item;
			});

			this.setState({
				copy_values,
				overwrite,
			});
		}
		else {
			this.setState({
				copy_values,
			});
		}
	};

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();
		const { overwrite } = this.state;
		const props = this.props;

		this.setState({ isLoading: true });
		let response = '';

		// console.log(overwrite);

		// overwrite staffing level
		response = await staffingLevelsService.updateAssignment(overwrite);


		// console.log(response);
		// TODO canviar. Esperar resposta bona del backend
		var hasErrors = response[0] && response[0].status === 400 ? true : false;

		if (response.ok && !hasErrors) {
			// if (response.ok  {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {
				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				props.getStaffingLevelAssignments();
				props.handleClose();
			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
				hasErrors: true // TODO temp (treure quan backend estigui ok)
			});
		}
	}

	getNeedRows = () => this.state.overwrite.map((item, i) => {
		const needed = item.hasOwnProperty('overwrite') ? item.overwrite : item.needed;
		return (
			<li key={i}>
				<FormGroup controlId={`needed_${i}`}>
					<FormLabel>{moment(item.day).format("DD/MM/YY")}</FormLabel>
					<FormControl
						type="number"
						onChange={(e) => this.handleChange(e, item.row, item.index, i)}
						placeholder="0"
						value={needed}
						required
					//isInvalid={this.checkValidField("needed")}
					/>
				</FormGroup>
				<span className='needed-status'>({item.current}/{needed || 0})</span>

				{needed < item.current && (<FaCaretUp className="color-green" />)}
				{needed > item.current && (<FaCaretDown className="color-red" />)}

				{i === 0 && this.props.options.isMultiple && (
					<div className='row-copy'>
						<Switch
							controlId="copy_values"
							isOn={this.state.copy_values}
							handleToggle={this.handleSwitch}
							className="switch-mini"
						/> Copiar valor a todas las filas
					</div>
				)}
			</li>
		)
	});


	render() {
		const { errors } = this.state; // TODO: mostrar errors
		const { options } = this.props;
		// console.log(options);

		const shiftname = options.shift.description ? options.shift.name + " - " + options.shift.description : options.shift.name;

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body staffing-overwrite-edit">

					<Row className="staffing-overwrite-head">
						<Col sm={6} >
							<strong>Función</strong> {options.function.name}
						</Col>
						<Col sm={6} >
							<strong>Turno</strong> {shiftname}
						</Col>
						<Col sm={6} >
							<strong>Grupo</strong> {options.group.name}
						</Col>

						{options.ubication && (
							<Col sm={6} >
								<strong>Ubicación</strong> {options.ubication.name}
							</Col>)}
					</Row>

					<div className='need-heading'>
						<h2 className='subtitle'>Empleados necesarios</h2>
						<span className='info-note'>(Empleados asignados / empleados necesarios)</span>
					</div>

					<ul className='need-list'>
						{this.getNeedRows()}
					</ul>

					{this.state.submitted && this.state.hasErrors && (
						<div className="form-error">
							Hay errores en el formulario. Revisa que todos los valores estén informados.
						</div>
					)}

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}


export default StaffingLevelEdit