import React from 'react';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc'; 
import { FiMenu, FiEdit3, FiCheck } from "react-icons/fi";

const DragHandle = sortableHandle(() => <span className="column-move"><FiMenu className="column-move-icon" /></span>);

const ColumnItem = SortableElement(({item, index, handleColumn})  => {
	let tipo = "";
	let id = "";

	if (item.transaction_rule_id){
		tipo = "Regla";
		id = `col_transaction_rule_${item.transaction_rule_id}`;
	}
	else if (item.account_id){
		tipo = "Cuenta";
		id = `col_account_${item.account_id}`;
	}

	const checked = item.checked;

	return (
	   <li key={ index } aria-label={item.name} title={item.name} className="column-item"> 
			<div className="column-left checkbox">
				<input 
					type="checkbox" 
					id={id}
					onChange = {(e) => handleColumn(item, e, { action: 'select' })}
					checked={checked}
					/> 
				<label
					htmlFor={id}
					className="btn-check-label"
					>
					{item.edit ?
						<input 
							type="text"
							id={`label_${id}`}
							className="form-control column-label-edit"
							onChange = {(e) => handleColumn(item, e, { action: 'label-edit' })}
							value={item.label}
						/>
						:
						<span className="column-item-label">{item.label}</span>
					}

						<span className="column-item-tipo">{tipo}</span>
					</label>

				{item.edit ?
					<button 
						type="button" 
						className="column-label-btn"
						onClick = {(e) => handleColumn(item, e, { action: 'edit-mode', value:false })}
						>
						<FiCheck className="column-label-icon" aria-label="Aceptar" />
					</button>
					:
					<button 
						type="button" 
						className="column-label-btn"
						onClick = {(e) => handleColumn(item, e, { action: 'edit-mode', value:true })}
						>
						<FiEdit3 className="column-label-icon" aria-label="Editar etiqueta" />
					</button>
				}
			</div>
			<div className="column-right">
				<select
					id={`scope_${id}`}
					className="form-control column-item-scope"
					value={item.scope}
					onChange= {(e) => handleColumn(item, e, { action: 'scope' })}
					>
					<option value="Periodo">Periodo</option>
					<option value="Año">Año</option>
				</select>

				<DragHandle />
			</div>
	   </li>
	)
});

const ColumnList = SortableContainer(({items, handleColumn}) => {
	return (
		<ul className="list-columns">
			{items.map((item, index) => (
				<ColumnItem key={`item-${index}`} handleColumn={handleColumn} sortIndex={index} index={index} item={item} />
			))}
		</ul>
	);
}); 

export default ColumnList;