import { handleResponse } from "utils/utils";
import { authService } from "services";

export const rulesService = {
    getAll,
    getByType,
    getById,
    getRuleTypes,
    add,
    update,
    remove,
};

// gets all rules
function getAll(q) {
    const requestOptions = {
        method: 'GET',
	    headers: {
	      'Authorization' : `Bearer ${authService.token.access_token}`
	    },
    };

    let query = '?sort=+name';

    if (q !== undefined)
        query += "&q=" + q;

    return fetch(`${global.BASE_API}transaction-rules${query}`, requestOptions).then(handleResponse);
}

function getByType(ruleType) {
    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization' : `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name&transaction_rule_type=' + ruleType;

    return fetch(`${global.BASE_API}transaction-rules${query}`, requestOptions).then(handleResponse);
}

// get rule by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization' : `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}transaction-rules/${id}`, requestOptions).then(handleResponse);
}

// get transaction rule types
function getRuleTypes() {
    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization' : `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}transaction-rules/types`, requestOptions).then(handleResponse);
}

// add new rule
function add(rule) {
    const requestOptions = {
        method: 'POST',
	    headers: {
	      'Authorization' : `Bearer ${authService.token.access_token}`,
		  'Content-Type': 'application/json'
	    },
	    body: JSON.stringify(rule)
    };

    return fetch(`${global.BASE_API}transaction-rules/`, requestOptions).then(handleResponse);
}

// update rule
function update(rule) {
    const requestOptions = {
        method: 'PUT',
	    headers: {
	      'Authorization' : `Bearer ${authService.token.access_token}`,
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify(rule)
    };

    return fetch(`${global.BASE_API}transaction-rules/${rule.id}`, requestOptions).then(handleResponse);
}

// remove rule
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
	    headers: {
	      'Authorization' : `Bearer ${authService.token.access_token}`
	    },
    };

    return fetch(`${global.BASE_API}transaction-rules/${id}`, requestOptions).then(handleResponse);
}
