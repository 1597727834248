import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import TemplateEditModal from 'components/WidgetTemplates/TemplateEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as dashboardActions from 'actions/dashboardActions';
import { widgetsService } from "services";
import { errorFormating } from "utils/utils";
import { WIDGET_NAMES, sortByPosition } from "components/WidgetTemplates/utils";

class TemplateList extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.openEditTemplateModal = this.openEditTemplateModal.bind(this);
		this.openDeleteTemplateModal = this.openDeleteTemplateModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	closeModal() {
		this.props.hideModal()
	}

	openEditTemplateModal(widgetTemplate) {
		this.props.showModal({
			open: true,
			title: 'Editar plantilla',
			style: { width: '600px' },
			content: <TemplateEditModal widgetTemplate={widgetTemplate} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteTemplateModal(idTemplate) {
		this.props.showModal({
			open: true,
			title: 'Eliminar plantilla',
			style: { width: '400px' },
			message: '¿Desea eliminar la plantilla de widgets? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idTemplate),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idTemplate) => {
		const response = await widgetsService.remove(idTemplate);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {
			this.props.getWidgetTemplates();
			this.closeModal();
		}
	}

	openAsignEmployeesModal(widgetId) {
		this.props.showModal(
			{
				open: true,
				title: "Confirmar asignación",
				message: "Se asignará esta plantilla a todos los empleados que no tengan ninguna plantilla asignada. ¿Deseas continuar?",
				confirmAction: () => this.confirmAsignEmployees(widgetId),
				closeModal: () => this.closeModal(),
				style: { width: "420px" },
			},
			"confirm"
		);
	}

	confirmAsignEmployees = async (widgetId) => {
		const response = await widgetsService.massiveAssignment(widgetId);
		console.log(response);

		if (response.ok) {
			toastr.success("¡Bien!", "Se ha asignado correctamente la plantilla");
			this.closeModal();
		} else {
			const textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
	};


	getEmptyRow = () => {
		return (
			<p className="no-results">No hay plantillas de widgets creadas. Se aplicará la configuración por defecto.</p>
		);
	}

	getViewDetails = (view_details) => view_details.map((item, index) => {
		const name = WIDGET_NAMES[item.name];
		return (
			<li key={index} aria-label={name} title={name}>
				{name} ({item.width}x{item.height})
			</li>
		)
	});

	getRows = () => this.props.widget_templates.map((widgetTemplate) => {
		// console.log(widgetTemplate);
		const widgets = widgetTemplate.widgets.sort(sortByPosition);

		return (
			<div className="col-sm-6 col-lg-4 col-xl-3" key={widgetTemplate.id}>

				<article className="card" key={widgetTemplate.id}>
					<div className="card-top">
						<h2 className="card-title">{widgetTemplate.name}</h2>
					</div>
					<div className="card-content">
						<div className="form-group no-form">
							<label>Widgets</label>
							<ul className="list-columns">
								{this.getViewDetails(widgets)}
							</ul>
						</div>
					</div>

					<div className="card-actions bottom-right">
						<Dropdown>
							<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
							<Dropdown.Menu alignRight>
								<Dropdown.Item as="button" onClick={() => this.openEditTemplateModal(widgetTemplate)}>Editar</Dropdown.Item>
								{
									!widgetTemplate.system && (<Dropdown.Item as="button" onClick={() => this.openAsignEmployeesModal(widgetTemplate.id)}>Asignar a todos los empleados sin plantilla</Dropdown.Item>)
								}
								{
									!widgetTemplate.system && (<Dropdown.Item as="button" onClick={() => this.openDeleteTemplateModal(widgetTemplate.id)}>Eliminar</Dropdown.Item>)
								}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</article>

			</div>
		)
	});

	render() {
		return (
			<>
				{this.props.widget_templates.length === 0 ?
					this.getEmptyRow()
					:
					<div className="row list-widget-templates">
						{this.getRows()}
					</div>
				}
			</>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.dashboardReducer;
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getWidgetTemplates: (q) => dispatch(dashboardActions.getWidgetTemplates(q)),
})


export default connect(mapStateToProps, mapDispatchToProps)(TemplateList)