import { handleResponse, handleBlobResponse } from "utils/utils";
import { authService } from "services";

export const calendarsService = {
    getAll,
    getSelectable,
    getWeekdaysDefault,
    getById,
    add,
    update,
    remove,
    clone,
    getUpcomingHolidays,
    getCalendarsByEmployee,
    downloadImportTemplate,
    prepareImport,
    runImport,
};

// gets all calendars
function getAll(q) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name';

    if (q !== undefined)
        query += "&q=" + q;

    return fetch(`${global.BASE_API}calendars${query}`, requestOptions).then(handleResponse);
}


// gets selectable calendars
function getSelectable() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name&variant=select';

    return fetch(`${global.BASE_API}calendars${query}`, requestOptions).then(handleResponse);
}

// gets default week days
function getWeekdaysDefault() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}calendars/default`, requestOptions).then(handleResponse);
}

// get calendar by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}calendars/${id}`, requestOptions).then(handleResponse);
}

// add new calendar
function add(calendar) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(calendar)
    };

    return fetch(`${global.BASE_API}calendars/`, requestOptions).then(handleResponse);
}

// update calendar
function update(calendar) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(calendar)
    };

    return fetch(`${global.BASE_API}calendars/${calendar.id}`, requestOptions).then(handleResponse);
}

// remove calendar
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}calendars/${id}`, requestOptions).then(handleResponse);
}

// clone calendar
function clone(id) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
    };

    return fetch(`${global.BASE_API}calendars/${id}/clone`, requestOptions).then(
        handleResponse
    );
}


// gets upcoming holidays
function getUpcomingHolidays() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}calendars/holidays`, requestOptions).then(handleResponse);
}


// gets calendars from an employee
function getCalendarsByEmployee(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    const { employee_id, from, to } = options;

    let query = '?page=0';

    if (employee_id !== undefined)
        query += "&employee_id=" + employee_id;

    if (from !== undefined && from !== null && from !== '')
        query += "&from=" + from;

    if (to !== undefined && to !== null && to !== '')
        query += "&to=" + to;

    return fetch(`${global.BASE_API}calendars/employee${query}`, requestOptions).then(handleResponse);
}



/**********************************************************************
 ****** IMPORT CALENDARS
 ***********************************************************************/

function downloadImportTemplate() {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
            "Content-Type": "application/json",
        },
    };

    return fetch(
        `${global.BASE_API}calendars/imports/template`,
        requestOptions
    ).then(handleBlobResponse);
}

function prepareImport(formData) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
        body: formData,
    };

    return fetch(`${global.BASE_API}calendars/imports`, requestOptions).then(
        handleResponse
    );
}

function runImport(token) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
    };

    return fetch(
        `${global.BASE_API}calendars/imports/${token}`,
        requestOptions
    ).then(handleResponse);
}