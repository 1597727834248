import React from 'react';
import {toastr} from 'react-redux-toastr';
import { FormGroup, FormControl, FormLabel} from "react-bootstrap";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      submitted: false,
      errors:{},
  	  password_old: "",
      password_new: "",
      confirm_password_new: "",
    };
  }

  handleChange = event => {
    this.setState({
      	[event.target.id]: event.target.value,
      	submitted: false
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }     

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    const response = await employeesService.newPassword(this.state.password_old, this.state.password_new);
    const props = this.props;

    if (response.ok){
      toastr.success('¡Bien!', 'Cambios guardados correctamente');

      this.setState({ 
        submitted: false, 
        isLoading: false,
        errors: {},
        password_old: "",
        password_new: "",
        confirm_password_new: "",
      },()=>{
      	setTimeout(function(){ 
	      	props.handleClose();
      	}, 3000);

      });
    }
    else{
      this.setState({ 
        submitted: true, 
        isLoading: false,
        errors: errorFormating(response)
      });
    }
  }  

  render() {
	const { password_old, password_new, confirm_password_new, submitted } = this.state;

    return(
		<form className="ChangePassword" onSubmit={this.handleSubmit}>
			<FormGroup controlId="password_old">
				<FormLabel>Contraseña actual</FormLabel>
				<FormControl
					type="password"
					value={password_old}
					onChange={this.handleChange}
            		isInvalid = {this.checkValidField('password_old')}
            		placeholder = "Contraseña actual"
						/>
	            <FormControl.Feedback type="invalid">
	              {this.state.errors.password_old}
	            </FormControl.Feedback>                  
			</FormGroup>	

			<FormGroup controlId="password_new">
				<FormLabel>Contraseña nueva</FormLabel>
				<FormControl
					value={this.state.password_new}
					onChange={this.handleChange}
					type="password"
        			isInvalid = {this.checkValidField('password_new')}
        			placeholder = "Confirmar contraseña"
					/>
	            <FormControl.Feedback type="invalid">
	              {this.state.errors.password_new}
	            </FormControl.Feedback>                      
			</FormGroup>

			<FormGroup controlId="confirm_password_new">
				<FormLabel>Confirmar contraseña nueva</FormLabel>
				<FormControl
					value={this.state.confirm_password_new}
					onChange={this.handleChange}
					type="password"
					isInvalid={submitted && (!confirm_password_new || password_new === confirm_password_new)}
					placeholder = "Contraseña nueva"
					/>
	            <FormControl.Feedback type="invalid">
	              Las contraseñas no coinciden
	            </FormControl.Feedback>                      
			</FormGroup>	



				<SubmitButton
					block
					type="submit"
					isLoading={this.state.isLoading}
					text="Guardar"
					loadingText="Guardando..."
					/>

		</form>
    )
  }
}

export default ChangePassword;