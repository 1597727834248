const initialState = {
	employees: [],
	employee: {},
	employee_groups: [],
	employee_functions: [],
	employee_ubications: [],
	employee_contracts: [],
	employee_documents: [],
	employee_shift_patterns: [],
	employee_absences: [],
	employee_separated_rest_employees: [],
	employee_backup: {},
	employee_absence_calendar: [],
	select_employees: [],
	loading: false,
	error: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "GET_EMPLOYEES":
			return {
				...state,
				employees: action.payload,
				loading: false,
				error: "",
			};
		case "GET_SELECT_EMPLOYEES":
			return {
				...state,
				select_employees: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE":
			return {
				...state,
				employee: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_GROUPS":
			return {
				...state,
				employee_groups: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_FUNCTIONS":
			return {
				...state,
				employee_functions: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_UBICATIONS":
			return {
				...state,
				employee_ubications: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_CONTRACTS":
			return {
				...state,
				employee_contracts: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_DOCUMENTS":
			return {
				...state,
				employee_documents: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_SHIFT_PATTERNS":
			return {
				...state,
				employee_shift_patterns: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_ABSENCES":
			return {
				...state,
				employee_absences: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_ABSENCE_CALENDAR":
			return {
				...state,
				employee_absence_calendar: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_SEPARATED_REST":
			return {
				...state,
				employee_separated_rest_employees: action.payload,
				loading: false,
				error: "",
			};
		case "GET_EMPLOYEE_BACKUP":
			return {
				...state,
				employee_backup: action.payload,
				loading: false,
				error: "",
			};
		case "LOADING":
			return { ...state, loading: true };

		case "CHANGE_PASSWORD_SUCCESS":
			return { ...state, loading: false };
		case "CHANGE_PASSWORD_ERROR":
			return { ...state, loading: false };
		case "ERROR":
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			return state;
	}
};


