import React from "react";

const Loading = (props) => (

  <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

);

export default Loading;

