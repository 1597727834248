import React, { useState } from 'react';
import moment from 'moment';
import { FiEdit3 } from "react-icons/fi";
import { FaCaretUp, FaCaretDown, FaMapMarkerAlt } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { StaffingHeaderContextMenu } from "components/Plans/PlanMenus";

import { ContextMenuTrigger } from "react-contextmenu";
import { PLAN_GRID_VIEWS } from "components/Plans";
import { getContrastColor } from "utils/utils";
import { getFirstLastInterval } from "utils/datetime";

const MENU_STAFFING_ID = "menu_staffing";
const MENU_STAFFING_HEADER_ID = "menu_staffing_header";

function collect(props) {
	return props;
}



const getStaffingLevelHeader = (props, handleDayClick, selectedDay) => props.plan_days.map((d, i) => {
	const day = moment(d.day).format("dd");
	const dayNum = moment(d.day).format("DD");
	let day_type;
	const details = d.details;

	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}

	if (d.special_day)
		day_type = d.special_day.day_type;

	if (d.weekday)
		day_type = d.weekday.day_type;

	const color = day_type.color;

	return (
		<th
			className={`th-day ${selectedDay === d.day ? 'selected' : ''}`}
			key={d.day}
			style={{ color: color }}
			onClick={() => handleDayClick(d.day)}

		>
			<ContextMenuTrigger
				id={MENU_STAFFING_HEADER_ID}
				collect={collect}
				onItemClick={handleHeaderMenuClick(props)}
				itemStaffingHeader={d}
				holdToDisplay={1000}
			>
				<div className="th-wrap">
					<span className="span-day">{day}</span>
					<span className="span-num">{dayNum}</span>
				</div>
			</ContextMenuTrigger>
		</th>
	)
});

const handleHeaderMenuClick = (props) => (e, data, target) => {
	e.preventDefault();
	// console.log("handleHeaderMenuClick");
	// console.log(props);
	// console.log(data);

	if (data.action === "add") {
		handleAddAssignment(props, data.itemStaffingHeader);
	}
};

const handleAddAssignment = (props, data) => {
	// console.log(data);

	props.openNewStaffingLevelModal(data);


};

const getStaffingLevelRows = (props, selectedDay) => {
	return props.staffing_level_assignments.map((assignment, i) => {
		const fun = assignment.function;
		const shift = assignment.shift;
		// console.log(shift.time_intervals);

		var text_ubication = "";

		if (assignment.ubication) {
			const ubication = assignment.ubication;
			var color;

			if (ubication.border_color)
				color = ubication.border_color;

			if (ubication.background_color)
				color = ubication.background_color;

			text_ubication =
				(<span className='need-ubication'>
					<FaMapMarkerAlt className="icon" style={{
						color: color && color,
					}} /> {assignment.ubication.name}
				</span>)
		}

		var text_interval = (<span className='need-interval'>
			{getFirstLastInterval(shift.time_intervals)}
		</span>)


		// Añadir clase "hidden" si `needed` es 0 para el día seleccionado
		const counterForSelectedDay = assignment.counters.find(counter => moment(counter.day).isSame(selectedDay, 'day'));
		// console.log(counterForSelectedDay);
		const needed = counterForSelectedDay ? (counterForSelectedDay.hasOwnProperty('overwrite') ? counterForSelectedDay.overwrite : counterForSelectedDay.needed) : 0;
		const isHidden = selectedDay && needed === 0;
		const need_name = fun.name + " en " + shift.name;

		return (
			<tr key={i} className={isHidden ? "hidden" : ""}>
				<th
					style={{
						backgroundColor: shift.background_color && shift.background_color,
						borderColor: shift.border_color && shift.border_color,
						color: getContrastColor(shift.background_color),
					}}
				>
					<div className="th-wrap">
						<div title={need_name} className="need-name substring">{need_name}</div>
						<div className="need-footer">
							{text_ubication} {text_interval}
						</div>
					</div>
				</th>

				{getStaffingLevelRowContent(props, assignment, i, selectedDay)}
			</tr>
		);
	});
};


const getTooltipDetails = (item) => {
	let details = "";
	if (item.employees && item.employees.length > 0) {
		const employee_list = item.employees.map((employee, i) => {
			const skill = (employee.employee_with_ubication && employee.employee_with_ubication.skill && employee.employee_with_ubication.skill > 0) ? " (Skill: " + employee.employee_with_ubication.skill + ")" : "";
			return (
				<li key={i}>{employee.name} {employee.surname} {skill}</li>
			)
		});

		details = <ul className="tooltip-employees">{employee_list}</ul>;
	}
	return details;
};

const getStaffingLevelRowContent = (props, assignment, row, selectedDay) => assignment.counters.map((item, i) => {
	const checked = item.checked || false;
	const details = getTooltipDetails(item);
	const needed = item.hasOwnProperty('overwrite') ? item.overwrite : item.needed;
	const tooltip = <Tooltip className="tooltip-staffing">{item.current}/{needed}{details}</Tooltip>;

	item.function = assignment.function;
	item.shift = assignment.shift;

	if (assignment.ubication)
		item.ubication = assignment.ubication;

	var classBackground = "";
	if (needed < item.current) {
		classBackground = "bg-green";
	}

	if (needed > item.current) {
		classBackground = "bg-red";
	}

	// Ocultar días no seleccionados cuando hay un día seleccionado
	if (selectedDay && !moment(item.day).isSame(selectedDay, 'day')) {
		return (
			<td key={i} className="need-cell"></td>
		);
	}

	return (
		<OverlayTrigger key={i} placement="top" overlay={tooltip}>
			<td key={i} className={`need-cell btn-checkbox ${checked ? "active" : ""} ${classBackground}`}>
				<ContextMenuTrigger
					id={MENU_STAFFING_ID}
					holdToDisplay={1000}
					collect={collect}
					onItemClick={props.handleStaffingMenuClick}
					targetId={item.id}
					itemStaffing={item}
				>
					<input
						type="checkbox"
						id={`need_${row}_${i}`}
						onChange={(e) => props.handleNeedSelection(row, i, e)}
						checked={checked}
					/>
					<label
						htmlFor={`need_${row}_${i}`}
						className="btn-check-label"
						onContextMenu={() => props.handleNeedSelection(row, i)}
						draggable
						onDragEnter={() => props.handleNeedSelection(row, i)}
					></label>

					{needed === 0 && item.current === 0 ? "-" : needed}
					{item.overwrite && (item.overwrite !== item.needed) ? <FiEdit3 className="need-icon icon-edited" /> : ""}
					{needed < item.current && (<FaCaretUp className="color-green-dark" />)}
					{needed > item.current && (<FaCaretDown className="color-red" />)}

				</ContextMenuTrigger>
			</td>
		</OverlayTrigger >
	);
});

const getStaffingLevelCurrentRows = (props, selectedDay) => {
	return props.staffing_level_current_assignments.map((assignment, i) => {
		const fun = assignment.function;
		const shift = assignment.shift;

		var text_ubication = "";

		if (assignment.ubication) {
			const ubication = assignment.ubication;
			var color;

			if (ubication.border_color)
				color = ubication.border_color;

			if (ubication.background_color)
				color = ubication.background_color;

			text_ubication =
				(<span className='need-ubication'>
					<FaMapMarkerAlt className="icon" style={{
						color: color && color,
					}} /> {assignment.ubication.name}
				</span>)
		}

		// Añadir clase "hidden" si `current` es 0 para el día seleccionado
		const counterForSelectedDay = assignment.counters.find(counter => moment(counter.day).isSame(selectedDay, 'day'));
		const isHidden = selectedDay && (!counterForSelectedDay || counterForSelectedDay.current === 0);

		const need_name = fun.name + " en " + shift.name;
		return (
			<tr key={i} className={isHidden ? "hidden" : ""}>
				<th>
					<div className="th-wrap">
						<div title={need_name} className="need-name substring">
							{need_name} {text_ubication}
						</div>
					</div>
				</th>

				{getStaffingLevelCurrentRowContent(assignment, selectedDay)}
			</tr>
		);
	});
};

const getStaffingLevelCurrentRowContent = (assignment, selectedDay) => assignment.counters.map((item, i) => {
	const details = getTooltipDetails(item);
	const tooltip = <Tooltip className="tooltip-staffing">{item.current}{details}</Tooltip>;

	// Ocultar días no seleccionados cuando hay un día seleccionado
	if (selectedDay && !moment(item.day).isSame(selectedDay, 'day')) {
		return (
			<td key={i} className="current-cell"></td>
		);
	}

	return (
		<OverlayTrigger key={i} placement="top" overlay={tooltip}>
			<td key={i} className="current-cell">
				{item.current}
			</td>
		</OverlayTrigger >
	);
});

const PlanStaffingLevels = (props) => {
	const { plan_grid_view } = props;
	const [selectedDay, setSelectedDay] = useState(null);

	const handleDayClick = (day) => {
		setSelectedDay(day === selectedDay ? null : day);
	};

	var tableClass = "";
	if (plan_grid_view === PLAN_GRID_VIEWS.ubications)
		tableClass = "table-ubications";

	if (plan_grid_view === PLAN_GRID_VIEWS.default)
		tableClass = "table-default";

	if (props.staffing_level_assignments.length > 0 || props.staffing_level_current_assignments.length > 0) {
		return (
			<>
				<table id="tableNeeds" className={`table table-bordered table-plan table-needs ${tableClass}`}>
					<thead>
						<tr>
							<th className="main-col"><div className="th-wrap">Necesidades</div></th>
							{getStaffingLevelHeader(props, handleDayClick, selectedDay)}
						</tr>
					</thead>
					<tbody>
						{props.staffing_level_assignments.length > 0 ?
							getStaffingLevelRows(props, selectedDay) :
							getStaffingLevelCurrentRows(props, selectedDay)}
					</tbody>
				</table>
				<StaffingHeaderContextMenu />
			</>
		)
	} else return "";

}

export default PlanStaffingLevels;
