import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FiArrowLeft, FiExternalLink } from "react-icons/fi";
import { FaMagic } from "react-icons/fa";
import { Nav, Tab } from 'react-bootstrap';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import EmployeeInfo from 'components/Employees/EmployeeInfo';
import EmployeeCustomFields from 'components/Employees/EmployeeCustomFields';
import EmployeeGroups from 'components/Employees/EmployeeGroups';
import EmployeeFunctions from 'components/Employees/EmployeeFunctions';
import EmployeeContracts from 'components/Employees/EmployeeContracts';
import EmployeeDocuments from 'components/Employees/EmployeeDocuments';
import EmployeeUbications from 'components/Employees/EmployeeUbications';
import EmployeeShiftPatterns from 'components/Employees/EmployeeShiftPatterns';
import EmployeeMagicPlan from 'components/Employees/EmployeeMagicPlan';
import AbsenceHeader from 'components/Absences/AbsenceHeader';
import { employeesService } from "services";
import { showModal, hideModal } from "actions/modalActions";
import * as employeesActions from 'actions/employeesActions';
import * as groupsActions from 'actions/groupsActions';
import * as shiftsActions from 'actions/shiftsActions';
import * as functionsActions from 'actions/functionsActions';
import * as ubicationsActions from 'actions/ubicationsActions';
import * as rulesActions from 'actions/rulesActions';
import * as customFieldsActions from 'actions/customFieldsActions';
import * as dashboardActions from 'actions/dashboardActions';
import Modal from 'components/Modal';

class Employee extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);

    this.state = {
      loading: true,
      isMe: false,
      employee: {},
      errors: {},
      error_footer: '',
    };
  }

  closeModal() {
    this.props.hideModal()
  }

  openAlertModal(message, title) {
    this.props.showModal({
      open: true,
      title: title || "Error",
      style: { width: '400px' },
      message: message,
      closeModal: this.closeModal
    }, 'alert')
  }

  async componentDidMount() {
    const employee = await employeesService.getById(this.props.match.params.id);
    // console.log(employee);

    if (employee.ok) {
      await this.props.getAllGroups();
      await this.props.getAllCustomFields();
      await this.props.getEnabledFunctions();
      await this.props.getEnabledUbications();
      await this.props.getContracts();
      await this.props.getSelectableShiftPatterns();
      await this.props.getWidgetTemplates();

      this.setState({
        employee,
        loading: false,
        isMe: (this.props.employee.id === this.props.user.id)
      });
    }
    else {
      this.setState({
        loading: false,
      });
    }

  }

  handleBackClick = () => {
    // this.props.history.goBack();
    this.props.history.push("/employees");
  }

  getEmployeeCard = () => {
    const { employee } = this.state;
    const { user } = this.props;

    return (
      <article className="card" key={employee.id}>
        <div className="card-header">
          <h2 className="card-title">{employee.name} {employee.surname} </h2>
        </div>

        <Tab.Container defaultActiveKey="info">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="info">Información general</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="campos-personalizados">Campos personalizados</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="grupos">Grupos</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="funciones">Funciones</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="ruedas-turnos">Ruedas de turnos</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="contratos">Contratos</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="documentos">Documentos</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="ubicaciones">Ubicaciones</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="planificacion">Planificación automática <FaMagic className="icon-wand" /></Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item-fichajes">
              <Nav.Link as={NavLink} to={`/times/${employee.id}/employee`}>Fichajes <FiExternalLink className="icon-external-link" /></Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="info">
              <EmployeeInfo employee={employee} />
            </Tab.Pane>
            <Tab.Pane eventKey="grupos">
              <EmployeeGroups employee={employee} />
            </Tab.Pane>
            <Tab.Pane eventKey="campos-personalizados">
              <EmployeeCustomFields employee={employee} />
            </Tab.Pane>
            <Tab.Pane eventKey="funciones">
              <EmployeeFunctions employee={employee} />
            </Tab.Pane>
            <Tab.Pane eventKey="ruedas-turnos">
              <EmployeeShiftPatterns employee={employee} />
            </Tab.Pane>
            <Tab.Pane eventKey="contratos">
              <EmployeeContracts employee={employee} />
            </Tab.Pane>
            <Tab.Pane eventKey="documentos">
              <EmployeeDocuments employee={employee} />
            </Tab.Pane>
            <Tab.Pane eventKey="ubicaciones">
              <EmployeeUbications employee={employee} />
            </Tab.Pane>
            <Tab.Pane eventKey="planificacion">
              <EmployeeMagicPlan employee={employee} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </article>
    );
  }

  getContent = () => {
    if (this.state.loading) {
      return <Loading />;
    }

    if (!this.state.employee.id) {
      return "Error cargando el empleado";
    }
    return (
      <>
        <AbsenceHeader employee_id={this.state.employee.id} />
        {this.getEmployeeCard()}
      </>
    );
  }

  render() {
    return (
      <>
        <Layout className="page-employee">
          <div className="heading">
            <h1 className="title"><FiArrowLeft className="button-icon" onClick={this.handleBackClick} /> Empleados</h1>
          </div>

          {this.getContent()}
        </Layout>
        <Modal hideModal={this.props.hideModal} />
      </>
    )
  }
}


const mapStateToProps = (reducers) => {
  return {
    ...reducers.employeesReducer,
    ...reducers.groupsReducer,
    ...reducers.functionsReducer,
    ...reducers.ubicationsReducer,
    ...reducers.shiftsReducer,
    ...reducers.rulesReducer,
    ...reducers.customFieldsReducer,
    ...reducers.dashboardReducer,
    user: reducers.authReducer.user,
    loading: reducers.employeesReducer.loading
  }
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getEmployee: (employee_id) => dispatch(employeesActions.getEmployee(employee_id)),
  getAllCustomFields: () => dispatch(customFieldsActions.getAllCustomFields()),
  getAllGroups: () => dispatch(groupsActions.getAllGroups({ is_multigroup: false })),
  getEnabledFunctions: () => dispatch(functionsActions.getEnabledFunctions()),
  getEnabledUbications: () => dispatch(ubicationsActions.getEnabledUbications()),
  getContracts: () => dispatch(rulesActions.getContracts()),
  getSelectableShiftPatterns: () => dispatch(shiftsActions.getSelectableShiftPatterns()),
  getWidgetTemplates: () => dispatch(dashboardActions.getWidgetTemplates()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Employee)