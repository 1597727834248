const initialState = {
	widget_templates: [],
	widgets: [],
	loading: false,
	error: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "LOADING":
			return { ...state, loading: true };
		case "UNLOADING":
			return { ...state, loading: false };
		case "GET_WIDGET_TEMPLATES":
			return {
				...state,
				widget_templates: action.payload,
				loading: false,
				error: "",
			};
		case "GET_WIDGETS":
			return {
				...state,
				widgets: action.payload,
				loading: false,
				error: "",
			};
		case "ERROR":
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			return {
				...state,
				loading: false,
			};
	}
};
