import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FormGroup, FormControl, FormLabel, FormCheck, Row, Col } from "react-bootstrap";
import moment from "moment";
import { getFirstLastInterval } from "utils/datetime";
import { FiX, FiClock } from "react-icons/fi";
import { rulesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as rulesActions from 'actions/rulesActions';

class RuleEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		let rule = {
			name: '',
			description: '',
			formula_pretty: '',
			formula_easy: '',
			is_add: true,
			accounts_in_formula: [],
			start_hour: '',
			end_hour: '',
		}

		let isEdit = false;
		let isReadOnly = false;
		var selected_contracts = [];

		if (props.rule) {
			isEdit = true;
			rule = props.rule;

			isReadOnly = (rule.system) ? true : false

			if (rule.account)
				rule.account_id = rule.account.id;

			if (rule.shift)
				rule.shift_id = rule.shift.id;

			if (rule.time_type)
				rule.time_type_id = rule.time_type.id;

			if (rule.formula && rule.accounts_in_formula) {
				let formula_pretty = rule.formula;
				let formula_easy = rule.formula;

				rule.accounts_in_formula.forEach((account, index) => {
					const name = account.name.replaceAll(" ", "_");
					var pos = index + 1;
					if (pos < 10)
						pos = "0" + pos;

					formula_pretty = formula_pretty.replaceAll("account_id=" + account.id, name);
					formula_easy = formula_easy.replaceAll("account_id=" + account.id, "A" + pos);
				});
				rule.formula_pretty = formula_pretty;
				rule.formula_easy = formula_easy;
			} else {
				rule.formula = '';
				rule.formula_pretty = '';
				rule.formula_easy = '';
			}

			if (rule.contracts) {
				selected_contracts = rule.contracts
			}
		}

		this.state = {
			isReadOnly,
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			rule,
			accounts_in_formula: rule.accounts_in_formula || [],
			new_item_errors: {},
			temp_account: {},
			temp_contract: {},
			selected_contracts,
		};
	}

	updateRuleFormulas = (rule, accounts_in_formula, f_easy) => {
		let formula_easy = f_easy ? f_easy : rule.formula_easy;
		let formula_pretty = formula_easy;
		let formula = formula_easy;

		accounts_in_formula.forEach((account, index) => {
			var pos = index + 1;
			if (pos < 10)
				pos = "0" + pos;

			const name = account.name.replaceAll(" ", "_");
			formula_pretty = formula_pretty.replaceAll("A" + pos, name);
			formula = formula.replaceAll("A" + pos, "account_id=" + account.id);

		});

		rule.formula_easy = formula_easy;
		rule.formula_pretty = formula_pretty;
		rule.formula = formula;

		return rule;
	}

	handleChangeFormula = event => {
		const { rule, accounts_in_formula } = this.state;
		let formula_easy = event.target.value;

		this.setState({
			rule: this.updateRuleFormulas(rule, accounts_in_formula, formula_easy.toUpperCase()),
		});
	}

	handleChange = event => {
		const { rule } = this.state;
		let key = event.target.id;
		let value = event.target.value;

		if (key === "is_add" || key === "is_subtract") {
			key = "is_add";
			value = (event.target.id === "is_add");
		}

		if (key === "type" && rule.is_add === undefined) {
			rule.is_add = true;
		}

		this.setState({
			rule: {
				...rule,
				[key]: value
			},
		});
	}

	handleAddChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	prepareRuleToSubmit = (rule) => {
		// si antes teniamos un tipo de tiempo /turno y hemos cambiado, elimina rastro anterior
		if (rule.type && (rule.type === "Tipo de tiempo" || rule.type === "Time Type")) {
			delete (rule['shift']);
			delete (rule['shift_id']);
			delete (rule['account_type']);
		}
		if (rule.type && (rule.type === "Turno" || rule.type === "Shift")) {
			delete (rule['time_type']);
			delete (rule['time_type_id']);
			delete (rule['account_type']);
		}
		if (rule.type && (rule.type === "Calculada" || rule.type === "Calculated")) {
			delete (rule['shift']);
			delete (rule['shift_id']);
			delete (rule['time_type']);
			delete (rule['time_type_id']);
			delete (rule['is_add']);
			delete (rule['account_id']);
		}


		if (this.state.selected_contracts.length > 0) {
			const contract_ids = [];

			this.state.selected_contracts.map(c => {
				contract_ids.push(c.id);
				return true;
			});

			rule.contract_ids = contract_ids;
		}

		return rule;
	}

	handleSubmit = async event => {
		event.preventDefault();
		this.setState({ isLoading: true });

		let response = '';
		const props = this.props;
		const { rule } = this.state;

		let copiedRule = Object.assign({}, rule);
		const rule_temp = this.prepareRuleToSubmit(copiedRule);

		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await rulesService.update(rule_temp);
		}
		else {
			response = await rulesService.add(rule_temp);
		}

		//console.log(response);
		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getAllRules();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	// accounts being used in formula
	addAccount = () => {
		let { accounts_in_formula, temp_account, rule } = this.state;
		let isValid = true;
		let account;
		const new_item_errors = {};

		if (temp_account === undefined) {
			isValid = false;
		}
		else {
			account = this.props.select_accounts.find(item => item.id === parseInt(temp_account));
			if (account === undefined) {
				isValid = false;
				new_item_errors.account = false;
			}
		}

		if (isValid) {
			// check if account already exists
			const exists = accounts_in_formula.find(item => item.id === parseInt(temp_account));

			// if not : add new account
			if (!exists) {
				accounts_in_formula = accounts_in_formula.concat(account);
			}

			this.setState({
				accounts_in_formula,
				new_item_errors: {},
				temp_account: {},
				rule: this.updateRuleFormulas(rule, accounts_in_formula),
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}

	removeAccount = (idAccount) => {
		let { accounts_in_formula, rule } = this.state;
		accounts_in_formula = accounts_in_formula.filter(item => item.id !== parseInt(idAccount));

		this.setState({
			accounts_in_formula,
			rule: this.updateRuleFormulas(rule, accounts_in_formula),
		});
	}

	// layout:
	getSelectedAccounts = () => this.state.accounts_in_formula.map((item, index) => {
		var pos = index + 1;

		if (pos < 10)
			pos = "0" + pos;

		return (
			<li key={item.id} className="tag-default tag-delete">
				A{pos} : {item.name}
				<button type="button" onClick={() => this.removeAccount(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});


	// reglas de contratos

	getSelectedContracts = () => this.state.selected_contracts.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeContract(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	addContract = () => {
		let { selected_contracts, temp_contract } = this.state;
		let isValid = true;
		let contract;
		const new_item_errors = {};

		if (temp_contract === undefined) {
			isValid = false;
		}
		else {
			contract = this.props.contracts.find(item => item.id === parseInt(temp_contract));
			if (contract === undefined) {
				isValid = false;
				new_item_errors.contract = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_contracts.find(item => item.id === parseInt(temp_contract));

			if (!exists) {
				selected_contracts = selected_contracts.concat(contract);
			}

			this.setState({
				selected_contracts,
				new_item_errors: {},
				temp_contract: {},
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}
	removeContract = (idContract) => {
		this.setState((prevState, props) => {
			return {
				selected_contracts: prevState.selected_contracts.filter(item => item.id !== parseInt(idContract))
			};
		});
	}

	render() {
		const { isReadOnly, rule, errors, new_item_errors, temp_account, temp_contract, accounts_in_formula } = this.state;
		const { select_accounts, select_shifts, select_time_types, rule_types, account_types, contracts } = this.props;
		const is_add = rule.is_add;

		const optionAccountList = select_accounts.length > 0 && select_accounts.map((item, i) => {
			return <option key={i} value={item.id}>{item.name}</option>
		}, this);

		const optionRuleTypesList = rule_types.length > 0 && rule_types.map((item, i) => {
			return <option key={i} value={item.name}>{item.name}</option>
		}, this);

		const optionShiftsList = select_shifts.length > 0 && select_shifts.map((item, i) => {
			return <option key={i} value={item.id}>{item.name} {getFirstLastInterval(item.time_intervals)}</option>
		}, this);

		const optionTimeTypesList = select_time_types.length > 0 && select_time_types.map((item, i) => {
			return <option key={i} value={item.id}>{item.name}</option>
		}, this);

		const optionAccountTypesList = account_types.length > 0 && account_types.map((item, i) => {
			return <option key={i} value={item.id}>{item.name}</option>
		}, this);

		const isContractRule = (rule.type === "Turno" || rule.type === "Tipo de tiempo");

		const optionContractList = contracts.length > 0 && contracts.map((item, i) => {
			return <option key={i} value={item.id}>{item.name}</option>
		}, this);

		return (
			<form className="modal-rule" >
				<div className="modal-body">

					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={rule.name}
							onChange={this.handleChange}
							placeholder="Nombre de la regla"
							isInvalid={this.checkValidField('name')}
							disabled={isReadOnly}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="description">
						<FormLabel>Descripción</FormLabel>
						<FormControl
							type="text"
							value={rule.description}
							onChange={this.handleChange}
							placeholder="Descripción de la regla"
							isInvalid={this.checkValidField('description')}
							disabled={isReadOnly}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.description}
						</FormControl.Feedback>
					</FormGroup>

					<Row>
						<Col sm={6}>
							<FormGroup controlId="type">
								<FormLabel>Tipo de transacción <span className="label-required">*</span></FormLabel>
								<FormControl
									value={rule.type}
									onChange={this.handleChange}
									placeholder="Tipo de transacción"
									isInvalid={this.checkValidField('type')}
									as="select"
									disabled={isReadOnly}
								>
									<option value="">Selecciona tipo</option>
									{optionRuleTypesList}
								</FormControl>

								<FormControl.Feedback type="invalid">
									{errors.type}
								</FormControl.Feedback>
							</FormGroup>
						</Col>

						<Col sm={6}>
							{rule.type && (rule.type === "Tipo de tiempo" || rule.type === "Time type") &&
								(<FormGroup controlId="time_type_id">
									<FormLabel>Tipo de tiempo</FormLabel>
									<FormControl
										value={rule.time_type_id}
										onChange={this.handleChange}
										placeholder="Tipo de tiempo"
										isInvalid={this.checkValidField('time_type_id')}
										as="select"
										disabled={isReadOnly}
									>
										<option value="">Selecciona tipo de tiempo</option>
										{optionTimeTypesList}
									</FormControl>

									<FormControl.Feedback type="invalid">
										{errors.time_type_id}
									</FormControl.Feedback>
								</FormGroup>)
							}
							{rule.type && (rule.type === "Turno" || rule.type === "Shift") &&
								(<FormGroup controlId="shift_id">
									<FormLabel>Turno</FormLabel>
									<FormControl
										value={rule.shift_id}
										onChange={this.handleChange}
										placeholder="Turno"
										isInvalid={this.checkValidField('shift_id')}
										as="select"
										disabled={isReadOnly}
									>
										<option value="">Selecciona turno</option>
										{optionShiftsList}
									</FormControl>
									<FormControl.Feedback type="invalid">
										{errors.shift_id}
									</FormControl.Feedback>
								</FormGroup>)
							}
							{rule.type && (rule.type === "Calculada" || rule.type === "Calculated") &&
								(<FormGroup controlId="account_type">
									<FormLabel>Tipo de cuenta</FormLabel>
									<FormControl
										value={rule.account_type}
										onChange={this.handleChange}
										placeholder="Tipo de cuenta"
										isInvalid={this.checkValidField('account_type')}
										as="select"
										disabled={isReadOnly}
									>
										<option value="">Selecciona tipo cuenta</option>
										{optionAccountTypesList}
									</FormControl>
									<FormControl.Feedback type="invalid">
										{errors.account_type}
									</FormControl.Feedback>
								</FormGroup>)
							}
						</Col>
					</Row>

					{rule.type && (rule.type === "Tipo de tiempo" || rule.type === "Time type") &&
						<Row>
							<Col sm={6}>
								<FormGroup controlId="start_hour" className="form-group-time">
									<FormLabel>Hora inicio</FormLabel>
									<FormControl
										type="time"
										value={rule.start_hour}
										onChange={this.handleChange}
										isInvalid={this.checkValidField('start_hour')}
										disabled={isReadOnly}
									/>
									<FiClock />

									<FormControl.Feedback type="invalid">
										{errors.start_hour}
									</FormControl.Feedback>
								</FormGroup>
							</Col>

							<Col sm={6}>
								<FormGroup controlId="end_hour" className="form-group-time">
									<FormLabel>Hora fin</FormLabel>
									<FormControl
										type="time"
										value={rule.end_hour}
										onChange={this.handleChange}
										disabled={isReadOnly}
										isInvalid={this.checkValidField('end_hour')}
									/>
									<FiClock />

									<FormControl.Feedback type="invalid">
										{errors.end_hour}
									</FormControl.Feedback>
								</FormGroup>
							</Col>
						</Row>
					}

					{rule.type && (rule.type !== "Calculada" || rule.type !== "Calculated") &&
						<Row>
							<Col sm={6}>
								<FormGroup controlId="type">
									<FormLabel>Tipo de operación</FormLabel>
									<div className={`btn-choice-group ${isReadOnly && 'disabled'} `}>
										<FormCheck
											type="radio"
											id="is_add"
											label="Suma"
											value="true"
											checked={is_add}
											bsPrefix={is_add ? 'btn-choice active' : 'btn-choice'}
											onChange={this.handleChange}
											disabled={isReadOnly}
										/>
										<FormCheck
											type="radio"
											id="is_subtract"
											label="Resta"
											value="false"
											checked={!is_add}
											bsPrefix={!is_add ? 'btn-choice active' : 'btn-choice'}
											onChange={this.handleChange}
											disabled={isReadOnly}
										/>
									</div>
								</FormGroup>
							</Col>
							<Col sm={6}>
								<FormGroup controlId="account_id">
									<FormLabel>Cuenta de destino</FormLabel>
									<FormControl
										value={rule.account_id}
										onChange={this.handleChange}
										placeholder="Selecciona cuenta"
										isInvalid={this.checkValidField('account_id')}
										as="select"
										disabled={isReadOnly}
									>
										<option value="">Selecciona cuenta</option>
										{optionAccountList}
									</FormControl>

									<FormControl.Feedback type="invalid">
										{errors.account_id}
									</FormControl.Feedback>
								</FormGroup>
							</Col>
						</Row>
					}


					{rule.type && (rule.type === "Calculada" || rule.type === "Calculated") &&
						<>
							{!isReadOnly &&
								<>
									<h3 className="subtitle">Configuración de la regla calculada</h3>
									<p className="text-info">Selecciona las cuentas que van a intervenir en la operación. El código de la izquierda será el que deberás usar en la fórmula</p>

									<div className="flex-group mb10">
										<FormGroup controlId="temp_account">
											<FormControl
												type="text"
												onChange={this.handleAddChange}
												value={temp_account}
												placeholder="Selecciona la cuenta"
												isInvalid={new_item_errors.account !== undefined}
												as="select"
											>
												<option value="">Selecciona la cuenta</option>
												{optionAccountList}
											</FormControl>
										</FormGroup>
										<button type="button" onClick={this.addAccount} className="btn btn-primary">Añadir</button>
									</div>
									<ul className="list-tags">
										{this.getSelectedAccounts()}
									</ul>
								</>
							}

							{accounts_in_formula.length > 0 ?
								(<>
									<FormGroup controlId="formula_easy">
										<FormLabel>Fórmula</FormLabel>
										{!isReadOnly &&
											<>
												<p className="text-info">Escribe la operación entre cuentas, por ejemplo: <span>A1 + A2</span> o <span>(A1 - A2)*8</span><br />
													Pueden usarse números y los siguientes símbolos: + -  * / (  )</p>

												<FormControl
													value={rule.formula_easy}
													onChange={this.handleChangeFormula}
													placeholder="Describe la fórmula de la regla"
													isInvalid={this.checkValidField('formula')}
													disabled={isReadOnly}
												/>
											</>
										}

										<FormControl.Feedback type="invalid">
											{errors.formula}
										</FormControl.Feedback>
									</FormGroup>

									<p className="text-info text-info-pretty">{rule.formula_pretty}</p>
									<p className="text-info hidden">{rule.formula}</p>
								</>)
								:
								(errors.formula && <p className="invalid-feedback d-block">{errors.formula}</p>)
							}

						</>
					}

					{isContractRule && (
						<div
							className="form-group mb20"
						>
							<p className="mb5 label">Aplicar solo a los siguientes contratos:</p>

							<div className="flex-group mb10">
								<FormGroup controlId="temp_contract">
									<FormControl
										type="text"
										value={temp_contract}
										placeholder="Selecciona el contrato"
										onChange={this.handleAddChange}
										isInvalid={new_item_errors.contract !== undefined}
										as="select"
									>
										<option value="">Selecciona el contrato</option>
										{optionContractList}
									</FormControl>
								</FormGroup>
								<button type="button" onClick={this.addContract} className="btn btn-primary">Añadir</button>
							</div>
							<ul className="list-tags">
								{this.getSelectedContracts()}
							</ul>
						</div>
					)}
				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					{!isReadOnly && (
						<SubmitButton
							type="button"
							isLoading={this.state.isLoading}
							text="Guardar"
							loadingText="Guardando..."
							onClick={this.handleSubmit}
						/>
					)}
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.rulesReducer,
		...reducers.timesReducer,
		...reducers.shiftsReducer,
		loading: reducers.rulesReducer.loading
	}
};
export default connect(mapStateToProps, rulesActions)(RuleEdit)