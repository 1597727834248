import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus, FiSearch } from "react-icons/fi";
import { FormGroup, FormControl } from "react-bootstrap";
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import ContractsList from 'components/Contracts';
import ContractEditModal from 'components/Contracts/ContractEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as shiftsActions from "actions/shiftsActions";
import * as timesActions from "actions/timesActions";
import * as rulesActions from 'actions/rulesActions';
import Modal from 'components/Modal';

class Contracts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchContract: '',
    }

    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.getContracts = this.getContracts.bind(this);
    this.openNewContractModal = this.openNewContractModal.bind(this);
  }

  componentDidMount() {
    this.getContracts();
    this.getShifts();
    this.props.getSelectableTimeTypes();
  }

  handleChangeSearch = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => {
      this.getContracts()
    });
  }

  async getContracts() {
    await this.props.getContracts({ q: this.state.searchContract, variant: "detailed" });
  }

  async getShifts() {
    // TODO fer una sola crida i filtrar per JS
    await this.props.getSelectableShifts();
    await this.props.getAttendanceShifts();
    await this.props.getNonAttendanceShifts();
  }

  closeModal() {
    this.props.hideModal()
  }

  openNewContractModal() {
    this.props.showModal({
      open: true,
      title: 'Nuevo contrato',
      style: { width: '860px' },
      content: <ContractEditModal getContracts={this.getContracts} handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  getContent = () => {
    if (this.props.loading) {
      return <Loading />;
    }

    if (!this.props.accounts) {
      return "Error cargando los contratos";
    }

    return <ContractsList />;
  }

  render() {

    return (
      <>
        <Layout className="page-contracts" context="rules">

          <div className="heading">
            <h1 className="title">Contratos</h1>

            <div className="heading-filters">
              <FormGroup className="form-search" controlId="searchContract">
                <FormControl
                  type="text"
                  placeholder="Buscar..."
                  value={this.state.searchContract}
                  onChange={this.handleChangeSearch}
                />
                <FiSearch />
              </FormGroup>
            </div>
            <div className="heading-actions">
              <button type="button" title="Crear contrato" onClick={this.openNewContractModal} className="btn btn-new"><FiPlus /></button>
            </div>
          </div>

          {this.getContent()}

        </Layout>

        <Modal hideModal={this.props.hideModal} />
      </>
    )
  }
}



const mapStateToProps = (reducers) => {
  return {
    ...reducers.shiftsReducer,
    ...reducers.timesReducer,
    ...reducers.rulesReducer,
  };
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getContracts: (options) => dispatch(rulesActions.getContracts(options)),
  getSelectableShifts: () => dispatch(shiftsActions.getSelectableShifts()),
  getAttendanceShifts: () => dispatch(shiftsActions.getAttendanceShifts()),
  getNonAttendanceShifts: () => dispatch(shiftsActions.getNonAttendanceShifts()),
  getSelectableTimeTypes: () => dispatch(timesActions.getSelectableTimeTypes()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Contracts)