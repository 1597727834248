import React from 'react';
import Header from 'components/Layout/Header';


class NotFound extends React.Component {

  render() {

    return (
      <React.Fragment>
        <Header />

        <div className="notfound page-notfound">
          <div className="container-fluid">
            <h1>404</h1>
            <h3>:(</h3>
            <h2>La página que buscabas no existe</h2>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

export default NotFound;